import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Avatar,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import "../../components/styles/navbar.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useUser } from "../../components/context/userAuth";

const NavBar = () => {
  const { user, logout } = useUser();

  const [show, setShow] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/login") {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [location.pathname]);

  return (
    show && (
      <AppBar
        sx={{
          maxHeight: "80px",
          paddingTop: "1rem",
          background: "transparent",
          boxShadow: "none",
        }}
        position="static"
      >
        <Toolbar sx={{ py: 3 }}>
          {user && (
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "24px",
                lineHeight: "36px",
                fontWeight: "500",
                paddingLeft: "1rem",
                height: "100%",
                display: "flex",
                alignItems: "center",
                borderRadius: "2px 0px 0px 2px",
                flexGrow: 1,
                color: "rgba(57, 71, 83, 1)",
              }}
            ></Typography>
          )}

          {user && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {/* <div
                style={{
                  background: "#134394",
                  borderRadius: "10px",
                  width: "46px",
                  height: "46px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: "10px",
                }}
              >
                <NotificationsActiveOutlinedIcon
                  style={{
                    color: "#fff",
                    width: "20px",
                    height: "20px",
                  }}
                />
              </div> */}
              <Avatar
                sx={{
                  background: "#E6E6E6",
                  borderRadius: "10px",
                  width: "46px",
                  height: "46px",
                  marginRight: "10px",
                }}
              />
              <div>
                <Typography
                  variant="h6"
                  component="div"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "20px",
                    letterSpacing: "0.27px",
                    color: "rgba(0, 0, 0, 1)",

                    textTransform: "capitalize",
                  }}
                >
                  {user.user_name}
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    fontSize: "10px",
                    lineHeight: "12px",
                    letterSpacing: "0.17px",
                    color: "rgba(103, 129, 150, 1)",
                    textTransform: "capitalize",
                  }}
                >
                  {user.user_position}
                </Typography>
              </div>
            </div>
          )}

          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mx: 2, color: "black" }}
            onClick={handleMenu}
          >
            <ArrowDropDownIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              className="rounded mt-1"
              onClick={handleClose}
              component={Link}
              to="/profile"
            >
              <PersonOutlineOutlinedIcon
                sx={{
                  fontSize: "15px",
                  marginRight: "5px",
                  color: "rgba(57, 71, 83, 1)",
                }}
              />
              <span
                style={{
                  color: "rgba(57, 71, 83, 1)",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  lineHeight: "18px",
                }}
              >
                Profile
              </span>{" "}
            </MenuItem>
            <MenuItem
              className="rounded mt-1"
              onClick={() => {
                logout();
                handleClose();
              }}
              component={Link}
              to="/login"
            >
              <LogoutOutlinedIcon
                sx={{
                  fontSize: "15px",
                  marginRight: "5px",
                  color: "rgba(57, 71, 83, 1)",
                }}
              />
              <span
                style={{
                  color: "rgba(57, 71, 83, 1)",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  lineHeight: "18px",
                }}
              >
                Logout
              </span>{" "}
            </MenuItem>

            {/* <MenuItem onClick={handleClose} component={Link} to="/surveys">
              Survey
            </MenuItem> */}
            {/* <MenuItem onClick={handleClose} component={Link} to="/calendar">
              Calendar
            </MenuItem> */}
            {/* <MenuItem onClick={handleClose} component={Link} to="/bar">
              Bar Chart
            </MenuItem>
            <MenuItem onClick={handleClose} component={Link} to="/pie">
              Pie Chart
            </MenuItem>
            <MenuItem onClick={handleClose} component={Link} to="/geography">
              Heat Map
            </MenuItem> */}
          </Menu>
          {/* Logout Button */}
        </Toolbar>
      </AppBar>
    )
  );
};

export default NavBar;
