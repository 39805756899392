import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import { Divider, Drawer, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import { useStoresContext } from "../../context/storesfetch";
import EditCustomerForm from "../../Drawers/EditCustomerDrawer";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import { Button, Modal } from "react-bootstrap";
import {
  changeCustomerStatus,
  deleteCustomers,
  RestoreCustomer,
} from "../../../services/allcustomers/apicalls";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import "../../styles/menus.css";
export default function StoresMenu({ routes, params }) {
  const { toggleStoresUpdateFlag } = useStoresContext();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  ///////////////////// Restore Users Modal Configuration //////////////////////////////////////////

  const [restoreLoading, setRestoreLoading] = useState(false);

  const [restoreDialogOpen, setRestoreDialogOpen] = useState(false);
  const [selectedUserIdRestore, setSelectedUserIdRestore] = useState(null);
  const handleRestore = (userId) => {
    setSelectedUserIdRestore(userId);
    setRestoreDialogOpen(true);
  };

  const handleCloseResotreModal = () => {
    setSelectedUserIdRestore(null);
    setRestoreDialogOpen(false);
  };

  const RestoreUser = async (customerId) => {
    setRestoreLoading(true);
    try {
      const { message } = await RestoreCustomer(customerId);

      toast.success(message);
      toggleStoresUpdateFlag();

      handleCloseResotreModal();
    } catch (err) {
      console.log(err);
      toast.error("Error Restoring Route, try again");
    } finally {
      handleClose();

      setRestoreLoading(false);
    }
  };
  ///////////////////// Delete Modal Configuration //////////////////////////////////////////

  const [deleteloading, setdeleteloading] = useState(false);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedUserIdDelete, setSelectedUserIdDelete] = useState(null);
  const handleDelete = (userId) => {
    setSelectedUserIdDelete(userId);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setSelectedUserIdDelete(null);
    setDeleteDialogOpen(false);
  };

  const deleteUser = async (customerId) => {
    setdeleteloading(true);
    try {
      const { message } = await deleteCustomers(customerId);

      toast.success(message);
      toggleStoresUpdateFlag();

      handleCloseDeleteModal();
    } catch (err) {
      console.log(err);
      toast.error("Error deleting User, try again");
    } finally {
      handleClose();

      setdeleteloading(false);
    }
  };
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //////////////////////////// Edit User Configurations////////////////////////
  const [isEditStoreDrawerOpen, setEditStoreDrawerOpen] = useState(false);

  const toggleEditDrawer = () => {
    setEditStoreDrawerOpen(!isEditStoreDrawerOpen);
  };

  ///////////////////////////////////////////////////// Activate or deactivate User Modal
  const [statusloading, setStatusloading] = useState(false);

  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [selectedUserIdStatus, setSelectedUserIdStatus] = useState(null);
  const [userCurrentStatus, setUserCurrentStatus] = useState(null);
  const handlestatus = (customerId, status) => {
    setUserCurrentStatus(status);
    setSelectedUserIdStatus(customerId);
    setStatusDialogOpen(true);
  };

  const handleCloseStatusModal = () => {
    setSelectedUserIdStatus(null);
    setUserCurrentStatus(null);
    setStatusDialogOpen(false);
  };

  const changeStatusForStore = async (customerId, status) => {
    setStatusloading(true);
    try {
      const data = {
        customer_status: status === "Active" ? "Inactive" : "Active",
      };

      const { message } = await changeCustomerStatus(customerId, data);
      if (message === "Status changed succesfully") {
        toast.success(message);
        toggleStoresUpdateFlag();
      } else {
        // Handle unexpected messages
        toast.error(message || "Unexpected response from server");
      }
    } catch (err) {
      toast.error("Error deleting User, try again");
    } finally {
      handleCloseStatusModal();
      handleClose();

      setStatusloading(false);
    }
  };
  ///////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////////////////
  const handleMenuItemClick = (action) => {
    handleClose();

    if (action === "Remove") {
      handleDelete(params.row.customer_id); // Set selected user ID for deletion
    } else if (action === "Restore") {
      handleRestore(params.row.customer_id);
    } else if (action === "Edit") {
      toggleEditDrawer();
    } else if (action === "Status") {
      handlestatus(params.row.customer_id, params.row.customer_status);
    }
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          className="rounded mt-1"
          onClick={() => handleMenuItemClick("Edit")}
        >
          <EditIcon
            sx={{
              fontSize: "15px",
              marginRight: "5px",
              color: "rgba(57, 71, 83, 1)",
            }}
          />
          <span
            style={{
              color: "rgba(57, 71, 83, 1)",
              fontFamily: "Poppins",
              fontSize: "12px",
              lineHeight: "18px",
            }}
          >
            Edit Store
          </span>
        </MenuItem>
        {params.row.customer_deleted === false && (
          <>
            {" "}
            <Divider />
            <MenuItem
              className="mt-1 rounded"
              onClick={() => handleMenuItemClick("Status")}
            >
              <DoNotDisturbAltIcon
                sx={{
                  fontSize: "14px",
                  marginRight: "5px",
                  color:
                    params.row.customer_status === "Active"
                      ? "rgba(198, 40, 40, 1)"
                      : "#037847",
                }}
              />
              <span
                style={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  lineHeight: "18px",
                  color:
                    params.row.customer_status === "Active"
                      ? "rgba(198, 40, 40, 1)"
                      : "#037847",
                }}
              >
                {params.row.customer_status === "Active"
                  ? "Deactivate"
                  : "Activate"}
              </span>
            </MenuItem>
          </>
        )}
        {params.row.customer_deleted === false && <Divider />}
        {params.row.customer_deleted === false ? (
          <MenuItem
            className="mt-1 rounded"
            onClick={() => handleMenuItemClick("Remove")}
          >
            <DeleteForeverOutlinedIcon
              sx={{
                fontSize: "14px",
                marginRight: "5px",
                color: "rgba(198, 40, 40, 1)",
              }}
            />
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "12px",
                lineHeight: "18px",
                color: "rgba(198, 40, 40, 1)",
              }}
            >
              Remove{" "}
            </span>
          </MenuItem>
        ) : (
          <MenuItem
            className="mt-1 rounded"
            onClick={() => handleMenuItemClick("Restore")}
          >
            <RestoreOutlinedIcon
              sx={{
                fontSize: "14px",
                marginRight: "5px",
                color: "#037847",
              }}
            />
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "12px",
                lineHeight: "18px",
                color: "#037847",
              }}
            >
              Restore{" "}
            </span>
          </MenuItem>
        )}
      </Menu>
      <Drawer
        anchor="right"
        open={isEditStoreDrawerOpen}
        onClose={toggleEditDrawer}
        sx={{
          "& .MuiDrawer-paper": { width: "50%", boxSizing: "border-box" },
        }}
      >
        <EditCustomerForm
          user={params.row}
          routes={routes}
          onClose={toggleEditDrawer}
        />
      </Drawer>
      <Modal show={statusDialogOpen} onHide={handleCloseStatusModal} centered>
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              fontFamily: "Poppins",
              fontWeight: "600",
              fontSize: "20px",
              lineHeight: "24px",
              letterSpacing: "-0.22px",
              color: "#000000",
              textTransform: "capitalize",
            }}
          >
            {params.row.customer_status === "Active"
              ? "Deactivate "
              : "Activate "}
            Store
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            padding: "2rem 1rem",
            fontFamily: "Poppins",
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "22px",
            letterSpacing: "-0.2px",
            color: "#000000",
            textTransform: "capitalize",
          }}
        >
          Are you sure you want to{" "}
          {params.row.customer_status === "Active" ? "deactivate" : "Activate"}{" "}
          this Store?
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleCloseStatusModal}
            style={{
              borderRadius: "30px ",
              border: "1px solid rgba(23, 71, 153, 1)",
              opacity: "1",
              fontFamily: "Poppins",
              fontSize: "12px",
              lineHeight: "25px",
              fontWeight: "600",
              color: "rgba(23, 71, 153, 1)",
              letterSpacing: "-0.18px",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              textTransform: "capitalize",
              backgroundColor: "transparent",
            }}
            className="px-5 py-2 mr-3"
            type="close"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              changeStatusForStore(selectedUserIdStatus, userCurrentStatus);
            }}
            type="submit"
            style={{
              border: "none",
              background:
                params.row.customer_status === "Active"
                  ? "rgba(198, 40, 40, 1)"
                  : "#03A9F4",
              borderRadius: "30px ",
              opacity: "1",
              fontFamily: "Poppins",
              fontSize: "12px",
              lineHeight: "25px",
              fontWeight: "600",
              color: "#FFFFFF",
            }}
            className="px-5 py-2 "
            disabled={statusloading}
          >
            {statusloading
              ? "Loading..."
              : `${
                  params.row.customer_status === "Active"
                    ? "Deactivate"
                    : "Activate"
                }`}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={restoreDialogOpen} onHide={handleCloseResotreModal} centered>
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              fontFamily: "Poppins",
              fontWeight: "600",
              fontSize: "20px",
              lineHeight: "24px",
              letterSpacing: "-0.22px",
              color: "#000000",
              textTransform: "capitalize",
            }}
          >
            Restore Store{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            padding: "2rem 1rem",
            fontFamily: "Poppins",
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "22px",
            letterSpacing: "-0.2px",
            color: "#000000",
            textTransform: "capitalize",
          }}
        >
          Are you sure you want to restore this Store?
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleCloseResotreModal}
            style={{
              borderRadius: "30px ",
              border: "1px solid rgba(23, 71, 153, 1)",
              opacity: "1",
              fontFamily: "Poppins",
              fontSize: "12px",
              lineHeight: "25px",
              fontWeight: "600",
              color: "rgba(23, 71, 153, 1)",
              letterSpacing: "-0.18px",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              textTransform: "capitalize",
              backgroundColor: "transparent",
            }}
            className="px-5 py-2 mr-3"
            type="close"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              RestoreUser(selectedUserIdRestore);
            }}
            type="submit"
            style={{
              border: "none",
              background: "#03A9F4",

              borderRadius: "30px ",
              opacity: "1",
              fontFamily: "Poppins",
              fontSize: "12px",
              lineHeight: "25px",
              fontWeight: "600",
              color: "#FFFFFF",
            }}
            className="px-5 py-2 "
            disabled={restoreLoading}
          >
            {restoreLoading ? "Loading..." : "Restore"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={deleteDialogOpen} onHide={handleCloseDeleteModal} centered>
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              fontFamily: "Poppins",
              fontWeight: "600",
              fontSize: "20px",
              lineHeight: "24px",
              letterSpacing: "-0.22px",
              color: "#000000",
              textTransform: "capitalize",
            }}
          >
            Remove Store
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            padding: "2rem 1rem",
            fontFamily: "Poppins",
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "22px",
            letterSpacing: "-0.2px",
            color: "#000000",
            textTransform: "capitalize",
          }}
        >
          Are you sure you want to remove this Store?
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleCloseDeleteModal}
            style={{
              borderRadius: "30px ",
              border: "1px solid rgba(23, 71, 153, 1)",
              opacity: "1",
              fontFamily: "Poppins",
              fontSize: "12px",
              lineHeight: "25px",
              fontWeight: "600",
              color: "rgba(23, 71, 153, 1)",
              letterSpacing: "-0.18px",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              textTransform: "capitalize",
              backgroundColor: "transparent",
            }}
            className="px-5 py-2 mr-3"
            type="close"
          >
            Cancel
          </Button>
          <Button
            style={{
              border: "none",
              background: "rgba(198, 40, 40, 1)",

              borderRadius: "30px ",
              opacity: "1",
              fontFamily: "Poppins",
              fontSize: "12px",
              lineHeight: "25px",
              fontWeight: "600",
              color: "#FFFFFF",
            }}
            className="px-5 py-2 "
            onClick={() => {
              deleteUser(selectedUserIdDelete);
            }}
            type="submit"
            disabled={deleteloading}
          >
            {deleteloading ? "Loading..." : "Remove"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
