import React, { useCallback, useMemo, useState } from "react";
import { TextField, Grid, InputAdornment, Autocomplete } from "@mui/material";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import "../styles/galleryfilters.css";
import RouteIcon from "@mui/icons-material/Route";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";

const RouteSearchFilters = ({ onSearch, onReset, data }) => {
  const [searchFilters, setSearchFilters] = useState({
    route_app_id: "",
    route_name: "",
    route_status: "",
  });

  const handleAutocompleteChange = useCallback(
    (event, newValue, reason, field) => {
      if (reason === "input") {
        setSearchFilters((prevFilters) => ({
          ...prevFilters,
          [field]: event.target.value,
        }));
      } else if (reason === "reset" && newValue) {
        setSearchFilters((prevFilters) => ({
          ...prevFilters,
          [field]: newValue,
        }));
      }
    },
    []
  );

  const fieldLabels = {
    route_name: "Route Name",

    route_app_id: "Route Id",
    route_status: "Status",
  };

  const options = useMemo(() => {
    const fieldOptions = {
      route_app_id: new Set(),
      route_name: new Set(),
      route_status: ["Active", "Inactive"],
    };

    data.forEach((item) => {
      if (item.route_name) fieldOptions.route_name.add(item.route_name);
      if (item.route_app_id) fieldOptions.route_app_id.add(item.route_app_id);
    });

    return {
      route_name: Array.from(fieldOptions.route_name),
      route_app_id: Array.from(fieldOptions.route_app_id),
      route_status: fieldOptions.route_status,
    };
  }, [data]); // Dependency on data
  const handleSearch = useCallback(() => {
    onSearch(searchFilters);
  }, [searchFilters, onSearch]);

  const handleReset = useCallback(() => {
    setSearchFilters({
      route_app_id: "",

      route_name: "",
      route_status: "",
    });
    onReset();
  }, [onReset]);
  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        style={{ marginBottom: "1rem" }}
      >
        {Object.keys(searchFilters).map((key) => (
          <Grid item xs={12} sm={3} key={key}>
            <Autocomplete
              id="FullName-autocomplete"
              fullWidth
              disableClearable
              freeSolo
              value={searchFilters[key]}
              isOptionEqualToValue={(option, value) =>
                option === value || value === "" || !option
              }
              onInputChange={(event, newValue, reason) =>
                handleAutocompleteChange(event, newValue, reason, key)
              }
              placeholder={`Search by ${fieldLabels[key]}`}
              options={options[key] || []}
              getOptionLabel={(option) => option || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={key}
                  placeholder={`Search by ${fieldLabels[key]}`}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        {key === "route_app_id" && <RouteIcon />}

                        {key === "route_name" && <DriveFileRenameOutlineIcon />}
                        {key === "route_status" && <ToggleOnIcon />}
                      </InputAdornment>
                    ),
                    style: {
                      height: "40px",
                      padding: "5px 16px",
                      maxWidth: "250px",
                      boxShadow: "0px 3px 6px #a8a8a833",
                      borderRadius: "50px",
                    },
                  }}
                  className="custom-autocomplete-input"
                />
              )}
            />
          </Grid>
        ))}
        <Grid
          item
          xs={12}
          sm={3}
          style={{ display: "flex", justifyContent: "flex-end", gap: "1rem" }}
        >
          {" "}
          <button
            type="button"
            onClick={handleSearch}
            className="btn py-3 px-5 d-flex align-items-center justify-content-center"
            style={{
              background: "rgba(19, 67, 149, 1)",
              borderRadius: "30px",
              fontFamily: "Poppins",
              fontSize: "12px",
              lineHeight: "25px",
              fontWeight: "600",
              color: "#FFFFFF",
              letterSpacing: "-0.16px",
              height: "40px",
              width: "66.67%",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              maxWidth: "140px",
            }}
          >
            Search
          </button>
          <button
            type="button"
            onClick={handleReset}
            className="btn py-3 px-5 d-flex align-items-center"
            style={{
              background: "transparent",
              border: "1px solid rgba(19, 67, 149, 1)",
              borderRadius: "50px",
              fontFamily: "Poppins",
              fontSize: "12px",
              lineHeight: "16px",
              fontWeight: "500",
              color: "rgba(19, 67, 149, 1)",
              letterSpacing: "-0.16px",
              height: "40px",
              width: "33.33%", // Takes 1/3 of the width
              textAlign: "center", // Center text alignment
              display: "flex", // Flex to center the content
              justifyContent: "center", // Horizontally center the text
              alignItems: "center", // Vertically center the text
              maxWidth: "90px",
            }}
          >
            Reset
          </button>
        </Grid>
      </Grid>
    </>
  );
};

export default RouteSearchFilters;
