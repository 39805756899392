import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import * as yup from "yup";
import { Input, Ripple, initTWE } from "tw-elements";
import { Form, Formik } from "formik";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LockIcon from "@mui/icons-material/Lock";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import { toast } from "react-toastify";

import CloseIcon from "@mui/icons-material/Close";
import { useSalesRepContext } from "../../context/salesfetch";
import { editUsers } from "../../../services/users/usersApis";
import CancelButton from "../../buttons/cancelButton";
import SubmitButton from "../../buttons/submitFormButton";

initTWE({ Input, Ripple });
const initialValues = {
  user_password: "",
  check_password: "",
};
const checkoutSchema = yup.object().shape({
  user_password: yup
    .string()
    .min(6, "Password must be at least 6 characters long")
    .required("Password is required"),
  check_password: yup
    .string()
    .required("Please confirm your password")
    .oneOf([yup.ref("user_password"), null], "Passwords must match"),
});

export default function ChangePasswordForm({ userId, onClose }) {
  const { toggleUpdateFlag } = useSalesRepContext();

  const [changePasswordLoading, setchangePasswordLoading] = useState(false);
  const [checkpasswordVisible, setCheckPasswordVisible] = useState(false);
  const toggleCheckPasswordVisibility = () => {
    setCheckPasswordVisible(!checkpasswordVisible);
  };
  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleFormSubmit = async (values, { resetForm }) => {
    setchangePasswordLoading(true);
    const data = { user_password: values["user_password"] };

    try {
      const { message } = await editUsers(data, userId);
      if (message === "User Updated successfully") {
        toast.success("Password Changed successfully");
        toggleUpdateFlag();
        onClose();
      } else {
        toast.error(message);
      }
    } catch (err) {
      const errorMessage =
        err.response && err.response.data.message
          ? err.response.data.message
          : "Error updating Sales Rep password, try again";
      toast.error(errorMessage);
    } finally {
      setchangePasswordLoading(false);
    }
  };

  return (
    <Box sx={{ width: "100%" }} autoComplete="off">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          boxShadow: "0px 3px 6px #A8A8A81F",
        }}
        autoComplete="off"
        className="p-4 mb-5"
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "24px",
            letterSpacing: "-0.22px",
            color: " rgba(75, 75, 75, 1)",
          }}
        >
          Reset Password
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Formik
        initialValues={initialValues}
        validationSchema={checkoutSchema}
        onSubmit={(values, formikHelpers) =>
          handleFormSubmit(values, formikHelpers)
        }
      >
        {({ getFieldProps, touched, errors }) => (
          <Form className="px-4 ">
            <div className="flex mb-2 ">
              <div className="flex w-50 flex-col mr-4">
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                  htmlFor="Password"
                  className="mb-2 input-label"
                >
                  New Password *
                </label>
                <TextField
                  id="outlined-basic7"
                  variant="outlined"
                  type={passwordVisible ? "text" : "password"}
                  autoComplete="Password"
                  required
                  placeholder="User Password"
                  {...getFieldProps("user_password")}
                  error={touched.user_password && Boolean(errors.user_password)}
                  helperText={touched.user_password && errors.user_password}
                  className="mt-1 mb-4 rounded-lg"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon style={{ color: "rgba(19, 67, 149, 1)" }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={togglePasswordVisibility}
                          edge="end"
                        >
                          {passwordVisible ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="flex  w-50 flex-col">
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                  htmlFor="Check Password"
                  className="mb-2 input-label"
                >
                  Confirm New Password *
                </label>
                <TextField
                  id="outlined-basic8"
                  variant="outlined"
                  placeholder="Confirm Password"
                  type={checkpasswordVisible ? "text" : "password"}
                  autoComplete="Check Password"
                  required
                  {...getFieldProps("check_password")}
                  error={
                    touched.check_password && Boolean(errors.check_password)
                  }
                  helperText={touched.check_password && errors.check_password}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon style={{ color: "rgba(19, 67, 149, 1)" }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle Check password visibility"
                          onClick={toggleCheckPasswordVisibility}
                          edge="end"
                        >
                          {checkpasswordVisible ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1 mb-4 rounded-lg"
                />
              </div>
            </div>

            <div
              className="text-end "
              style={{
                marginTop: "55vh",
              }}
            >
              {" "}
              <CancelButton onClose={onClose} />
              <SubmitButton
                disabled={changePasswordLoading}
                loading={changePasswordLoading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
