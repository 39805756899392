import React, { useCallback, useMemo, useState } from "react";
import {
  TextField,
  Grid,
  InputAdornment,
  Autocomplete,
  MenuItem,
  Menu,
  FormControlLabel,
  Checkbox,
  Box,
} from "@mui/material";
import { Person, CalendarToday } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

import RouteIcon from "@mui/icons-material/Route";
import AddIcon from "@mui/icons-material/Add";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "../styles/galleryfilters.css";

const SurveyCustomFilters = ({ onSearch, onReset, data }) => {
  const [searchFilters, setSearchFilters] = useState({
    user_name: "",
    user_position: "",
    customer_name: "",
    route_app_id: "",
    survey_ques_ref: "",
    visit_start_time_stamp: "",
    startDate: null,
    endDate: null,
  });

  /////////// filters viewed configuration
  const [filterVisibility, setFilterVisibility] = useState({
    user_name: true,
    user_position: true,
    customer_name: true,
    route_app_id: true,
    survey_ques_ref: false,
    visit_start_time_stamp: false,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  /////////// functions to be triggered when the add button is clicked
  const handleAddClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleDateChange = (newValue, field) => {
    setSearchFilters((prevFilters) => ({
      ...prevFilters,
      [field]: newValue,
    }));
  };

  //// hiding and showing the filters
  const handleFilterToggle = (filter) => {
    setFilterVisibility((prevVisibility) => {
      const isVisible = prevVisibility[filter];
      return {
        ...prevVisibility,
        [filter]: !isVisible,
      };
    });

    // Set value to empty string if filter is being removed
    if (filterVisibility[filter]) {
      if (filter === "visit_start_time_stamp") {
        setSearchFilters((prevValues) => ({
          ...prevValues,
          [filter]: "",
          endDate: null,
          startDate: null,
        }));
      } else {
        setSearchFilters((prevValues) => ({
          ...prevValues,
          [filter]: "",
        }));
      }
    }
  };

  ///////////////////////////////////////
  const handleAutocompleteChange = useCallback(
    (event, newValue, reason, field) => {
      if (reason === "input") {
        setSearchFilters((prevFilters) => ({
          ...prevFilters,
          [field]: event.target.value,
        }));
      } else if (reason === "reset" && newValue) {
        setSearchFilters((prevFilters) => ({
          ...prevFilters,
          [field]: newValue,
        }));
      }
    },
    []
  );

  const fieldLabels = {
    user_name: "Submitter",
    user_position: "Role",
    customer_name: "Store Name",
    route_app_id: "Route Id",
    survey_ques_ref: "Survey Type",
    visit_start_time_stamp: "Date",
  };

  const options = useMemo(() => {
    const fieldOptions = {
      user_name: new Set(),
      user_position: new Set(),
      customer_name: new Set(),
      route_app_id: new Set(),
      survey_ques_ref: new Set(),
    };

    data.forEach((item) => {
      if (item.user_name) fieldOptions.user_name.add(item.user_name);
      if (item.user_position)
        fieldOptions.user_position.add(item.user_position);
      if (item.customer_name)
        fieldOptions.customer_name.add(item.customer_name);
      if (item.route_app_id) fieldOptions.route_app_id.add(item.route_app_id);
      if (item.survey_ques_ref)
        fieldOptions.survey_ques_ref.add(item.survey_ques_ref);
    });

    return {
      user_name: Array.from(fieldOptions.user_name),
      user_position: Array.from(fieldOptions.user_position),
      customer_name: Array.from(fieldOptions.customer_name),
      route_app_id: Array.from(fieldOptions.route_app_id),

      survey_ques_ref: Array.from(fieldOptions.survey_ques_ref),
    };
  }, [data]); // Dependency on data
  const handleSearch = useCallback(() => {
    onSearch(searchFilters);
  }, [searchFilters, onSearch]);

  const handleReset = useCallback(() => {
    setSearchFilters({
      user_name: "",
      user_position: "",
      customer_name: "",
      route_app_id: "",
      survey_ques_ref: "",
      visit_start_time_stamp: "",
      endDate: null,
      startDate: null,
    });
    onReset();
  }, [onReset]);
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          style={{ marginBottom: "1rem" }}
        >
          <Grid item>
            <h6
              style={{
                margin: 0,
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "42px",
              }}
            >
              Search by
            </h6>
          </Grid>
          <Grid item xs={12} sm="auto">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                width: "100%",
              }}
            >
              <button
                type="button"
                onClick={handleSearch}
                className="btn py-3 px-5 d-flex align-items-center justify-content-center"
                style={{
                  background: "rgba(19, 67, 149, 1)",
                  borderRadius: "30px",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  lineHeight: "25px",
                  fontWeight: "600",
                  color: "#FFFFFF",
                  letterSpacing: "-0.16px",
                  height: "40px",
                  width: "66.67%",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  maxWidth: "140px",
                }}
              >
                Search
              </button>
              <button
                type="button"
                onClick={handleReset}
                className="btn py-3 px-5 d-flex align-items-center"
                style={{
                  background: "transparent",
                  border: "1px solid rgba(19, 67, 149, 1)",
                  borderRadius: "50px",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  lineHeight: "16px",
                  fontWeight: "500",
                  color: "rgba(19, 67, 149, 1)",
                  letterSpacing: "-0.16px",
                  height: "40px",
                  width: "33.33%", // Takes 1/3 of the width
                  textAlign: "center", // Center text alignment
                  display: "flex", // Flex to center the content
                  justifyContent: "center", // Horizontally center the text
                  alignItems: "center", // Vertically center the text
                  maxWidth: "90px",
                }}
              >
                Reset
              </button>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          sx={{
            width: "100%",
            marginBottom: "1rem",
            flexWrap: { xs: "wrap" },
            gap: "1rem",
          }}
        >
          {Object.keys(searchFilters)
            .filter((key) => filterVisibility[key])
            .map((key) => (
              <Grid
                item
                xs={12}
                sm={key === "visit_start_time_stamp" ? 4.8 : 2.4}
                key={key}
              >
                {key === "visit_start_time_stamp" ? (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <DatePicker
                      value={
                        searchFilters.startDate
                          ? dayjs(searchFilters.startDate)
                          : null
                      }
                      onChange={(newDate) =>
                        handleDateChange(newDate, "startDate")
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="startDate"
                          placeholder="Start Date"
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <CalendarToday />
                              </InputAdornment>
                            ),
                            style: {
                              display: "flex",
                              alignItems: "center",
                              padding: "0.5rem",
                            },
                          }}
                          className="custom-autocomplete-input"
                        />
                      )}
                    />
                    <Box mx={0.5} fontSize="1.25rem">
                      -
                    </Box>
                    <DatePicker
                      value={
                        searchFilters.endDate
                          ? dayjs(searchFilters.endDate)
                          : null
                      }
                      onChange={(newDate) =>
                        handleDateChange(newDate, "endDate")
                      }
                      minDate={
                        searchFilters.startDate
                          ? dayjs(searchFilters.startDate)
                          : null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="endDate"
                          placeholder="End Date"
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <CalendarToday />
                              </InputAdornment>
                            ),
                            style: {
                              display: "flex",
                              alignItems: "center",
                              padding: "0.5rem",
                            },
                          }}
                          className="custom-autocomplete-input"
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  <Autocomplete
                    fullWidth
                    disableClearable
                    freeSolo
                    value={searchFilters[key]}
                    isOptionEqualToValue={(option, value) =>
                      option === value || value === "" || !option
                    }
                    onInputChange={(event, newValue, reason) =>
                      handleAutocompleteChange(event, newValue, reason, key)
                    }
                    placeholder={`${fieldLabels[key]}`}
                    options={options[key] || []}
                    getOptionLabel={(option) => option || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name={key}
                        placeholder={`${fieldLabels[key]}`}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              {key === "user_name" && <Person />}
                              {key === "user_position" && <VpnKeyIcon />}

                              {key === "customer_name" && (
                                <StoreMallDirectoryIcon />
                              )}
                              {key === "route_app_id" && <RouteIcon />}

                              {key === "survey_ques_ref" && (
                                <SummarizeOutlinedIcon />
                              )}
                            </InputAdornment>
                          ),
                          style: {
                            height: "40px",
                            padding: "5px 16px",
                            maxWidth: "250px",
                            boxShadow: "0px 3px 6px #a8a8a833",
                            borderRadius: "50px",
                          },
                        }}
                        className="custom-autocomplete-input"
                      />
                    )}
                  />
                )}
              </Grid>
            ))}
          <Grid
            item
            sx={{
              marginLeft: { sm: "auto" }, // Align the button to the right on larger screens
            }}
            sm="auto"
          >
            <button
              style={{
                background: "transparent",
                border: "1px solid rgba(19, 67, 149, 1)",
                borderRadius: "50px",
                fontFamily: "Poppins",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: "500",
                color: "rgba(19, 67, 149, 1)",
                letterSpacing: "-0.16px",
                height: "40px",
                padding: "6px 16px",
                opacity: "1",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={handleAddClick}
            >
              <AddIcon sx={{ marginRight: "2px", fontSize: "22px" }} />
              <span style={{ marginTop: "0.1rem" }}> Add</span>
            </button>
          </Grid>

          <Menu anchorEl={anchorEl} open={open} onClose={handleCloseMenu}>
            {[
              "user_name",
              "user_position",
              "customer_name",
              "route_app_id",
              "survey_ques_ref",
              "visit_start_time_stamp",
            ].map((filter) => (
              <MenuItem
                sx={{
                  padding: "4px 12px",
                  marginBottom: "4px",
                }}
                key={filter}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filterVisibility[filter]}
                      onChange={() => handleFilterToggle(filter)}
                      sx={{
                        color: "rgba(23, 71, 153, 1)", // Set the default unchecked color
                        "&.Mui-checked": {
                          color: "rgba(23, 71, 153, 1)", // Set the checked color to blue
                        },
                        "& .MuiSvgIcon-root": {
                          fontSize: "24px", // Adjust the size of the checkbox icon (similar to image)
                        },
                      }}
                    />
                  }
                  label={fieldLabels[filter]}
                />
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      </LocalizationProvider>
    </>
  );
};

export default SurveyCustomFilters;
