// TaskDeleteModal.js
import axios from "axios";
import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";

const TaskDeleteModal = ({ route_id, user_id, fetchDetails }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleDelete = async () => {
    try {
      const response = await axios.delete(
        `http://127.0.0.1:5000/api/routesmodify/${route_id}/${user_id}`
      );
      if (response.status === 200) {
        toast.success("Visit Deleted successfully");
        fetchDetails();
      }
    } catch (err) {
      toast.error("Error deleting visit");
    } finally {
      setShow(false);
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-outline-danger"
        onClick={handleShow}
      >
        Delete
      </button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Visit</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this visit?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TaskDeleteModal;
