// MapModal.js
import React from "react";
import { Modal, Button } from "react-bootstrap";
import MapView from "../mapView";

const MapModal = ({ show, onHide, coords }) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Map Location</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MapView coords={coords} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MapModal;
