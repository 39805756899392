import {
  Box,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addVisit, fetchRoutes } from "../../services/assignRoutes/apiCalls";

const Tasks = ({ profileId, closeModal, fetchDetails }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [filteredRoutes, setFilteredRoutes] = useState([]);

  const [routes, setRoutes] = useState([]);
  const [routesloading, setRoutesLoading] = useState(true);
  // Assuming this state is managed where your component is defined
  const [selectedRoute, setSelectedRoute] = useState([]);

  const handleRouteSearch = (value) => {
    const seen = new Set();

    const filtered = routes
      .filter((route) => {
        const route_id = route && route["route_name"];
        if (
          route_id &&
          route_id.toLowerCase().includes(value) &&
          !seen.has(route_id)
        ) {
          seen.add(route_id);
          return true;
        }
        return false;
      })
      .slice(0, 20);
    setFilteredRoutes(filtered);
  };
  //// to foramt the values of the users for more efficient search

  useEffect(() => {
    async function loadData() {
      const { data: Routes_data, error: RoutesError } = await fetchRoutes(
        profileId
      );
      if (!RoutesError) {
        setRoutes(Routes_data);
        setRoutesLoading(false);
      } else {
        console.log(RoutesError);
      }
    }

    loadData();
  }, []);
  // to make the api call here
  const handleFormSubmit = async (values, { resetForm }) => {
    const [startYear, startMonth] = values.dateField.split("-").map(Number);
    if (values.route_monthly_repeated) {
      values = {
        ...values,
        visitDurationMonths: "",
        route_end_month: "",
        route_end_year: "",
      };
    } else {
      values = {
        ...values,
      };
      if (!values.route_monthly_repeated && values.visitDurationMonths) {
        const durationMonths = parseInt(values.visitDurationMonths, 10);
        if (durationMonths >= 1 && durationMonths <= 12) {
          const startDate = new Date(values.dateField);
          startDate.setMonth(startDate.getMonth() + durationMonths);

          // Add end_visit_month and end_visit_year to values
          values = {
            ...values,
            route_end_month: startDate.getMonth() + 1, // JavaScript months are 0-indexed
            route_end_year: startDate.getFullYear(),
          };
        }
      }
    }
    const { dateField, visitDurationMonths, ...rest } = values;
    const FinalData = {
      ...rest,
      route_start_year: startYear,
      route_start_month: startMonth,
      user_id: profileId,
    };
    try {
      const { success, data, error } = await addVisit(FinalData);

      if (success) {
        toast.success(data.message);
        closeModal();
        fetchDetails();
        // history.push("/");
      } else {
        toast.error(error.message);
      }
    } catch (err) {
      console.log(err);
      toast.error("Error while adding visit , try again !");
    }
  };
  if (routesloading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <Box sx={{ margin: "20px auto", width: "90%" }}>
      <Header title="Assign Visit " subtitle="Assign a New Visit" />

      <Formik
        onSubmit={(values, formikHelpers) =>
          handleFormSubmit(values, formikHelpers)
        }
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Visit Comment"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.route_comment}
                name="route_comment"
                error={!!touched.route_comment && !!errors.route_comment}
                helperText={touched.route_comment && errors.route_comment}
                sx={{ gridColumn: "span 4" }}
              />
              <Autocomplete
                multiple
                options={filteredRoutes}
                getOptionLabel={(option) => option["route_name"]}
                isOptionEqualToValue={(option, value) =>
                  option["route_id"] === value["route_id"]
                }
                sx={{ gridColumn: "span 4" }}
                value={selectedRoute}
                onChange={(event, newValue) => {
                  setSelectedRoute(newValue);
                  const routes = newValue.map((user) => user["route_id"]);
                  setFieldValue("routes_ids", routes);
                }}
                onInputChange={(event, newInputValue) => {
                  handleRouteSearch(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="filled"
                    label="Routes"
                    name="routes_ids"
                    error={!!touched.routes_ids && !!errors.routes_ids}
                    helperText={touched.routes_ids && errors.routes_ids}
                    sx={{ gridColumn: "span 4" }}
                  />
                )}
              />
              <TextField
                label="Choose a date"
                type="month"
                name="dateField"
                value={values.dateField}
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{
                  shrink: true,
                }}
                error={touched.dateField && Boolean(errors.dateField)}
                helperText={touched.dateField && errors.dateField}
                sx={{ gridColumn: "span 4" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.route_monthly_repeated}
                    onChange={(event) => {
                      setFieldValue(
                        "route_monthly_repeated",
                        event.target.checked
                      );
                    }}
                    name="route_monthly_repeated"
                  />
                }
                label="Mark as default monthly visit"
                sx={{ gridColumn: "span 4" }}
              />
              {!values.route_monthly_repeated && (
                <FormControl fullWidth sx={{ gridColumn: "span 4" }}>
                  <InputLabel id="visit-duration-select-label">
                    Visit Duration
                  </InputLabel>
                  <Select
                    labelId="visit-duration-select-label"
                    id="visitDurationMonths"
                    name="visitDurationMonths"
                    value={values.visitDurationMonths}
                    label="Visit Duration"
                    onChange={handleChange}
                  >
                    {[...Array(12).keys()].map((number) => (
                      <MenuItem key={number + 1} value={number + 1}>
                        {number + 1} Month{number + 1 > 1 ? "s" : ""}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}{" "}
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Create New Task
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

//   for validation function
const checkoutSchema = yup.object().shape({
  route_comment: yup.string().required("Required"),
  // routes_ids: yup
  //   .array()
  //   .min(1, "At least one route should be selected")
  //   .required("Required"),

  dateField: yup.date().required("Required"),
});
const initialValues = {
  route_comment: "",
  route_start_month: "",
  route_start_year: "",
  route_end_month: "",
  route_end_year: "",
  route_monthly_repeated: true,

  routes_ids: [],
  dateField: "",
  visitDurationMonths: "",
};

export default Tasks;
