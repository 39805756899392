import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import smjLogo from "../../components/images/smjrefined.png";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import { Collapse, List, ListItem, ListItemText } from "@mui/material";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DataSaverOffOutlinedIcon from "@mui/icons-material/DataSaverOffOutlined";
import "../../components/styles/sidebar.css";
import RouteOutlinedIcon from "@mui/icons-material/RouteOutlined";
import StoreMallDirectoryOutlinedIcon from "@mui/icons-material/StoreMallDirectoryOutlined";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import PhotoLibraryOutlinedIcon from "@mui/icons-material/PhotoLibraryOutlined";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { mapUserRole } from "../../utils/helperFunctions";
import { useUser } from "../../components/context/userAuth";
import { act } from "react";

const Sidebar = () => {
  const { user } = useUser();

  const [activeLink, setActiveLink] = useState("");
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    setShow(location.pathname !== "/login");
    setActiveLink(location.pathname);
    const token = Cookies.get("Price_App_Token");
    if (token) {
      const { user_position } = jwtDecode(token);
      const mappedRole = mapUserRole(user_position);
      setUserRole(mappedRole);
    }
  }, [location.pathname]);

  const [expanded, setExpanded] = useState(false);
  const [galleryExpanded, setGalleryExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleNavLinkClick = (link) => {
    setExpanded(!expanded);
    setActiveLink(link);
  };
  const handleGalleryExpandClick = () => {
    setGalleryExpanded(!galleryExpanded);
  };

  const handleGalleryNavLinkClick = (link) => {
    setGalleryExpanded(!galleryExpanded);
    setActiveLink(link);
  };
  if (!show) return null;

  return (
    <div
      className=" py-2   text-white"
      style={{
        height: "fit-content",
        minHeight: "100%",
        background: "linear-gradient(180deg, #134395 0%, #3568BE 100%)",
        minWidth: "240px",
        maxWidth: "240px",
      }}
    >
      <div className="my-5 d-flex justify-content-center align-items-center">
        <img src={smjLogo} alt="logo" />
      </div>
      <ul>
        <li>
          <div
            className={`nav-link ${
              activeLink === "/dashboard" ? "activeLink" : "notActiveLink"
            }`}
            style={{
              padding: "1rem",
            }}
          >
            <DataSaverOffOutlinedIcon
              style={{ marginRight: 10, color: "#ffffffdb" }}
            />{" "}
            Dashboard
            <ExpandMoreIcon
              style={{
                marginLeft: "auto",
                cursor: "pointer",
                color: "#ffffffdb",
              }}
              onClick={handleExpandClick}
            />
          </div>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <List component="div" disablePadding style={{ marginLeft: "1rem" }}>
              <ListItem
                button
                component={NavLink}
                to="/visitAnalytics"
                onClick={() => handleNavLinkClick("/visitAnalytics")}
                className={`nav-link ${
                  activeLink === "/visitAnalytics"
                    ? "activeLink"
                    : "notActiveLink"
                }`}
              >
                <ListItemText primary="SOVI Analytics" />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to="/casesPacked"
                onClick={() => handleNavLinkClick("/casesPacked")}
                className={`nav-link ${
                  activeLink === "/casesPacked" ? "activeLink" : "notActiveLink"
                }`}
              >
                <ListItemText primary="Cases Packed SASA" />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to="/pricessasa"
                onClick={() => handleNavLinkClick("/pricessasa")}
                className={`nav-link ${
                  activeLink === "/pricessasa" ? "activeLink" : "notActiveLink"
                }`}
              >
                <ListItemText primary="Prices SASA" />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to="/pricesNewSurveys"
                onClick={() => handleNavLinkClick("/pricesNewSurveys")}
                className={`nav-link ${
                  activeLink === "/pricesNewSurveys"
                    ? "activeLink"
                    : "notActiveLink"
                }`}
              >
                <ListItemText primary="Prices SASA (New Surveys)" />
              </ListItem>

              <ListItem
                button
                component={NavLink}
                to="/priceTags"
                onClick={() => handleNavLinkClick("/priceTags")}
                className={`nav-link ${
                  activeLink === "/priceTags" ? "activeLink" : "notActiveLink"
                }`}
              >
                <ListItemText primary="Price Tags SASA" />
              </ListItem>

              <ListItem
                button
                component={NavLink}
                to="/planogramSasa"
                onClick={() => handleNavLinkClick("/planogramSasa")}
                className={`nav-link ${
                  activeLink === "/planogramSasa"
                    ? "activeLink"
                    : "notActiveLink"
                }`}
              >
                <ListItemText primary="Planogram SASA" />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to="/sosSasa"
                onClick={() => handleNavLinkClick("/sosSasa")}
                className={`nav-link ${
                  activeLink === "/sosSasa" ? "activeLink" : "notActiveLink"
                }`}
              >
                <ListItemText primary="SOS SASA" />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to="/displaySasa"
                onClick={() => handleNavLinkClick("/displaySasa")}
                className={`nav-link ${
                  activeLink === "/displaySasa" ? "activeLink" : "notActiveLink"
                }`}
              >
                <ListItemText primary="Display SASA" />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to="/expiredSasa"
                onClick={() => handleNavLinkClick("/expiredSasa")}
                className={`nav-link ${
                  activeLink === "/expiredSasa" ? "activeLink" : "notActiveLink"
                }`}
              >
                <ListItemText primary="Expired SASA" />
              </ListItem>

              <ListItem
                button
                component={NavLink}
                to="/oosSasa"
                onClick={() => handleNavLinkClick("/oosSasa")}
                className={`nav-link ${
                  activeLink === "/oosSasa" ? "activeLink" : "notActiveLink"
                }`}
              >
                <ListItemText primary="OOS SASA" />
              </ListItem>
            </List>
          </Collapse>
        </li>
        {user.user_position === "Administrator" && (
          <>
            <li>
              <NavLink
                to="/"
                className={`nav-link ${
                  activeLink === "/" ? "activeLink" : "notActiveLink"
                }`}
              >
                <ManageAccountsOutlinedIcon
                  style={{ marginRight: 10, color: "#ffffffdb" }}
                />{" "}
                User Management
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/routes"
                className={`nav-link ${
                  activeLink === "/routes" ? "activeLink" : "notActiveLink"
                }`}
              >
                <RouteOutlinedIcon
                  style={{ marginRight: 10, color: "#ffffffdb" }}
                />{" "}
                Route Management
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/stores"
                className={`nav-link ${
                  activeLink === "/stores" ? "activeLink" : "notActiveLink"
                }`}
              >
                <StoreMallDirectoryOutlinedIcon
                  style={{ marginRight: 10, color: "#ffffffdb" }}
                />{" "}
                Store Management
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/brands"
                className={`nav-link ${
                  activeLink === "/brands" ? "activeLink" : "notActiveLink"
                }`}
              >
                <SellOutlinedIcon
                  style={{ marginRight: 10, color: "#ffffffdb" }}
                />{" "}
                Product Management
              </NavLink>
            </li>
          </>
        )}
        <li>
          <NavLink
            to="/surveys"
            className={`nav-link ${
              activeLink === "/surveys" ? "activeLink" : "notActiveLink"
            }`}
          >
            <SummarizeOutlinedIcon
              style={{ marginRight: 10, color: "#ffffffdb" }}
            />{" "}
            Surveys Management
          </NavLink>
        </li>
        <li>
          <div
            className={`nav-link ${
              activeLink === "/gallery" ? "activeLink" : "notActiveLink"
            }`}
            style={{
              padding: "1rem",
            }}
          >
            <PhotoLibraryOutlinedIcon
              style={{ marginRight: 10, color: "#ffffffdb" }}
            />{" "}
            Gallery
            <ExpandMoreIcon
              style={{
                marginLeft: "auto",
                cursor: "pointer",
                color: "#ffffffdb",
              }}
              onClick={handleGalleryExpandClick}
            />
          </div>
          <Collapse in={galleryExpanded} timeout="auto" unmountOnExit>
            <List component="div" disablePadding style={{ marginLeft: "1rem" }}>
              <ListItem
                button
                component={NavLink}
                to="/visitsGallery"
                onClick={() => handleGalleryNavLinkClick("/visitsGallery")}
                className={`nav-link ${
                  activeLink === "/visitsGallery"
                    ? "activeLink"
                    : "notActiveLink"
                }`}
              >
                <ListItemText primary="Visits" />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to="/visitsImages"
                onClick={() => handleGalleryNavLinkClick("/visitsImages")}
                className={`nav-link ${
                  activeLink === "/visitsImages"
                    ? "activeLink"
                    : "notActiveLink"
                }`}
              >
                <ListItemText primary="Images" />
              </ListItem>
            </List>
          </Collapse>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
