import React, { useState, useRef } from "react";
import { toast } from "react-toastify";

import { uploadExcelFile } from "../../../services/allbrands/apicalls";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
const ExcelSheetUpload = ({ handleMenuClose }) => {
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const validExtensions = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ];
      const validMimeTypes = [".xlsx", ".xls"];

      const fileType = selectedFile.type;
      const fileExtension = selectedFile.name.split(".").pop();

      if (
        !validExtensions.includes(fileType) ||
        !validMimeTypes.includes(`.${fileExtension}`)
      ) {
        toast.error("Please upload a valid Excel file (xlsx or xls).");
      } else {
        await handleSubmit(selectedFile);
      }
    }
  };

  const handleSubmit = async (file) => {
    setLoading(true);

    try {
      const data = await uploadExcelFile(file);
      toast.success(data.message);
      // Clear the file input value
    } catch (error) {
      toast.error(error.message);
    } finally {
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      setLoading(false);
      handleMenuClose();
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div onClick={handleButtonClick} disabled={loading}>
      <input
        type="file"
        accept=".xlsx,.xls"
        id="fileInput"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />

      <div>
        <FileUploadOutlinedIcon
          sx={{
            fontSize: "15px",
            marginRight: "0px",
            color: "rgba(57, 71, 83, 1)",
          }}
        />{" "}
        <span
          style={{
            color: "rgba(57, 71, 83, 1)",
            fontFamily: "Poppins",
            fontSize: "12px",
            lineHeight: "18px",
            paddingTop: "0.1rem",
          }}
        >
          {loading ? "Uploading..." : "Upload Excel Sheet"}
        </span>
      </div>
    </div>
  );
};

export default ExcelSheetUpload;
