import React from "react";
import { Card, CardContent, Typography, CardMedia } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const ProductCards = ({ users }) => {
  return (
    <>
      {" "}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
        }}
      >
        {users.map((user) => (
          <Card key={user.id} style={{ width: "300px" }}>
            <CardContent>
              <Typography variant="h6">{user.product_name}</Typography>
              <Typography color="textSecondary">{user.product_size}</Typography>

              <Carousel showThumbs={false}>
                {user.image_url.map((image, index) => (
                  <div key={index}>
                    <CardMedia
                      component="img"
                      height="150"
                      image={image}
                      alt="Product Image"
                    />
                  </div>
                ))}
              </Carousel>

              <Typography variant="body2" style={{ marginTop: "10px" }}>
                User ID: {user.user_id}
              </Typography>
              <Typography variant="body2" style={{ marginTop: "10px" }}>
                Zone: {user.zone}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </div>
    </>
  );
};

export default ProductCards;
