import {
  Box,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import * as yup from "yup";
import { Input, Ripple, initTWE } from "tw-elements";
import { Form, Formik } from "formik";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import PersonIcon from "@mui/icons-material/Person";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import BadgeIcon from "@mui/icons-material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import { useState } from "react";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import determineAccessType, {
  mapUserRole,
} from "../../../utils/helperFunctions";
import { useSalesRepContext } from "../../context/salesfetch";
import { editUsers } from "../../../services/users/usersApis";
import { access_type, cities, roles } from "../../../data/data";
import { useUser } from "../../context/userAuth";
import CancelButton from "../../buttons/cancelButton";
import SubmitButton from "../../buttons/submitFormButton";

initTWE({ Input, Ripple });

const checkoutSchema = yup.object().shape({
  user_name: yup
    .string()
    .max(40, "Full Name must be at most 40 characters")
    .required("Full Name is required"),
  user_username: yup
    .string()
    .max(40, "User Name must be at most 40 characters"),
  user_email: yup
    .string()
    .email("Please enter a valid email address") // Customized error message for invalid email
    .required("Email is required") // Error message for the required condition
    .max(100, "Email must be at most 100 characters long"),
  // .required("User Name is required"),

  user_city: yup.string(),
  user_position: yup.string().required("Position is required"),
  user_access_type: yup.string().required("Position is required"),
  user_phone_no: yup
    .string()
    .max(20, "User Phone number Name must be at most 20 digits")
    .required("Phone Number is required"),
});

export default function EditUserForm({ user, onClose }) {
  const initialValues = {
    user_email: user.user_email || "",
    user_name: user.user_name || "",
    user_username: user.user_username || "",

    user_city: user.user_city || "",
    user_position: user.user_position || "",
    user_access_type:
      determineAccessType(
        user.user_access_dashboard,
        user.user_access_mobile_app
      ) || "",
    user_phone_no: user.user_phone_no || "",
  };
  const { updateUser } = useUser();

  const { toggleUpdateFlag } = useSalesRepContext();

  const [editUserLoading, setEditUserLoading] = useState(false);

  const handleFormSubmit = async (values, { resetForm }) => {
    setEditUserLoading(true);

    switch (values["user_access_type"]) {
      case "Dashboard":
        values["user_access_dashboard"] = true;
        values["user_access_mobile_app"] = false;
        break;
      case "Mobile Application":
        values["user_access_dashboard"] = false;
        values["user_access_mobile_app"] = true;
        break;
      default:
        values["user_access_dashboard"] = true;
        values["user_access_mobile_app"] = true;
    }
    const { user_access_type, ...data } = values;
    try {
      const { message } = await editUsers(data, user.user_id);
      if (message === "User Updated successfully") {
        toast.success("User Updated successfully");
        toggleUpdateFlag();
        if (
          user.user_id === jwtDecode(Cookies.get("Price_App_Token")).user_id
        ) {
          updateUser({
            user_name: data.user_name,
            user_position: data.user_position,
          });
        }
        onClose();
      } else {
        toast.error(message);
      }
    } catch (err) {
      const errorMessage =
        err.response && err.response.data.message
          ? err.response.data.message
          : "Error updating Sales Rep password, try again";
      toast.error(errorMessage);
    } finally {
      setEditUserLoading(false);
    }
  };

  return (
    <Box sx={{ width: "100%" }} autoComplete="off">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          boxShadow: "0px 3px 6px #A8A8A81F",
        }}
        autoComplete="off"
        className="p-4 mb-5"
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "24px",
            letterSpacing: "-0.22px",
            color: " rgba(75, 75, 75, 1)",
          }}
        >
          Edit Account
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Formik
        initialValues={initialValues}
        validationSchema={checkoutSchema}
        onSubmit={(values, formikHelpers) =>
          handleFormSubmit(values, formikHelpers)
        }
      >
        {({ getFieldProps, touched, errors }) => {
          const userPositionFieldProps = getFieldProps("user_position");
          return (
            <Form className="px-4 ">
              <div className="flex mb-2">
                <div className="flex  w-50 flex-col mr-4">
                  <label
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                    htmlFor="Full Name"
                    className="mb-2 input-label"
                  >
                    Full Name *
                  </label>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Enter Employee Full Name"
                    type="text"
                    autoComplete="Full Name"
                    required
                    {...getFieldProps("user_name")}
                    error={touched.user_name && Boolean(errors.user_name)}
                    helperText={touched.user_name && errors.user_name}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon
                            style={{ color: "rgba(19, 67, 149, 1)" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    className="mt-1 mb-4 rounded-lg"
                  />
                </div>
                <div className="flex w-50 flex-col">
                  <label
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                    htmlFor="username"
                    className="mb-2 input-label"
                  >
                    User Name
                  </label>
                  <TextField
                    id="outlined-basic1"
                    variant="outlined"
                    placeholder="Enter Employee Id"
                    type="text"
                    autoComplete="User Name"
                    {...getFieldProps("user_username")}
                    error={
                      touched.user_username && Boolean(errors.user_username)
                    }
                    helperText={touched.user_username && errors.user_username}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon
                            style={{ color: "rgba(19, 67, 149, 1)" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    className="mt-1 mb-4 rounded-lg"
                  />
                </div>
              </div>
              <div className="flex mb-2 flex-col">
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                  htmlFor="City"
                  className="mb-2 input-label"
                >
                  City
                </label>
                <TextField
                  id="outlined-basic2"
                  variant="outlined"
                  select
                  autoComplete="City"
                  {...getFieldProps("user_city")}
                  error={touched.user_city && Boolean(errors.user_city)}
                  helperText={touched.user_city && errors.user_city}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationCityIcon
                          style={{ color: "rgba(19, 67, 149, 1)" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1 mb-4 rounded-lg"
                >
                  {cities.sort().map((country, index) => (
                    <MenuItem key={index} value={country["value"]}>
                      {country["name"]}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="flex mb-2 ">
                <div className="flex w-50 flex-col mr-4">
                  <label
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                    htmlFor="role"
                    className="mb-2 input-label"
                  >
                    Role *
                  </label>
                  <TextField
                    id="outlined-basic3"
                    variant="outlined"
                    autoComplete="Role"
                    select
                    required
                    {...userPositionFieldProps}
                    value={mapUserRole(userPositionFieldProps.value)}
                    error={
                      touched.user_position && Boolean(errors.user_position)
                    }
                    helperText={touched.user_position && errors.user_position}
                    className="mt-1 mb-4 rounded-lg"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <BadgeIcon
                            style={{ color: "rgba(19, 67, 149, 1)" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  >
                    {roles.sort().map((role, index) => (
                      <MenuItem key={index} value={role}>
                        {role}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="flex  w-50 flex-col">
                  <label
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                    htmlFor="access"
                    className="mb-2 input-label"
                  >
                    Access Type *
                  </label>
                  <TextField
                    id="outlined-basic4"
                    variant="outlined"
                    select
                    autoComplete="Access Type"
                    required
                    {...getFieldProps("user_access_type")}
                    error={
                      touched.user_access_type &&
                      Boolean(errors.user_access_type)
                    }
                    helperText={
                      touched.user_access_type && errors.user_access_type
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <VpnKeyIcon
                            style={{ color: "rgba(19, 67, 149, 1)" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    className="mt-1 mb-4 rounded-lg"
                  >
                    {access_type.map((country, index) => (
                      <MenuItem key={index} value={country}>
                        {country}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
              <div className="flex mb-2 ">
                <div className="flex w-50 flex-col mr-4">
                  <label
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                    htmlFor="Email"
                    className="mb-2 input-label"
                  >
                    Email *
                  </label>
                  <TextField
                    id="outlined-basic5"
                    variant="outlined"
                    type="email"
                    autoComplete="Email"
                    placeholder="example@example.com"
                    {...getFieldProps("user_email")}
                    error={touched.user_email && Boolean(errors.user_email)}
                    helperText={touched.user_email && errors.user_email}
                    className="mt-1 mb-4 rounded-lg"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MailIcon style={{ color: "rgba(19, 67, 149, 1)" }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="flex  w-50 flex-col">
                  <label
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                    htmlFor="Phone Number"
                    className="mb-2 input-label"
                  >
                    Phone Number *
                  </label>
                  <TextField
                    id="outlined-basic6"
                    variant="outlined"
                    type="text"
                    placeholder="Enter a phone number"
                    autoComplete="Phone Number"
                    required
                    {...getFieldProps("user_phone_no")}
                    error={
                      touched.user_phone_no && Boolean(errors.user_phone_no)
                    }
                    helperText={touched.user_phone_no && errors.user_phone_no}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ContactPhoneIcon
                            style={{ color: "rgba(19, 67, 149, 1)" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    className="mt-1 mb-4 rounded-lg"
                  />
                </div>
              </div>
              <div className="text-end ">
                {" "}
                <CancelButton onClose={onClose} />
                <SubmitButton
                  disabled={editUserLoading}
                  loading={editUserLoading}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}
