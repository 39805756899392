import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import "../../components/styles/menus.css";
import {
  downloadUsersListExcel,
  fetchSalesRep,
} from "../../services/users/usersApis";
import MoreActions from "../../components/Menus/usersmenu";
import CreateAccountForm from "../../components/Drawers/adduserDrawer";
import { useSalesRepContext } from "../../components/context/salesfetch";
import UserProfileDrawer from "../../components/Drawers/UserProfileDrawer";
import AddButton from "../../components/buttons/addButton";
import { mapUserRole } from "../../utils/helperFunctions";
import UserSearchFilters from "../../components/userFilters";
const Team = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showRemovedUsers, setShowRemovedUsers] = useState(false);

  //////////////////Action menus
  const handleMenuClick = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const toggleRemovedUsers = () => setShowRemovedUsers(!showRemovedUsers);
  const open = Boolean(anchorEl);

  ////////////////////////////////

  const handleSearch = (filters) => {
    const filtered = users.filter((user) => {
      return (
        (!filters.user_name ||
          (user.user_name &&
            user.user_name
              .toLowerCase()
              .includes(filters.user_name.toLowerCase()))) &&
        (!filters.user_username ||
          (user.user_username &&
            user.user_username
              .toLowerCase()
              .includes(filters.user_username.toLowerCase()))) &&
        (!filters.user_city ||
          (user.user_city &&
            user.user_city
              .toLowerCase()
              .includes(filters.user_city.toLowerCase()))) &&
        (!filters.user_position ||
          (user.user_position &&
            user.user_position
              .toLowerCase()
              .includes(filters.user_position.toLowerCase()))) &&
        (!filters.user_status ||
          (user.user_status &&
            user.user_status
              .toLowerCase()
              .includes(filters.user_status.toLowerCase())))
      );
    });
    setFilteredUsers(filtered);
  };

  const handleReset = () => {
    setFilteredUsers(users);
  };

  const [isAddUserDrawerOpen, setAddUserDrawerOpen] = useState(false);
  const { updateFlag } = useSalesRepContext();
  const [isProfileDraweOpen, setIsProfileDrawerOpen] = useState(false);
  const [profileId, setProfileId] = useState(null);
  const [downloadExcelLoading, setExcelLoading] = useState(false);

  const toggleAddDrawer = () => {
    setAddUserDrawerOpen(!isAddUserDrawerOpen);
  };

  const toggleProfileDrawer = () => {
    setIsProfileDrawerOpen(!isProfileDraweOpen);
  };

  /////////////////////// Downloading the users excel loading state managment
  const handleDownloadClick = async () => {
    setExcelLoading(true);
    try {
      await downloadUsersListExcel();
    } catch (error) {
      // Handle the error if needed (optional, since the API file already handles errors)
    } finally {
      setExcelLoading(false);
    }
    handleMenuClose();
  };

  useEffect(() => {
    fetchSalesRep(
      setIsLoading,
      setUsers,
      setFilteredUsers,
      showRemovedUsers ? "true" : "false"
    );
  }, [updateFlag, showRemovedUsers]);
  /// Columns for the table showing users blow
  const columns = [
    {
      field: "rowNumber",
      headerName: "#",
      width: 80,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "user_name",
      headerName: "Full Name",
      width: 170,
      renderCell: (params) => (
        <Box
          onClick={() => {
            setProfileId(params.row.user_id);
            toggleProfileDrawer();
          }}
          sx={{
            color: "rgba(58, 67, 68, 1)",
            fontWeight: "600",
            fontSize: "12px",
            lineHeight: "20px",
            letterSpacing: "-0.14px",
            fontFamily: "Poppins",
            padding: "1rem",
            cursor: "pointer",
          }}
        >
          {params.value}
        </Box>
      ),
    },
    {
      field: "user_username",
      headerName: "User Name",
      width: 170,
    },
    {
      field: "user_phone_no",
      headerName: "Phone Number",
      width: 170,
    },
    {
      field: "user_city",
      headerName: "City ",
      width: 170,
    },

    {
      field: "user_position",
      headerName: "Role ",
      width: 200,
      valueGetter: (params) => mapUserRole(params.row.user_position),
    },

    {
      field: "user_status",
      headerName: "Status",
      sortable: false,

      width: 170,
      renderCell: (params) => (
        <>
          {params.value === "Active" ? (
            <Box
              className="px-3 py-2"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "600",
                fontSize: "10px",
                lineHeight: "14px",
                fontFamily: "Poppins",
                letterSpacing: "0.1px",
                color: "#037847",
                background: "#ECFDF3 0% 0% no-repeat padding-box",
                borderRadius: "11px",
                minWidth: "80px",
              }}
            >
              <FiberManualRecordIcon
                sx={{ fontSize: "inherit", marginRight: "5px" }}
              />
              Active
            </Box>
          ) : (
            <Box
              className="px-3 py-2"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "600",
                fontSize: "10px",
                lineHeight: "14px",
                fontFamily: "Poppins",
                letterSpacing: "0.1px",
                color: "#D12E34",
                background: "#FED8D9 0% 0% no-repeat padding-box",
                borderRadius: "11px",
              }}
            >
              <FiberManualRecordIcon
                sx={{ fontSize: "inherit", marginRight: "5px" }}
              />
              Inactive
            </Box>
          )}
        </>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      renderCell: (params) => <MoreActions params={params} />,
    },
  ];

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="container " style={{ height: "95%" }}>
      <Grid className="h-full" item xs={12}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: 40,
            marginBottom: "1rem",
            marginTop: "2rem",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "24px",
              lineHeight: "36px",
              fontWeight: "500",
              paddingLeft: "0.5rem",
              borderLeft: "5px solid  #134394 ",
              height: "100%",
              display: "flex",
              alignItems: "center",
              borderRadius: "2px 0px 0px 2px",
            }}
          >
            User Management
          </Typography>
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <AddButton
              sx={{
                width: "152px", // Set the width to 152px
                height: "40px", // Set the height to 40px
              }}
              onclick={toggleAddDrawer}
              text={"Add a new user"}
            />
            <Button
              className="btn  d-flex align-items-center"
              style={{
                textTransform: "capitalize",
                padding: "7px 16px",
                borderRadius: "30px",
                border: "1px solid rgba(23, 71, 153, 1)",
                fontFamily: "Poppins",
                fontSize: "12px",
                lineHeight: "25px",
                fontWeight: "600",
                color: "rgba(23, 71, 153, 1)", // Text color
                letterSpacing: "-0.18px",
                opacity: "1", // Ensure opacity is 1 so the button is visible
                display: "inline-flex", // Responsive display with flexbox
                alignItems: "center", // Center content vertically
                justifyContent: "center", // Center content horizontally
              }}
              id="actions-button"
              aria-controls={open ? "actions-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleMenuClick}
              endIcon={<ArrowDropDownIcon />}
            >
              Actions
            </Button>
            <Menu
              sx={{
                padding: "0px !important",
              }}
              id="actions-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              MenuListProps={{
                "aria-labelledby": "actions-button",
              }}
            >
              <MenuItem className="rounded mt-1" onClick={handleDownloadClick}>
                <FileDownloadOutlinedIcon
                  sx={{
                    fontSize: "15px",
                    marginRight: "5px",
                    color: "rgba(57, 71, 83, 1)",
                  }}
                />
                <span
                  style={{
                    color: "rgba(57, 71, 83, 1)",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    lineHeight: "18px",
                    paddingTop: "0.1rem",
                  }}
                >
                  {downloadExcelLoading
                    ? "Downloading..."
                    : "Download Excel Sheet"}
                </span>
              </MenuItem>
              <MenuItem
                className="rounded mt-1"
                onClick={() => {
                  toggleRemovedUsers();
                  handleMenuClose();
                }}
              >
                <RestoreOutlinedIcon
                  sx={{
                    fontSize: "15px",
                    marginRight: "5px",
                    color: "rgba(57, 71, 83, 1)",
                  }}
                />
                <span
                  style={{
                    color: "rgba(57, 71, 83, 1)",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    lineHeight: "18px",
                  }}
                >
                  {showRemovedUsers
                    ? "Hide Removed Users"
                    : "Show Removed Users"}
                </span>
              </MenuItem>
            </Menu>

            {/* Add User Drawer */}
            <Drawer
              anchor="right"
              open={isAddUserDrawerOpen}
              onClose={toggleAddDrawer}
              sx={{
                "& .MuiDrawer-paper": { width: "50%", boxSizing: "border-box" },
              }}
            >
              <CreateAccountForm onClose={toggleAddDrawer} />
            </Drawer>
            {/* Profile Drawer */}
            <Drawer
              anchor="right"
              open={isProfileDraweOpen}
              onClose={toggleProfileDrawer}
              sx={{
                "& .MuiDrawer-paper": { width: "50%", boxSizing: "border-box" },
              }}
            >
              <UserProfileDrawer
                userId={profileId}
                onClose={toggleProfileDrawer}
              />
            </Drawer>
          </Box>
        </Box>
        <UserSearchFilters
          onSearch={handleSearch}
          onReset={handleReset}
          data={users}
        />

        <Box
          height="90%"
          sx={{
            "& .MuiDataGrid-row": {
              backgroundColor: "white",
            },
            "& .MuiDataGrid-root": {
              color: "#424242",
              borderRadius: "10px",
            },
            "& .MuiDataGrid-cell": {
              color: "#424242",
              fontSize: "14px",
              borderRight: "1px solid #e0e0e0",
              justifyContent: "center !important",
            },
            "& .MuiDataGrid-cellContent": {
              fontFamily: "Poppins",
              color: "rgba(58, 67, 68, 1)",
              fontWeight: "500",
              fontSize: "12px",
              lineHeight: "20px",
              letterSpacing: "-0.14px",
              padding: "1rem",
              cursor: "pointer",
            },
            "& .MuiDataGrid-columnHeaderTitleContainer": {
              padding: "1rem",
              justifyContent: "center",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontFamily: "Poppins",
              fontSize: "12px",
              fontWeight: "600",
              lineHeight: "18px",
              letterSpacing: "-0.01em",
              textAlign: "left",
              color: "rgba(97, 129, 184, 1)", // Ensure text color is visible against the background
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "rgba(238, 241, 247, 1)", // Background color for headers
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: "#fafafa",
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "1px solid #e0e0e0",
              backgroundColor: "#f5f5f5",
            },
            "& .MuiCheckbox-root": {
              color: `#757575 !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `#424242 !important`,
            },
            "& .MuiDataGrid-footerContainer ": {
              background: "white",
            },
            "& .MuiDataGrid-columnHeader": {
              height: "50px",
              padding: "10px 16px", // Padding for headers
              gap: "10px",
              border: "0px solid",
              borderRight: "1px solid #e0e0e0",
            },
          }}
        >
          <DataGrid
            rows={filteredUsers}
            columns={columns}
            rowHeight={70}
            disableSelectionOnClick
            pageSizeOptions={[8, 16, 32, 64, 100]}
          />
        </Box>
      </Grid>
    </div>
  );
};

export default Team;
