// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-autocomplete-input .MuiAutocomplete-input {
  background-color: transparent !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  box-shadow: 0px 3px 6px #a8a8a833 !important;
  max-height: 40px !important;
  padding: 0rem 0.5rem !important;
  border-radius: 50px !important;
  font-family: "Poppins" !important;
  max-width: 220px !important;
}
.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
  margin-right: 0px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/styles/galleryfilters.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;AAC1C;;AAEA;EACE,4CAA4C;EAC5C,2BAA2B;EAC3B,+BAA+B;EAC/B,8BAA8B;EAC9B,iCAAiC;EACjC,2BAA2B;AAC7B;AACA;EACE,4BAA4B;AAC9B","sourcesContent":[".custom-autocomplete-input .MuiAutocomplete-input {\n  background-color: transparent !important;\n}\n\n.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {\n  box-shadow: 0px 3px 6px #a8a8a833 !important;\n  max-height: 40px !important;\n  padding: 0rem 0.5rem !important;\n  border-radius: 50px !important;\n  font-family: \"Poppins\" !important;\n  max-width: 220px !important;\n}\n.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {\n  margin-right: 0px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
