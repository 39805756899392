// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-link {
  display: flex;
  align-items: center;
  padding: 1rem;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
  text-decoration: none;
}
.nav-link:hover {
  color: white;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
}

.activeLink {
  background: linear-gradient(
    90deg,
    rgba(217, 229, 250, 0.2) 0%,
    rgba(217, 229, 250, 0) 100%
  );
  border-left: 3px solid rgba(217, 229, 250, 1);
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
}

.notActiveLink {
  color: rgba(240, 244, 248, 1);
  font-weight: 400;
}

.nav-link span {
  font-family: "Poppins", sans-serif !important;
  color: rgba(240, 244, 248, 1) !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important
  ;
}
`, "",{"version":3,"sources":["webpack://./src/components/styles/sidebar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,kCAAkC;EAClC,eAAe;EACf,iBAAiB;EACjB,uBAAuB;EACvB,gBAAgB;EAChB,qBAAqB;AACvB;AACA;EACE,YAAY;EACZ,gBAAgB;EAChB,kCAAkC;EAClC,eAAe;EACf,iBAAiB;EACjB,uBAAuB;AACzB;;AAEA;EACE;;;;GAIC;EACD,6CAA6C;EAC7C,6BAA6B;EAC7B,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;EAC7B,gBAAgB;AAClB;;AAEA;EACE,6CAA6C;EAC7C,wCAAwC;EACxC,2BAA2B;EAC3B,0BAA0B;EAC1B;EACA;AACF","sourcesContent":[".nav-link {\n  display: flex;\n  align-items: center;\n  padding: 1rem;\n  font-family: \"Poppins\", sans-serif;\n  font-size: 14px;\n  line-height: 20px;\n  letter-spacing: -0.02em;\n  text-align: left;\n  text-decoration: none;\n}\n.nav-link:hover {\n  color: white;\n  font-weight: 500;\n  font-family: \"Poppins\", sans-serif;\n  font-size: 14px;\n  line-height: 20px;\n  letter-spacing: -0.02em;\n}\n\n.activeLink {\n  background: linear-gradient(\n    90deg,\n    rgba(217, 229, 250, 0.2) 0%,\n    rgba(217, 229, 250, 0) 100%\n  );\n  border-left: 3px solid rgba(217, 229, 250, 1);\n  color: rgba(255, 255, 255, 1);\n  font-weight: 500;\n}\n\n.notActiveLink {\n  color: rgba(240, 244, 248, 1);\n  font-weight: 400;\n}\n\n.nav-link span {\n  font-family: \"Poppins\", sans-serif !important;\n  color: rgba(240, 244, 248, 1) !important;\n  font-weight: 400 !important;\n  font-size: 14px !important;\n  line-height: 20px !important\n  ;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
