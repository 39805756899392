import React, { createContext, useState, useContext } from "react";

const SalesRepContext = createContext();

export const useSalesRepContext = () => useContext(SalesRepContext);

export const SalesRepProvider = ({ children }) => {
  const [updateFlag, setUpdateFlag] = useState(false);

  const toggleUpdateFlag = () => {
    setUpdateFlag(!updateFlag);
  };

  return (
    <SalesRepContext.Provider value={{ updateFlag, toggleUpdateFlag }}>
      {children}
    </SalesRepContext.Provider>
  );
};
