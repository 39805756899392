import {
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { toast } from "react-toastify";
import { AddUser } from "../../services/users/usersApis";
import { roles } from "../../data/data";

const AddUsers = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  // to make the api call here to add user
  const handleFormSubmit = async (values, { resetForm }) => {
    setLoading(true);
    try {
      const { message } = await AddUser(values);
      if (message === "User added successfully") {
        toast.success(message);
        history.push("/team");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ margin: "20px auto", width: "90%", minHeight: "87vh" }}>
      <Header title="CREATE USER" subtitle="Create a New User Profile" />

      <Formik
        onSubmit={(values, formikHelpers) =>
          handleFormSubmit(values, formikHelpers)
        }
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.user_name}
                name="user_name"
                error={!!touched.user_name && !!errors.user_name}
                helperText={touched.user_name && errors.user_name}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.user_email}
                name="user_email"
                error={!!touched.user_email && !!errors.user_email}
                helperText={touched.user_email && errors.user_email}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="password"
                label="Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.user_password}
                name="user_password"
                error={!!touched.user_password && !!errors.user_password}
                helperText={touched.user_password && errors.user_password}
                sx={{ gridColumn: "span 4" }}
                autoComplete="off"
              />

              <FormControl
                fullWidth
                variant="filled"
                sx={{ gridColumn: "span 2" }}
              >
                <InputLabel htmlFor="user_country">Country</InputLabel>
                <Select
                  value={values.user_country}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="user_country"
                  error={!!touched.user_country && !!errors.user_country}
                >
                  <MenuItem value="Trinidad and Tobago">
                    Trinidad and Tobago
                  </MenuItem>
                  <MenuItem value="Suriname">Suriname</MenuItem>
                  <MenuItem value="Guyana">Guyana</MenuItem>{" "}
                  <MenuItem value="Barbados">Barbados</MenuItem>{" "}
                  <MenuItem value="Jamaica">Jamaica</MenuItem>
                  {/* Add more options here */}
                </Select>
                <FormHelperText sx={{ color: "red" }}>
                  {touched.user_country && errors.user_country}
                </FormHelperText>
              </FormControl>
              <FormControl
                fullWidth
                variant="filled"
                sx={{ gridColumn: "span 2" }}
              >
                <InputLabel htmlFor="user_position">Position</InputLabel>
                <Select
                  value={values.user_position}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="user_position"
                >
                  {roles
                    .sort()
                    .filter((position) => position)
                    .map((position, index) => (
                      <MenuItem key={index} value={position}>
                        {position}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              {/* Select for user_department */}
              <FormControl
                fullWidth
                variant="filled"
                sx={{ gridColumn: "span 2" }}
              >
                <InputLabel htmlFor="user_department">Department</InputLabel>
                <Select
                  value={values.user_department}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="user_department"
                >
                  {[
                    "Marketing",
                    "Commercial",
                    "Data Analytics",
                    "Merchandising",
                    "nan",
                    "IT",
                  ]
                    .sort()
                    .filter((department) => department)
                    .map((department, index) => (
                      <MenuItem key={index} value={department}>
                        {department}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              {/* Select for user_subsidiary */}
              <FormControl
                fullWidth
                variant="filled"
                sx={{ gridColumn: "span 2" }}
              >
                <InputLabel htmlFor="user_subsidiary">Subsidiary</InputLabel>
                <Select
                  value={values.user_subsidiary}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="user_subsidiary"
                >
                  {[
                    "SM Jaleel",
                    "Guyana Beverages Inc",
                    "SMJ Suriname NV",
                    "SMJ Jamaica",
                    "SMJ Barbados Ltd",
                  ]
                    .sort()
                    .filter((subsidiary) => subsidiary)
                    .map((subsidiary, index) => (
                      <MenuItem key={index} value={subsidiary}>
                        {subsidiary}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                variant="filled"
                type="tel"
                label="Phone Number"
                onBlur={handleBlur}
                onChange={handleChange}
                error={!!touched.user_phone_no && !!errors.user_phone_no}
                helperText={touched.user_phone_no && errors.user_phone_no}
                value={values.user_phone_no}
                name="user_phone_no"
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                label="User Birthday"
                type="date"
                name="user_date_of_birth"
                value={values.user_date_of_birth}
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ gridColumn: "span 4" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.user_access_dashboard}
                    onChange={handleChange}
                    name="user_access_dashboard"
                  />
                }
                label="Access Dashboard"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.user_access_mobile_app}
                    onChange={handleChange}
                    name="user_access_mobile_app"
                  />
                }
                label="Access Mobile App"
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : "Create New User"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  user_email: yup.string().email("invalid email").required("required"),
  user_name: yup.string().required("Required"),
  user_phone_no: yup.string().matches(phoneRegExp, "Phone number is not valid"),
  user_password: yup
    .string()
    .min(8, "Password is too short - should be 8 chars minimum.")
    .max(20, "Password is too long - should be 20 chars maximum.")
    .matches(/\d/, "Password must contain a number")
    .matches(/[A-Z]/, "Password must contain an uppercase letter")
    .matches(/[a-z]/, "Password must contain a lowercase letter")
    .matches(/[@$!%*?&#]/, "Password must contain a special character")
    .required("Password is required"),
  user_country: yup.string().required("required"),
});
const initialValues = {
  user_phone_no: "",
  user_email: "",
  user_password: "",
  user_name: "",
  user_position: "",
  user_country: "",
  user_department: "",
  user_access_dashboard: false,
  user_access_mobile_app: true,
  user_subsidiary: "",
  user_date_of_birth: "",
};

export default AddUsers;
