import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import * as yup from "yup";
import { Input, Ripple, initTWE } from "tw-elements";
import { Form, Formik } from "formik";
import PersonIcon from "@mui/icons-material/Person";
import { useState } from "react";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import { useRoutesContext } from "../../context/routesfetch";
import { editRoute } from "../../../services/allroutes/apicalls";
import CancelButton from "../../buttons/cancelButton";
import SubmitButton from "../../buttons/submitFormButton";

initTWE({ Input, Ripple });

const checkoutSchema = yup.object().shape({
  route_name: yup
    .string()
    .max(40, "Route Name must be at most 40 characters")
    .required("Route Name is required"),
  route_app_id: yup
    .string()
    .max(40, "Route Id  must be at most 40 characters")
    .required("Route ID  is required"),
});

export default function EditRouteForm({ user, onClose }) {
  const initialValues = {
    route_name: user.route_name || "",
    route_app_id: user.route_app_id || "",
  };
  const { toggleRouteUpdateFlag } = useRoutesContext();

  const [editRouteLoading, setEditRouteLoading] = useState(false);

  const handleFormSubmit = async (values, { resetForm }) => {
    setEditRouteLoading(true);

    try {
      const { message } = await editRoute(values, user.route_id);
      if (message === "Route updated successfully") {
        toast.success("Route updated successfully");
        toggleRouteUpdateFlag();
        onClose();
      } else {
        toast.error(message);
      }
    } catch (err) {
      const errorMessage =
        err.response && err.response.data.message
          ? err.response.data.message
          : "Error updating Route ,please try again";
      toast.error(errorMessage);
    } finally {
      setEditRouteLoading(false);
    }
  };

  return (
    <Box sx={{ width: "100%" }} autoComplete="off">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          boxShadow: "0px 3px 6px #A8A8A81F",
        }}
        autoComplete="off"
        className="p-4 mb-5"
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "24px",
            letterSpacing: "-0.22px",
            color: " rgba(75, 75, 75, 1)",
          }}
        >
          Edit Route
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Formik
        initialValues={initialValues}
        validationSchema={checkoutSchema}
        onSubmit={(values, formikHelpers) =>
          handleFormSubmit(values, formikHelpers)
        }
      >
        {({ getFieldProps, setFieldValue, touched, errors, values }) => (
          <Form className="px-4 ">
            <div className="flex mb-2">
              <div className="flex  w-50 flex-col mr-4">
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                  htmlFor="Route ID"
                  className="mb-2 input-label"
                >
                  Route ID*
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Enter Route ID "
                  type="text"
                  autoComplete="Route ID"
                  required
                  {...getFieldProps("route_app_id")}
                  error={touched.route_app_id && Boolean(errors.route_app_id)}
                  helperText={touched.route_app_id && errors.route_app_id}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        sx={{
                          display: "none",
                        }}
                        position="start"
                      >
                        <PersonIcon style={{ color: "#030391eb" }} />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1 mb-4 rounded-lg"
                />
              </div>
              <div className="flex w-50 flex-col">
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                  htmlFor="routeName"
                  className="mb-2 input-label"
                >
                  Route Name *
                </label>
                <TextField
                  id="outlined-basic1"
                  variant="outlined"
                  placeholder="Enter Route Name"
                  type="text"
                  autoComplete="Route Name"
                  required
                  {...getFieldProps("route_name")}
                  error={touched.route_name && Boolean(errors.route_name)}
                  helperText={touched.route_name && errors.route_name}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        sx={{
                          display: "none",
                        }}
                        position="start"
                      >
                        <PersonIcon style={{ color: "#030391eb" }} />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1 mb-4 rounded-lg"
                />
              </div>
            </div>

            <div className="text-end ">
              {" "}
              <CancelButton onClose={onClose} />
              <SubmitButton
                disabled={editRouteLoading}
                loading={editRouteLoading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

/////////// function to get route stores
//   const [customerwithRouteId, setCustomerswithRouteId] = useState([]);
//   const [customerwithoutRouteId, setCustomerswithoutRouteId] = useState([]);

//   useEffect(() => {
//     RouteStores(
//       user.route_id,
//       setCustomerswithRouteId,
//       setCustomerswithoutRouteId,
//       setLoading
//     );
//   }, []);
/* {customerwithoutRouteId.length > 0 && (
              <div className="flex mb-2 flex-col">
                <label
                  htmlFor="stores_without_route"
                  className="mb-2 input-label"
                >
                  Add Stores
                </label>
                <Autocomplete
                  multiple
                  id="customers-without-route-ids"
                  options={customerwithoutRouteId}
                  getOptionLabel={(option) => option.customer_name}
                  onChange={(event, newValue) => {
                    console.log(newValue);
                    setFieldValue(
                      "addedCustomers",
                      newValue.map((item) => item.customer_id)
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
              </div>
            )}

            <div className="flex mb-2 flex-col">
              <label className="mb-2 input-label">Remove Stores</label>

              <Autocomplete
                multiple
                id="customers-with-route-ids"
                options={customerwithRouteId}
                getOptionLabel={(option) => option.customer_name}
                onChange={(event, newValue) => {
                  setFieldValue(
                    "removedCustomers",
                    newValue.map((item) => item.customer_id)
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
            </div> */
////////////////////////////////////////////////
