import React from "react";
import { Button } from "@mui/material";
import PropTypes from "prop-types";

const SubmitButton = React.memo(({ disabled, loading }) => {
  return (
    <Button
      type="submit"
      sx={{
        borderRadius: "30px ",
        background: "rgba(19, 67, 149, 1)",
        boxShadow: "1px 3px 4px 0px rgba(66, 105, 170, 1) inset", // Inset box-shadow
        opacity: "1",
        fontFamily: "Poppins",
        fontSize: "12px",
        lineHeight: "25px",
        fontWeight: "600",
        color: "#FFFFFF",
        letterSpacing: "-0.18px",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",

        "&:hover": {
          backgroundColor: "rgba(19, 67, 149, 40)",
        },
        textTransform: "capitalize",
      }}
      className="px-5 py-2"
      disabled={disabled}
    >
      {loading ? "Loading..." : "Save"}
    </Button>
  );
});

SubmitButton.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

SubmitButton.defaultProps = {
  disabled: false,
  loading: false,
};

export default SubmitButton;
