// ForgotPasswordForm.js
import React, { useState } from "react";
import { Form, Formik } from "formik";
import { TextField, Button } from "@mui/material";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { requestOtpForForgettenPassword } from "../../services/users/usersApis";
import OtpSentModal from "../emailmodal";
import OtpVerification from "../otpCheckResetPassword";
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Oops! Enter a valid email")
    .required("Oops! Enter an email"),
});

function ForgotPasswordForm({ onBackToLogin }) {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [showOtpVerification, setShowOtpVerification] = useState(false); // New state for showing OTP verification

  const handleForgotPasswordSubmit = async (values) => {
    setLoading(true);
    try {
      const response = await requestOtpForForgettenPassword(values.email);

      if (response.success) {
        setEmail(values.email);
        setShowModal(true);
      } else {
        // Handle failure (e.g., show an error message)
        toast.error("Failed to send OTP:", response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
      // Handle error (e.g., show an error message)
    } finally {
      setLoading(false); // Stop loading
    }
  };
  const handleHideModal = () => {
    setShowModal(false);
    setShowOtpVerification(true);
  };
  const handleResendOtp = async () => {
    await requestOtpForForgettenPassword(email);
  };
  return (
    <>
      {!showOtpVerification ? (
        <Formik
          initialValues={{ email: "" }}
          validationSchema={validationSchema}
          onSubmit={(values) => handleForgotPasswordSubmit(values)}
        >
          {({ getFieldProps, errors, touched, values }) => (
            <Form style={{ width: "100%", maxWidth: "75%" }}>
              <h2
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "700",
                  fontSize: "24px",
                  lingHeight: "34px",
                  color: "rgba(87, 110, 128, 1)",
                }}
                className="mb-1"
              >
                Forgot Password
              </h2>
              <p
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "17px",
                  lineHeight: "28px",
                  color: "rgba(125, 148, 166, 1)",
                }}
              >
                Please enter your registered email address below. You will
                <br />
                receive an OTP via email to reset your password.
              </p>
              <div className="flex flex-col my-3">
                <TextField
                  id="forgetPassword"
                  label="Email Address"
                  variant="outlined"
                  type="email"
                  autoComplete="email"
                  fullWidth
                  {...getFieldProps("email")}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  InputLabelProps={{
                    style: {
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: "16px",
                      color:
                        touched.email && errors.email
                          ? "red"
                          : "rgba(19, 67, 149, 1)",
                    },
                  }}
                  FormHelperTextProps={{
                    style: {
                      color: "red",
                      fontFamily: "Poppins",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor:
                          touched.email && errors.email
                            ? "red"
                            : "rgba(19, 67, 149, 1)",
                      },
                      "&:hover fieldset": {
                        borderColor:
                          touched.email && errors.email
                            ? "red"
                            : "rgba(19, 67, 149, 1)",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor:
                          touched.email && errors.email
                            ? "red"
                            : "rgba(19, 67, 149, 1) !important",
                      },
                    },
                  }}
                />
              </div>
              <div className="text-right mb-4 d-flex align-items-center">
                <p
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    fontWeight: "500",
                    lineHeight: "25px",
                    textAlign: "left",
                    color: "rgba(85, 95, 104, 1)",
                  }}
                >
                  Remembered it?{" "}
                </p>
                <p
                  onClick={onBackToLogin}
                  className="login-button-forgot-password"
                >
                  Login
                </p>
              </div>
              <div className="text-center lg:text-left">
                <button
                  className={`loginButton inline-block  leading-normal w-full transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg ${
                    Object.keys(errors).length === 0 &&
                    Object.values(values).every((val) => val !== "") // Check if all values are not empty
                      ? "noErrorsButton"
                      : "errorsButton"
                  }`}
                  type="submit"
                  disabled={loading}
                >
                  <h5 className="text-md button-txt">
                    {" "}
                    {loading ? "Sending..." : "Send me a link"}
                  </h5>
                </button>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <>
          <OtpVerification
            onLogin={onBackToLogin}
            onResendCode={handleResendOtp}
            email={email}
          />
        </>
      )}

      <OtpSentModal
        show={showModal}
        onHide={handleHideModal}
        onResend={handleResendOtp}
        email={email}
      />
    </>
  );
}

export default ForgotPasswordForm;
