import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import SearchBar from "../../components/galleryFilters";
import { fetchGalleryImagesData } from "../../services/gallery/apicalls";
import Alert from "@mui/material/Alert";
import CustomCard from "../../components/galleryCard";

function GalleryImages() {
  const [visits, setVisits] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1); // Current page
  const pageSize = 8; // Items per page
  const [totalVisits, setTotalVisits] = useState(0);
  const [data, setSearchData] = useState({
    user_country: "",
    user_name: "",
    customer_name: "",
    visit_date: null,
    sku_brand: "",
    sku_full_name: "",
  });

  // Handling Openning Users Profile

  useEffect(() => {
    if (data.user_country) {
      fetchGalleryImagesData(
        setIsLoading,
        setVisits,
        data,
        page,
        pageSize,
        setTotalVisits
      );
    }
  }, [data.user_country, page]);

  const handleSearch = (searchData) => {
    setSearchData((prevData) => ({ ...prevData, ...searchData }));
    setPage(1);
    fetchGalleryImagesData(
      setIsLoading,
      setVisits,
      { ...data, ...searchData },
      1,
      pageSize,
      setTotalVisits
    );
  };
  ///// Pagination Logic
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <div className="container " style={{ height: "95%" }}>
      <Grid className="h-full" item xs={12}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: 45,
            marginBottom: "1rem",
            marginTop: "2rem",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "24px",
              lineHeight: "36px",
              fontWeight: "500",
              paddingLeft: "0.5rem",
              borderLeft: "5px solid  #134394 ",
              height: "100%",
              display: "flex",
              alignItems: "center",
              borderRadius: "2px 0px 0px 2px",
            }}
          >
            Gallery Management
          </Typography>
        </Box>
        <SearchBar onSearch={handleSearch} initialData={data} />
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            {visits.length > 0 ? (
              <>
                {" "}
                <Box
                  sx={{
                    marginTop: "2rem",
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)", // 4 columns
                    gap: "20px",
                  }}
                >
                  {visits.map((visit, index) => (
                    <Box key={index}>
                      <CustomCard
                        isPanorama={visit.is_panorama}
                        images={visit.image_urls}
                        uniqueId={visit.uniqueId}
                        user_name={visit.user_name}
                        customer_name={visit.customer_name}
                        visit_date={visit.visit_date}
                        visit_location={visit.visit_location}
                      />
                    </Box>
                  ))}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "2rem",
                    marginBottom: "2rem",
                  }}
                >
                  <Pagination
                    count={Math.ceil(totalVisits / pageSize)}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                    sx={{
                      marginBottom: "1rem",
                      "& .Mui-selected": {
                        backgroundColor: "rgba(19, 67, 149, 1)", // Set background color for active button
                        color: "#fff", // Ensure text color is white for contrast
                        "&:hover": {
                          backgroundColor: "rgba(19, 67, 149, 0.9)", // Add hover effect
                        },
                      },
                      "& .MuiPaginationItem-root": {
                        fontFamily: "Poppins",
                        fontSize: "13px",
                        fontWeight: 600,
                        display: "flex",
                        justifyContent: "center", // Horizontally center the content
                        alignItems: "center",
                        lineHeight: "19.5px",
                      },
                    }}
                  />
                </Box>
              </>
            ) : (
              <Alert
                sx={{ fontFamily: "Poppins", marginTop: "2rem" }}
                severity="success"
                color="warning"
              >
                No Images Available
              </Alert>
            )}
          </>
        )}
      </Grid>
    </div>
  );
}

export default GalleryImages;
