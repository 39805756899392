import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import { Divider, Drawer, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  changeStatus,
  deleteUsers,
  RestoreUsers,
} from "../../../services/users/usersApis";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import { useSalesRepContext } from "../../context/salesfetch";
import ChangePasswordForm from "../../Drawers/changePasswordDrawer";
import EditUserForm from "../../Drawers/EditUserDrawer";
import EditIcon from "@mui/icons-material/Edit";
import LockResetIcon from "@mui/icons-material/LockReset";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import "../../styles/menus.css";
export default function MoreActions({ params }) {
  const { toggleUpdateFlag } = useSalesRepContext();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  ///////////////////// Restore Users Modal Configuration //////////////////////////////////////////

  const [restoreLoading, setRestoreLoading] = useState(false);

  const [restoreDialogOpen, setRestoreDialogOpen] = useState(false);
  const [selectedUserIdRestore, setSelectedUserIdRestore] = useState(null);
  const handleRestore = (userId) => {
    setSelectedUserIdRestore(userId);
    setRestoreDialogOpen(true);
  };

  const handleCloseResotreModal = () => {
    setSelectedUserIdRestore(null);
    setRestoreDialogOpen(false);
  };

  const RestoreUser = async (userId) => {
    setRestoreLoading(true);
    try {
      const { message } = await RestoreUsers(userId);

      toast.success(message);
      toggleUpdateFlag();

      handleCloseResotreModal();
    } catch (err) {
      console.log(err);
      toast.error("Error Restoring User, try again");
    } finally {
      handleClose();

      setRestoreLoading(false);
    }
  };

  ///////////////////// Delete Modal Configuration //////////////////////////////////////////

  const [deleteloading, setdeleteloading] = useState(false);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedUserIdDelete, setSelectedUserIdDelete] = useState(null);
  const handleDelete = (userId) => {
    setSelectedUserIdDelete(userId);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setSelectedUserIdDelete(null);
    setDeleteDialogOpen(false);
  };

  const deleteUser = async (userId) => {
    setdeleteloading(true);
    try {
      const { message } = await deleteUsers(userId);

      toast.success(message);
      toggleUpdateFlag();

      handleCloseDeleteModal();
    } catch (err) {
      console.log(err);
      toast.error("Error deleting User, try again");
    } finally {
      handleClose();

      setdeleteloading(false);
    }
  };
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////// Activate or deactivate User Modal
  const [statusloading, setStatusloading] = useState(false);

  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [selectedUserIdStatus, setSelectedUserIdStatus] = useState(null);
  const [userCurrentStatus, setUserCurrentStatus] = useState(null);
  const handlestatus = (userId, status) => {
    setUserCurrentStatus(status);
    setSelectedUserIdStatus(userId);
    setStatusDialogOpen(true);
  };

  const handleCloseStatusModal = () => {
    setSelectedUserIdStatus(null);
    setUserCurrentStatus(null);
    setStatusDialogOpen(false);
  };

  const changeStatusForUser = async (userId, status) => {
    setStatusloading(true);
    try {
      const data = {
        user_status: status === "Active" ? "Inactive" : "Active",
      };

      const { message } = await changeStatus(userId, data);

      if (message === "Status changed succesfully") {
        toast.success(message);
        toggleUpdateFlag();
      } else {
        // Handle unexpected messages
        toast.error(message || "Unexpected response from server");
      }
    } catch (err) {
      console.log(err);
      toast.error("Error deleting User, try again");
    } finally {
      handleClose();
      handleCloseStatusModal();
      setStatusloading(false);
    }
  };
  ///////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////// Change Password configurations
  const [isPasswordDrawerOpen, setPasswordDrawerOpen] = useState(false);

  const togglePasswordDrawer = () => {
    setPasswordDrawerOpen(!isPasswordDrawerOpen);
  };

  //////////////////////////// Edit User Configurations////////////////////////
  const [isEditUserDrawerOpen, setEditUserDrawerOpen] = useState(false);

  const toggleEditDrawer = () => {
    setEditUserDrawerOpen(!isEditUserDrawerOpen);
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  const handleMenuItemClick = (action) => {
    handleClose();

    if (action === "Remove") {
      handleDelete(params.row.user_id); // Set selected user ID for deletion
    } else if (action === "Restore") {
      handleRestore(params.row.user_id);
    } else if (action === "Status") {
      handlestatus(params.row.user_id, params.row.user_status);
    } else if (action === "Password") {
      togglePasswordDrawer();
    } else if (action === "Edit") {
      toggleEditDrawer();
    }
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        sx={{
          padding: "0px !important",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => handleMenuItemClick("Edit")}
          className="rounded mt-1"
        >
          <EditIcon
            sx={{
              fontSize: "15px",
              marginRight: "5px",
              color: "rgba(57, 71, 83, 1)",
            }}
          />

          <span
            style={{
              color: "rgba(57, 71, 83, 1)",
              fontFamily: "Poppins",
              fontSize: "12px",
              lineHeight: "18px",
            }}
          >
            Edit User
          </span>
        </MenuItem>
        <Divider />
        <MenuItem
          className="mt-1 rounded"
          onClick={() => handleMenuItemClick("Password")}
        >
          <LockResetIcon
            sx={{
              fontSize: "15px",
              marginRight: "5px",
              color: "rgba(57, 71, 83, 1)",
            }}
          />
          <span
            style={{
              color: "rgba(57, 71, 83, 1)",
              fontFamily: "Poppins",
              fontSize: "12px",
              lineHeight: "18px",
            }}
          >
            Reset Password
          </span>
        </MenuItem>
        {params.row.user_deleted === false && <Divider />}
        {params.row.user_deleted === false && (
          <MenuItem
            className="mt-1 rounded"
            onClick={() => handleMenuItemClick("Status")}
          >
            <DoNotDisturbAltIcon
              sx={{
                fontSize: "14px",
                marginRight: "5px",
                color:
                  params.row.user_status === "Active"
                    ? "rgba(198, 40, 40, 1)"
                    : "#037847",
              }}
            />
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "12px",
                lineHeight: "18px",
                color:
                  params.row.user_status === "Active"
                    ? "rgba(198, 40, 40, 1)"
                    : "#037847",
              }}
            >
              {params.row.user_status === "Active" ? "Deactivate" : "Activate"}
            </span>
          </MenuItem>
        )}

        <Divider />

        {params.row.user_deleted === false ? (
          <MenuItem
            className="mt-1 rounded"
            onClick={() => handleMenuItemClick("Remove")}
          >
            <DeleteForeverOutlinedIcon
              sx={{
                fontSize: "14px",
                marginRight: "5px",
                color: "rgba(198, 40, 40, 1)",
              }}
            />
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "12px",
                lineHeight: "18px",
                color: "rgba(198, 40, 40, 1)",
              }}
            >
              Remove{" "}
            </span>
          </MenuItem>
        ) : (
          <MenuItem
            className="mt-1 rounded"
            onClick={() => handleMenuItemClick("Restore")}
          >
            <RestoreOutlinedIcon
              sx={{
                fontSize: "14px",
                marginRight: "5px",
                color: "#037847",
              }}
            />
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "12px",
                lineHeight: "18px",
                color: "#037847",
              }}
            >
              Restore{" "}
            </span>
          </MenuItem>
        )}
      </Menu>
      <Drawer
        anchor="right"
        open={isPasswordDrawerOpen}
        onClose={togglePasswordDrawer}
        sx={{
          "& .MuiDrawer-paper": { width: "50%", boxSizing: "border-box" },
        }}
      >
        <ChangePasswordForm
          userId={params.row.user_id}
          onClose={togglePasswordDrawer}
        />
      </Drawer>
      <Drawer
        anchor="right"
        open={isEditUserDrawerOpen}
        onClose={toggleEditDrawer}
        sx={{
          "& .MuiDrawer-paper": { width: "50%", boxSizing: "border-box" },
        }}
      >
        <EditUserForm user={params.row} onClose={toggleEditDrawer} />
      </Drawer>

      <Modal show={restoreDialogOpen} onHide={handleCloseResotreModal} centered>
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              fontFamily: "Poppins",
              fontWeight: "600",
              fontSize: "20px",
              lineHeight: "24px",
              letterSpacing: "-0.22px",
              color: "#000000",
              textTransform: "capitalize",
            }}
          >
            Restore User Account{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            padding: "2rem 1rem",
            fontFamily: "Poppins",
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "22px",
            letterSpacing: "-0.2px",
            color: "#000000",
            textTransform: "capitalize",
          }}
        >
          Are you sure you want to restore this Account?
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleCloseResotreModal}
            style={{
              borderRadius: "30px ",
              border: "1px solid rgba(23, 71, 153, 1)",
              opacity: "1",
              fontFamily: "Poppins",
              fontSize: "12px",
              lineHeight: "25px",
              fontWeight: "600",
              color: "rgba(23, 71, 153, 1)",
              letterSpacing: "-0.18px",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              textTransform: "capitalize",
              backgroundColor: "transparent",
            }}
            className="px-5 py-2 mr-3"
            type="close"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              RestoreUser(selectedUserIdRestore);
            }}
            type="submit"
            style={{
              border: "none",
              background: "#03A9F4",

              borderRadius: "30px ",
              opacity: "1",
              fontFamily: "Poppins",
              fontSize: "12px",
              lineHeight: "25px",
              fontWeight: "600",
              color: "#FFFFFF",
            }}
            className="px-5 py-2 "
            disabled={restoreLoading}
          >
            {restoreLoading ? "Loading..." : "Restore"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={deleteDialogOpen} onHide={handleCloseDeleteModal} centered>
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              fontFamily: "Poppins",
              fontWeight: "600",
              fontSize: "20px",
              lineHeight: "24px",
              letterSpacing: "-0.22px",
              color: "#000000",
              textTransform: "capitalize",
            }}
          >
            Remove User Account{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            padding: "2rem 1rem",
            fontFamily: "Poppins",
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "22px",
            letterSpacing: "-0.2px",
            color: "#000000",
            textTransform: "capitalize",
          }}
        >
          Are you sure you want to remove this Account?
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleCloseDeleteModal}
            style={{
              borderRadius: "30px ",
              border: "1px solid rgba(23, 71, 153, 1)",
              opacity: "1",
              fontFamily: "Poppins",
              fontSize: "12px",
              lineHeight: "25px",
              fontWeight: "600",
              color: "rgba(23, 71, 153, 1)",
              letterSpacing: "-0.18px",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              textTransform: "capitalize",
              backgroundColor: "transparent",
            }}
            className="px-5 py-2 mr-3"
            type="close"
          >
            Cancel
          </Button>
          <Button
            style={{
              border: "none",
              background: "rgba(198, 40, 40, 1)",

              borderRadius: "30px ",
              opacity: "1",
              fontFamily: "Poppins",
              fontSize: "12px",
              lineHeight: "25px",
              fontWeight: "600",
              color: "#FFFFFF",
            }}
            className="px-5 py-2 "
            onClick={() => {
              deleteUser(selectedUserIdDelete);
            }}
            type="submit"
            disabled={deleteloading}
          >
            {deleteloading ? "Loading..." : "Remove"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={statusDialogOpen} onHide={handleCloseStatusModal} centered>
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              fontFamily: "Poppins",
              fontWeight: "600",
              fontSize: "20px",
              lineHeight: "24px",
              letterSpacing: "-0.22px",
              color: "#000000",
              textTransform: "capitalize",
            }}
          >
            {params.row.user_status === "Active" ? "Deactivate " : "Activate "}
            User Account
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            padding: "2rem 1rem",
            fontFamily: "Poppins",
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "22px",
            letterSpacing: "-0.2px",
            color: "#000000",
            textTransform: "capitalize",
          }}
        >
          Are you sure you want to{" "}
          {params.row.user_status === "Active" ? "deactivate" : "Activate"} this
          Account?
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleCloseStatusModal}
            style={{
              borderRadius: "30px ",
              border: "1px solid rgba(23, 71, 153, 1)",
              opacity: "1",
              fontFamily: "Poppins",
              fontSize: "12px",
              lineHeight: "25px",
              fontWeight: "600",
              color: "rgba(23, 71, 153, 1)",
              letterSpacing: "-0.18px",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              textTransform: "capitalize",
              backgroundColor: "transparent",
            }}
            className="px-5 py-2 mr-3"
            type="close"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              changeStatusForUser(selectedUserIdStatus, userCurrentStatus);
            }}
            type="submit"
            style={{
              border: "none",
              background:
                params.row.user_status === "Active"
                  ? "rgba(198, 40, 40, 1)"
                  : "#03A9F4",
              borderRadius: "30px ",
              opacity: "1",
              fontFamily: "Poppins",
              fontSize: "12px",
              lineHeight: "25px",
              fontWeight: "600",
              color: "#FFFFFF",
            }}
            className="px-5 py-2 "
            disabled={statusloading}
          >
            {statusloading
              ? "Loading..."
              : `${
                  params.row.user_status === "Active"
                    ? "Deactivate"
                    : "Activate"
                }`}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
