import React from "react";
import { Route, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import { mapUserRole } from "../../utils/helperFunctions";
import { jwtDecode } from "jwt-decode";

const AdminRoute = ({ component: Component, ...rest }) => {
  const token = Cookies.get("Price_App_Token");

  const isAuthenticated = !!token;
  let userRole = null;
  if (isAuthenticated) {
    const { user_position } = jwtDecode(token);
    userRole = mapUserRole(user_position);
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          userRole === "Administrator" ? (
            <Component {...props} />
          ) : (
            <Redirect to="/surveys" />
          )
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default AdminRoute;
