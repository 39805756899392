import React from "react";
import {
  TextField,
  InputAdornment,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Divider,
  IconButton,
  Autocomplete,
} from "@mui/material";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import { skus_sizes } from "../../data/data";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { FieldArray } from "formik";
const renderCaseConfigurations = (fieldArrayProps, errors, touched) => (
  <>
    {fieldArrayProps.form.values[fieldArrayProps.name].map(
      (caseConfig, index) => (
        <div key={index} className="flex mb-2">
          <div className="flex w-50 flex-col mr-4">
            <label
              style={{
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "16px",
              }}
              htmlFor={`units_${index}`}
              className="mb-2 input-label"
            >
              Units *
            </label>
            <TextField
              id={`units_${index}`}
              variant="outlined"
              placeholder="Enter number of units"
              type="number"
              {...fieldArrayProps.form.getFieldProps(
                `${fieldArrayProps.name}[${index}].units`
              )}
              error={
                touched[fieldArrayProps.name] &&
                touched[fieldArrayProps.name][index]?.units &&
                Boolean(errors[fieldArrayProps.name]?.[index]?.units)
              }
              helperText={
                touched[fieldArrayProps.name] &&
                touched[fieldArrayProps.name][index]?.units &&
                errors[fieldArrayProps.name]?.[index]?.units
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment sx={{ display: "none" }} position="start">
                    <LocationCityIcon style={{ color: "#030391eb" }} />
                  </InputAdornment>
                ),
              }}
              className="mt-1 mb-4 rounded-lg"
            />
          </div>
          <div className="flex w-50 flex-col">
            <label
              style={{
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "16px",
              }}
              htmlFor={`price_${index}`}
              className="mb-2 input-label"
            >
              Retail Selling Price (Case) *
            </label>
            <TextField
              id={`price_${index}`}
              variant="outlined"
              placeholder="Enter price"
              type="number"
              {...fieldArrayProps.form.getFieldProps(
                `${fieldArrayProps.name}[${index}].price`
              )}
              error={
                touched[fieldArrayProps.name] &&
                touched[fieldArrayProps.name][index]?.price &&
                Boolean(errors[fieldArrayProps.name]?.[index]?.price)
              }
              helperText={
                touched[fieldArrayProps.name] &&
                touched[fieldArrayProps.name][index]?.price &&
                errors[fieldArrayProps.name]?.[index]?.price
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment sx={{ display: "none" }} position="start">
                    <LocationCityIcon style={{ color: "#030391eb" }} />
                  </InputAdornment>
                ),
              }}
              className="mt-1 mb-4 rounded-lg"
            />
          </div>
          <IconButton
            sx={{
              "&:hover": {
                backgroundColor: "transparent", // Remove background color on hover
              },
              "&:active": {
                backgroundColor: "transparent", // Remove background color on click
              },
              "& svg": {
                "&:hover": {
                  color: "inherit", // Remove color change on hover
                },
                "&:active": {
                  color: "inherit", // Remove color change on click
                },
              },
            }}
            onClick={() => fieldArrayProps.remove(index)}
          >
            <RemoveIcon />
          </IconButton>
        </div>
      )
    )}
    <div className="flex mb-2">
      <IconButton
        onClick={() => fieldArrayProps.push({ units: "", price: "" })}
      >
        <AddIcon />
      </IconButton>
    </div>
  </>
);

const ProductForm = ({
  getFieldProps,
  touched,
  errors,
  setFieldValue,
  brands,
  distributors,
  values,
  categories,
  flavors,
}) => (
  <div className="flex mb-2 flex-col">
    <div className="flex mb-2">
      <div className="flex w-50 flex-col mr-4">
        <label
          style={{
            fontFamily: "Poppins",
            fontWeight: "400",
            fontSize: "16px",
          }}
          htmlFor="sku_brand"
          className="mb-2 input-label"
        >
          Product Brand *
        </label>
        <TextField
          select
          variant="outlined"
          placeholder="Select Product Brand"
          required
          {...getFieldProps("sku_brand")}
          error={touched.sku_brand && Boolean(errors.sku_brand)}
          helperText={touched.sku_brand && errors.sku_brand}
          className="mt-1 mb-4 rounded-lg"
          InputProps={{
            startAdornment: (
              <InputAdornment sx={{ display: "none" }} position="start">
                <LocationCityIcon style={{ color: "#030391eb" }} />
              </InputAdornment>
            ),
          }}
        >
          {brands.length > 0 &&
            brands.map((brand, index) => (
              <MenuItem key={index} value={brand.brand_name}>
                {brand.brand_name}
              </MenuItem>
            ))}
        </TextField>
      </div>
      <div className="flex w-50 flex-col">
        <label
          style={{
            fontFamily: "Poppins",
            fontWeight: "400",
            fontSize: "16px",
          }}
          htmlFor="sku_flavor"
          className="mb-2 input-label"
        >
          Product Flavor *
        </label>
        <Autocomplete
          id="sku_flavor"
          options={flavors} // options passed as props to the component
          freeSolo // Allows users to type in a new value
          getOptionLabel={(option) => option || ""} // Ensures proper labeling for both options and custom input
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Enter Product Flavor"
              autoComplete="off"
              required
              error={touched.sku_flavor && Boolean(errors.sku_flavor)}
              helperText={touched.sku_flavor && errors.sku_flavor}
              className="mt-1 mb-4"
              InputProps={{
                ...params.InputProps,
                style: {
                  borderRadius: "7px", // Custom border-radius
                  minHeight: "56px", // Custom minimum height
                },
                startAdornment: (
                  <InputAdornment sx={{ display: "none" }} position="start">
                    <LocationCityIcon style={{ color: "#030391eb" }} />
                  </InputAdornment>
                ),
              }}
              style={{
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "16px",
                borderRadius: "50px",
                minHeight: "55px",
              }}
            />
          )}
          onChange={(event, newValue) => {
            setFieldValue("sku_flavor", newValue);
          }}
          onInputChange={(event, newInputValue) => {
            setFieldValue("sku_flavor", newInputValue);
          }}
          value={values.sku_flavor || ""}
        />
      </div>
    </div>
    <div className="flex mb-2">
      <div className="flex w-50 flex-col mr-4 ">
        <label
          style={{
            fontFamily: "Poppins",
            fontWeight: "400",
            fontSize: "16px",
          }}
          htmlFor="sku_size"
          className="mb-2 input-label"
        >
          Product Size *
        </label>
        <TextField
          id="outlined-basic"
          variant="outlined"
          placeholder="Choose Product Size "
          type="text"
          select
          autoComplete="Product Size"
          required
          {...getFieldProps("sku_size")}
          error={touched.sku_size && Boolean(errors.sku_size)}
          InputProps={{
            startAdornment: (
              <InputAdornment sx={{ display: "none" }} position="start">
                <LocationCityIcon style={{ color: "#030391eb" }} />
              </InputAdornment>
            ),
          }}
          helperText={touched.sku_size && errors.sku_size}
          className="mt-1 mb-4 rounded-lg"
        >
          {skus_sizes.length > 0 &&
            skus_sizes.map((sku, index) => (
              <MenuItem key={index} value={sku}>
                {sku}
              </MenuItem>
            ))}
        </TextField>
      </div>
      <div className="flex w-50 flex-col ">
        <label
          style={{
            fontFamily: "Poppins",
            fontWeight: "400",
            fontSize: "16px",
          }}
          htmlFor="sku_flavor"
          className="mb-2 input-label"
        >
          Product Category *
        </label>
        <Autocomplete
          id="sku_cateogry"
          options={categories} // options passed as props to the component
          freeSolo // Allows users to type in a new value
          getOptionLabel={(option) => option || ""} // Ensures proper labeling for both options and custom input
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Enter Product Category"
              autoComplete="off"
              required
              error={touched.sku_category && Boolean(errors.sku_category)}
              helperText={touched.sku_category && errors.sku_category}
              className="mt-1 mb-4"
              InputProps={{
                ...params.InputProps,
                style: {
                  borderRadius: "7px", // Custom border-radius
                  minHeight: "56px", // Custom minimum height
                },
                startAdornment: (
                  <InputAdornment sx={{ display: "none" }} position="start">
                    <LocationCityIcon style={{ color: "#030391eb" }} />
                  </InputAdornment>
                ),
              }}
              style={{
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "16px",
              }}
            />
          )}
          onChange={(event, newValue) => {
            setFieldValue("sku_category", newValue);
          }}
          onInputChange={(event, newInputValue) => {
            setFieldValue("sku_category", newInputValue);
          }}
          value={values.sku_category || ""}
        />
      </div>
    </div>
    <div className="flex mb-2">
      <div className="flex w-50 flex-col mr-4">
        <label
          style={{
            fontFamily: "Poppins",
            fontWeight: "400",
            fontSize: "16px",
          }}
          htmlFor="sku_distributor"
          className="mb-2 input-label"
        >
          Product Distributor *
        </label>
        <TextField
          id="outlined-basic"
          variant="outlined"
          placeholder="Choose Product Distributor "
          type="text"
          select
          autoComplete="Product Flavor"
          required
          {...getFieldProps("sku_distributor")}
          error={touched.sku_distributor && Boolean(errors.sku_distributor)}
          InputProps={{
            startAdornment: (
              <InputAdornment sx={{ display: "none" }} position="start">
                <LocationCityIcon style={{ color: "#030391eb" }} />
              </InputAdornment>
            ),
          }}
          helperText={touched.sku_distributor && errors.sku_distributor}
          className="mt-1 mb-4 rounded-lg"
        >
          {distributors.length > 0 &&
            distributors.map((distributor, index) => (
              <MenuItem key={index} value={distributor}>
                {distributor}
              </MenuItem>
            ))}
        </TextField>
      </div>
    </div>
    {["Key A", "Key B", "Chains", "DTT"].map((header, index) => (
      <div key={header} className="mb-3">
        <Divider className="mb-2" />

        <div className="flex ">
          <div className="flex w-50 flex-col mr-4">
            <label
              style={{
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "16px",
              }}
              htmlFor={`${header.toLowerCase().replace(/ /g, "_")}_price`}
              className="mb-2 input-label"
            >
              {header} SMJ Selling Price
            </label>
            <TextField
              id={`${header.toLowerCase().replace(/ /g, "_")}_price`}
              variant="outlined"
              placeholder={`Enter ${header} Price`}
              type="number"
              autoComplete={`${header} Price`}
              {...getFieldProps(
                `${header.toLowerCase().replace(/ /g, "_")}_price`
              )}
              error={
                touched[`${header.toLowerCase().replace(/ /g, "_")}_price`] &&
                Boolean(
                  errors[`${header.toLowerCase().replace(/ /g, "_")}_price`]
                )
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment sx={{ display: "none" }} position="start">
                    <LocationCityIcon style={{ color: "#030391eb" }} />
                  </InputAdornment>
                ),
              }}
              helperText={
                touched[`${header.toLowerCase().replace(/ /g, "_")}_price`] &&
                errors[`${header.toLowerCase().replace(/ /g, "_")}_price`]
              }
              className="mt-1 mb-4 rounded-lg"
            />
          </div>
          <div className="flex w-50 flex-col">
            <label
              style={{
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "16px",
              }}
              htmlFor={`${header
                .toLowerCase()
                .replace(/ /g, "_")}_selling_price`}
              className="mb-2 input-label"
            >
              {header} Retail Selling Price (Unit)
            </label>
            <TextField
              id={`${header.toLowerCase().replace(/ /g, "_")}_selling_price`}
              variant="outlined"
              placeholder={`Enter ${header} Selling Price`}
              type="number"
              autoComplete={`${header} Selling Price`}
              {...getFieldProps(
                `${header.toLowerCase().replace(/ /g, "_")}_selling_price`
              )}
              error={
                touched[
                  `${header.toLowerCase().replace(/ /g, "_")}_selling_price`
                ] &&
                Boolean(
                  errors[
                    `${header.toLowerCase().replace(/ /g, "_")}_selling_price`
                  ]
                )
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment sx={{ display: "none" }} position="start">
                    <LocationCityIcon style={{ color: "#030391eb" }} />
                  </InputAdornment>
                ),
              }}
              helperText={
                touched[
                  `${header.toLowerCase().replace(/ /g, "_")}_selling_price`
                ] &&
                errors[
                  `${header.toLowerCase().replace(/ /g, "_")}_selling_price`
                ]
              }
              className="mt-1 mb-4 rounded-lg"
            />
          </div>
        </div>
        <div className="flex w-100 flex-row align-items-center">
          <label
            style={{
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "16px",
            }}
            htmlFor={`${header.toLowerCase().replace(/ /g, "_")}_vat`}
            className=" input-label"
          >
            {header} VAT
          </label>
          <FormControlLabel
            control={
              <Checkbox
                id={`${header.toLowerCase().replace(/ /g, "_")}_vat`}
                name={`${header.toLowerCase().replace(/ /g, "_")}_vat`}
                checked={
                  values[`${header.toLowerCase().replace(/ /g, "_")}_vat`]
                }
                inputProps={{
                  title: "VAT is 12.5%",
                }}
                onChange={(event) =>
                  setFieldValue(
                    `${header.toLowerCase().replace(/ /g, "_")}_vat`,
                    event.target.checked
                  )
                }
              />
            }
            label={
              values[`${header.toLowerCase().replace(/ /g, "_")}_vat`]
                ? "VAT is 12.5%"
                : ""
            }
            style={{ fontSize: "0.875rem" }} // Adjust the font size as needed
            className=" mx-1 mb-2 rounded-lg text-muted"
          />
        </div>
        <div className="flex flex-col mt-3">
          <h3
            style={{
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "16px",
            }}
            className="mb-2 input-label"
          >
            {header} Case Configurations
          </h3>
          <FieldArray
            name={`${header.toLowerCase().replace(/ /g, "_")}_cases`}
            render={(fieldArrayProps) =>
              renderCaseConfigurations(fieldArrayProps, errors, touched)
            }
          />
        </div>
      </div>
    ))}
    <div className="flex mb-2">
      <div className="flex w-100 flex-col">
        <label
          style={{
            fontFamily: "Poppins",
            fontWeight: "400",
            fontSize: "16px",
          }}
          htmlFor="sku_image"
          className="mb-2 input-label"
        >
          Product Image *
        </label>
        <input
          id="sku_image"
          name="sku_image"
          type="file"
          accept="image/*"
          style={{
            paddingBottom: "2.5rem",
            paddingTop: "0.7rem",
            fontFamily: "Poppins",
          }}
          onChange={(event) => {
            const file = event.target.files[0];
            const maxSize = 1024 * 1024;
            if (file && file.size > maxSize) {
              toast.error(
                "Product Image is too large. Please upload a logo smaller than 1MB."
              );
              event.target.value = null;
            } else {
              setFieldValue("sku_image", event.target.files[0]);
            }
          }}
          className="form-control"
        />
        {touched.sku_image && errors.sku_image && (
          <div className="text-danger">{errors.sku_image}</div>
        )}
        <div
          style={{
            fontFamily: "Poppins",
            fontWeight: "400",
            fontSize: "12px",
          }}
          className="my-2"
        >
          Max Image size: 1MB
        </div>
      </div>
    </div>
  </div>
);

export default ProductForm;
