import React, { createContext, useState, useContext } from "react";

const BrandsContext = createContext();

export const useBrandsContext = () => useContext(BrandsContext);

export const BrandsProvider = ({ children }) => {
  const [updateBrandsFlag, setUpdateBrandsFlag] = useState(false);

  const toggleBrandsUpdateFlag = () => {
    setUpdateBrandsFlag(!updateBrandsFlag);
  };

  return (
    <BrandsContext.Provider
      value={{ updateBrandsFlag, toggleBrandsUpdateFlag }}
    >
      {children}
    </BrandsContext.Provider>
  );
};
