import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { profileDataApiCall } from "../../../services/users/usersApis";
import determineAccessType from "../../../utils/helperFunctions";
export default function UserProfileDrawer({ userId, onClose }) {
  const [userData, setUserData] = useState(null); // State to store user data
  const [dataLoading, setDataLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      const data = await profileDataApiCall(userId, setDataLoading);
      setUserData(data); // Store the data in state
    };

    fetchData();
  }, [userId]); // Dependency array includes userId to refetch if userId changes
  if (dataLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <Box sx={{ width: "100%" }} autoComplete="off">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          boxShadow: "0px 3px 6px #A8A8A81F",
        }}
        autoComplete="off"
        className="p-4 mb-3"
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "30px",
            letterSpacing: "-0.22px",
            color: " #000000",
          }}
        >
          User Details
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          padding: 4,
          backgroundColor: "#fff",
          margin: 2,
        }}
      >
        <Grid container spacing={2}>
          {/* Left column */}
          <Grid
            item
            xs={6}
            sx={{ borderRight: 1, borderColor: "divider", paddingRight: 2 }}
          >
            <Grid container>
              {[
                { label: "Username", value: userData.user_username },
                { label: "Full Name", value: userData.user_name },
                { label: "Phone Number", value: userData.user_phone_no },
                { label: "City", value: userData.user_city },
              ].map((item, index) => (
                <Grid
                  item
                  xs={12}
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: 1,
                    borderColor: "divider",
                    py: 1,
                    minHeight: "50px",
                  }}
                >
                  <Typography
                    style={{
                      font: "normal normal 500 18px/22px Poppins",
                      letterSpacing: " -0.14px",
                      color: "#000000",
                      textTransform: " capitalize",
                    }}
                  >
                    {item.label}
                  </Typography>
                  <Typography
                    style={{
                      font: "normal normal 400 16px/20px Poppins",
                      letterSpacing: " -0.14px",
                      color: item.label === "Username" ? "#03A9F4" : "#000000",
                      textTransform: " capitalize",
                    }}
                  >
                    {item.value}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>

          {/* Right column */}
          <Grid item xs={6}>
            <Grid container>
              {[
                {
                  label: "Status",
                  value:
                    userData.user_status === "Active" ? (
                      <Box
                        className="px-3 py-2"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          fontWeight: "500",
                          fontSize: "10px",
                          lineHeight: "14px",
                          fontFamily: "Poppins",
                          letterSpacing: "0.1px",
                          color: "#037847",
                          textTransform: "capitalize",
                          background: "#ECFDF3 0% 0% no-repeat padding-box",
                          borderRadius: "11px",
                        }}
                      >
                        <FiberManualRecordIcon
                          sx={{ fontSize: "inherit", marginRight: "5px" }}
                        />
                        Active
                      </Box>
                    ) : (
                      <Box
                        className="px-3 py-2"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          fontWeight: "500",
                          fontSize: "10px",
                          lineHeight: "14px",
                          fontFamily: "Poppins",
                          letterSpacing: "0.1px",
                          color: "#D12E34",
                          textTransform: "capitalize",
                          background: "#FED8D9 0% 0% no-repeat padding-box",
                          borderRadius: "11px",
                        }}
                      >
                        <FiberManualRecordIcon
                          sx={{ fontSize: "inherit", marginRight: "5px" }}
                        />
                        Inactive
                      </Box>
                    ),
                },
                { label: "Department", value: userData.department || "N/A" },
                { label: "Position", value: userData.user_position },
                {
                  label: "Access",
                  value: determineAccessType(
                    userData.user_access_dashboard,
                    userData.user_access_mobile_app
                  ),
                },
              ].map((item, index) => (
                <Grid
                  item
                  xs={12}
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: 1,
                    borderColor: "divider",
                    py: 1,
                    minHeight: "50px",
                  }}
                >
                  <Typography
                    style={{
                      font: "normal normal 500 18px/22px Poppins",
                      letterSpacing: " -0.14px",
                      color: "#000000",
                      textTransform: " capitalize",
                    }}
                  >
                    {item.label}
                  </Typography>
                  <Typography
                    style={{
                      font: "normal normal 400 16px/20px Poppins",
                      letterSpacing: " -0.14px",
                      color: "#000000",
                      textTransform: " capitalize",
                    }}
                  >
                    {item.value}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>

          {/* Routes - Full width  - commented as there is no user assigned routes*/}
          {/* <Grid
            item
            xs={12}
            sx={{ pt: 2, borderBottom: 1, borderColor: "divider" }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 1,
                mt: 2,
                justifyContent: "space-between",
              }}
            >
              <Typography
                style={{
                  font: "normal normal bold 18px/22px Poppins",
                  letterSpacing: "-0.14px",
                  color: "#000000",
                  textTransform: "capitalize",
                  marginRight: "1rem",
                }}
              >
                Routes
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {userData.route_names.map((route, index) => (
                  <Chip
                    key={index}
                    label={route}
                    variant="outlined"
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      fontSize: "12px",
                      lineHeight: "17px",
                      letterSpacing: "-0.12px",
                      textTransform: "capitalize",
                      backgroundColor:
                        index % 4 === 0 || index % 4 === 3
                          ? "#13439433"
                          : "#03A9F433",
                      mb: 0.5,
                      border: "none",
                      padding: "0.5rem 0.5rem",
                    }}
                  />
                ))}
              </Box>
            </Box>
          </Grid> */}
        </Grid>
      </Box>
    </Box>
  );
}
