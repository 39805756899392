import React from "react";
import { Button } from "@mui/material";

const CancelButton = ({ onClose }) => {
  return (
    <Button
      onClick={onClose}
      sx={{
        borderRadius: "30px ",
        border: "1px solid rgba(23, 71, 153, 1)",
        opacity: "1",
        fontFamily: "Poppins",
        fontSize: "12px",
        lineHeight: "25px",
        fontWeight: "600",
        color: "rgba(23, 71, 153, 1)",
        letterSpacing: "-0.18px",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",

        textTransform: "capitalize",
      }}
      className="px-5 py-2 mr-3"
      type="button"
    >
      Cancel
    </Button>
  );
};
export default CancelButton;
