import { Box, Typography, Tabs, Tab, Button } from "@mui/material";

import * as yup from "yup";
import { Input, Ripple, initTWE } from "tw-elements";
import CloseIcon from "@mui/icons-material/Close";
import "../../styles/loginpage.css";
import { Form, Formik } from "formik";
import IconButton from "@mui/material/IconButton";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";

import { useState } from "react";
import { toast } from "react-toastify";
import { useBrandsContext } from "../../context/brandsfetch";
import { AddBrand, AddSku } from "../../../services/allbrands/apicalls";
import ProductForm from "../../forms/addProduct";
import BrandForm from "../../forms/addBrand";
import CancelButton from "../../buttons/cancelButton";
import SubmitButton from "../../buttons/submitFormButton";

initTWE({ Input, Ripple });
const initialValuesProduct = {
  sku_full_name: "",
  sku_brand: "",
  sku_image: null,
  sku_category: "",
  sku_flavor: "",
  sku_distributor: "",
  sku_size: "",
  dtt_price: "",
  dtt_selling_price: "",
  dtt_vat: false,
  chains_price: "",
  chains_selling_price: "",
  chains_vat: false,
  key_a_price: "",
  key_a_selling_price: "",
  key_a_vat: false,
  key_b_price: "",
  key_b_selling_price: "",
  key_b_vat: false,
  dtt_cases: [],
  key_a_cases: [],
  key_b_cases: [],
  chains_cases: [],
};

const caseSchema = yup.object().shape({
  units: yup.number().required("Units are required"),
  price: yup.number().required("Price is required"),
});
const initialValuesBrand = {
  brand_name: "",
  brand_image: null,
};

const productSchema = yup.object().shape({
  sku_full_name: yup
    .string()
    .max(40, "Product Name must be at most 40 characters")
    .required("Product Full Name is required"),
  sku_brand: yup.string().required("Product Brand is required"),
  sku_category: yup
    .string()
    .max(40, "Product Category  must be at most 40 characters")
    .required("Product Ctategory is required"),
  sku_flavor: yup
    .string()
    .max(20, "Product Flavor  must be at most 20 characters")
    .required("Product Flavor is required"),
  sku_image: yup.mixed().required("Product Image is required"),
  sku_distributor: yup.string().required("Product Distributor is required"),
  sku_size: yup.string().required("Product Size is required"),
  dtt_price: yup.number().nullable(),
  dtt_selling_price: yup.number().nullable(),

  dtt_vat: yup.boolean(),
  chains_price: yup.number().nullable(),
  chains_selling_price: yup.number().nullable(),

  chains_vat: yup.boolean(),
  key_a_price: yup.number().nullable(),
  key_a_selling_price: yup.number().nullable(),

  key_a_vat: yup.boolean(),
  key_b_price: yup.number().nullable(),
  key_b_selling_price: yup.number().nullable(),

  key_b_vat: yup.boolean(),
  dtt_cases: yup.array().of(caseSchema),
  key_a_cases: yup.array().of(caseSchema),
  key_b_cases: yup.array().of(caseSchema),
  chains_cases: yup.array().of(caseSchema),
});

const brandSchema = yup.object().shape({
  brand_name: yup
    .string()
    .max(40, "Brand Name must be at most 40 characters")
    .required("Brand Name is required"),
  brand_image: yup.mixed().required("Brand Image is required"),
});

export default function CreateProductForm({ onClose, brands, distributors }) {
  const { toggleBrandsUpdateFlag } = useBrandsContext();
  const [activeTab, setActiveTab] = useState(0);
  const [addingProductLoading, setAddingProductLoading] = useState(false);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const handleFormSubmit = async (values, { resetForm }) => {
    setAddingProductLoading(true);
    const country = jwtDecode(Cookies.get("Price_App_Token")).user_country;
    const formData = new FormData();
    if (activeTab === 0) {
      formData.append("sku_full_name", values.sku_full_name);
      formData.append("sku_brand", values.sku_brand);
      formData.append("sku_flavor", values.sku_flavor);
      formData.append("sku_distributor", values.sku_distributor);
      formData.append("sku_image", values.sku_image);
      formData.append("sku_category", values.sku_category);
      formData.append("country", country);
      formData.append("sku_size", values.sku_size);
      formData.append("dtt_price", values.dtt_price);
      formData.append("dtt_selling_price", values.dtt_selling_price);

      formData.append("dtt_vat", values.dtt_vat);
      formData.append("chains_price", values.chains_price);
      formData.append("chains_selling_price", values.chains_selling_price);

      formData.append("chains_vat", values.chains_vat);
      formData.append("key_a_price", values.key_a_price);
      formData.append("key_a_selling_price", values.key_a_selling_price);

      formData.append("key_a_vat", values.key_a_vat);
      formData.append("key_b_price", values.key_b_price);
      formData.append("key_b_selling_price", values.key_b_selling_price);

      formData.append("key_b_vat", values.key_b_vat);
      ["dtt_cases", "key_a_cases", "key_b_cases", "chains_cases"].forEach(
        (category) => {
          if (values[category].length > 0) {
            formData.append(category, JSON.stringify(values[category]));
          } else {
            formData.append(category, JSON.stringify([]));
          }
        }
      );
      try {
        const { message } = await AddSku(formData);
        if (message === "Product added successfully") {
          toast.success(message);
          toggleBrandsUpdateFlag();
          onClose();
        } else {
          toast.error(message);
        }
      } catch (e) {
        toast.error("Error adding product, please try again");
      } finally {
        setAddingProductLoading(false);
      }
    } else {
      formData.append("brand_name", values.brand_name);
      formData.append("brand_image", values.brand_image);
      try {
        const { message } = await AddBrand(formData);
        if (message === "Brand added successfully") {
          toast.success(message);
          toggleBrandsUpdateFlag();
          onClose();
        } else {
          toast.error(message);
        }
      } catch (e) {
        toast.error("Error adding product, please try again");
      } finally {
        setAddingProductLoading(false);
      }
    }
  };

  return (
    <Box sx={{ width: "100%" }} autoComplete="off">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          boxShadow: "0px 3px 6px #A8A8A81F",
        }}
        autoComplete="off"
        className="p-4 "
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "24px",
            letterSpacing: "-0.22px",
            color: " rgba(75, 75, 75, 1)",
          }}
        >
          Add New
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Tabs className="mb-5" value={activeTab} onChange={handleTabChange}>
        <Tab label="Add Product" />
        <Tab label="Add Brand" />
      </Tabs>

      <Formik
        initialValues={
          activeTab === 0 ? initialValuesProduct : initialValuesBrand
        }
        validationSchema={activeTab === 0 ? productSchema : brandSchema}
        onSubmit={(values, formikHelpers) => {
          handleFormSubmit(values, formikHelpers);
        }}
      >
        {({ getFieldProps, touched, setFieldValue, errors, values }) => (
          <Form className="px-4 ">
            {activeTab === 0 ? (
              <ProductForm
                getFieldProps={getFieldProps}
                touched={touched}
                setFieldValue={setFieldValue}
                errors={errors}
                brands={brands}
                distributors={distributors}
                values={values}
              />
            ) : (
              <BrandForm
                getFieldProps={getFieldProps}
                touched={touched}
                setFieldValue={setFieldValue}
                errors={errors}
              />
            )}
            <div className="text-end my-3">
              <CancelButton onClose={onClose} />
              <SubmitButton
                disabled={addingProductLoading}
                loading={addingProductLoading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
