import React from "react";
import AddIcon from "@mui/icons-material/Add";

const AddButton = ({ onclick, text }) => {
  return (
    <button
      type="button"
      onClick={onclick}
      className="btn d-flex align-items-center"
      style={{
        padding: "7px 16px",
        gap: "6px",
        borderRadius: "30px ",
        background: "rgba(19, 67, 149, 1)",
        boxShadow: "1px 3px 4px 0px rgba(66, 105, 170, 1) inset", // Inset box-shadow
        opacity: "1",
        fontFamily: "Poppins",
        fontSize: "12px",
        lineHeight: "25px",
        fontWeight: "600",
        color: "#FFFFFF",
        letterSpacing: "-0.18px",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <AddIcon sx={{ marginRight: "3px", fontSize: "25px" }} />
      {text}
    </button>
  );
};
export default AddButton;
