import React, { createContext, useState, useContext } from "react";

const StoreContext = createContext();

export const useStoresContext = () => useContext(StoreContext);

export const StoresProvider = ({ children }) => {
  const [updateStoresFlag, setUpdateRStoresFlag] = useState(false);

  const toggleStoresUpdateFlag = () => {
    setUpdateRStoresFlag(!updateStoresFlag);
  };

  return (
    <StoreContext.Provider value={{ updateStoresFlag, toggleStoresUpdateFlag }}>
      {children}
    </StoreContext.Provider>
  );
};
