export default function determineAccessType(dashboardAccess, mobileAppAccess) {
  if (dashboardAccess && !mobileAppAccess) {
    return "Dashboard";
  } else if (dashboardAccess && mobileAppAccess) {
    return "Both of them";
  } else {
    return "Mobile Application";
  }
}

export const mapUserRole = (role) => {
  const rolesMapping = {
    Merchandiser: "Merchandiser",
    "Sales Supervisor": "Sales Supervisor",
    "Sales Representative": "Sales Representative",
    "Merchandiser Supervisor": "Merchandiser Supervisor",
    "Data Analytics": "Administrator",
    "IT / Data Analytics": "Administrator",
    Administrator: "Administrator",
  };

  return rolesMapping[role] || "Manager";
};
