import React, { useCallback, useMemo, useState } from "react";
import {
  TextField,
  Grid,
  InputAdornment,
  Autocomplete,
  MenuItem,
  Menu,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import RouteIcon from "@mui/icons-material/Route";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import AddIcon from "@mui/icons-material/Add";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";

import "../styles/galleryfilters.css";

const CustomerSearchFilters = ({ onSearch, onReset, data }) => {
  const [searchFilters, setSearchFilters] = useState({
    customer_raw_id: "",
    customer_name: "",
    route_name: "",
    route_app_id: "",
    customer_division: "",
    customer_channel: "",
    customer_subchannel: "",
    customer_status: "",
  });

  /////////// filters viewed configuration
  const [filterVisibility, setFilterVisibility] = useState({
    customer_raw_id: true,
    customer_name: true,
    route_name: true,
    route_app_id: true,
    customer_division: false,
    customer_channel: false,
    customer_subchannel: false,
    customer_status: false,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  /////////// functions to be triggered when the add button is clicked
  const handleAddClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  //// hiding and showing the filters
  const handleFilterToggle = (filter) => {
    setFilterVisibility((prevVisibility) => {
      const isVisible = prevVisibility[filter];
      return {
        ...prevVisibility,
        [filter]: !isVisible,
      };
    });

    // Set value to empty string if filter is being removed
    if (filterVisibility[filter]) {
      setSearchFilters((prevValues) => ({
        ...prevValues,
        [filter]: "",
      }));
    }
  };

  ///////////////////////////////////////
  const handleAutocompleteChange = useCallback(
    (event, newValue, reason, field) => {
      if (reason === "input") {
        setSearchFilters((prevFilters) => ({
          ...prevFilters,
          [field]: event.target.value,
        }));
      } else if (reason === "reset" && newValue) {
        setSearchFilters((prevFilters) => ({
          ...prevFilters,
          [field]: newValue,
        }));
      }
    },
    []
  );

  const fieldLabels = {
    customer_raw_id: "Store Id",
    customer_name: "Store Name",
    route_name: "Route Name",
    route_app_id: "Route Id",
    customer_division: "Store Division",
    customer_channel: "Store Channel",
    customer_subchannel: "Store Sub Channel",
    customer_status: "Status",
  };

  const options = useMemo(() => {
    const fieldOptions = {
      customer_raw_id: new Set(),
      customer_name: new Set(),
      route_name: new Set(),
      route_app_id: new Set(),
      customer_division: new Set(),
      customer_channel: new Set(),
      customer_subchannel: new Set(),

      customer_status: ["Active", "Inactive"],
    };

    data.forEach((item) => {
      if (item.customer_raw_id)
        fieldOptions.customer_raw_id.add(item.customer_raw_id);
      if (item.customer_name)
        fieldOptions.customer_name.add(item.customer_name);
      if (item.route_name) fieldOptions.route_name.add(item.route_name);
      if (item.route_app_id) fieldOptions.route_app_id.add(item.route_app_id);

      if (item.customer_division) {
        fieldOptions.customer_division.add(item.customer_division);
      }
      if (item.customer_channel) {
        fieldOptions.customer_channel.add(item.customer_channel);
      }
      if (item.customer_subchannel) {
        fieldOptions.customer_subchannel.add(item.customer_subchannel);
      }
    });

    return {
      customer_raw_id: Array.from(fieldOptions.customer_raw_id),
      customer_name: Array.from(fieldOptions.customer_name),
      route_name: Array.from(fieldOptions.route_name),
      route_app_id: Array.from(fieldOptions.route_app_id),
      customer_division: Array.from(fieldOptions.customer_division),
      customer_channel: Array.from(fieldOptions.customer_channel),
      customer_subchannel: Array.from(fieldOptions.customer_subchannel),
      customer_status: fieldOptions.customer_status,
    };
  }, [data]); // Dependency on data
  const handleSearch = useCallback(() => {
    onSearch(searchFilters);
  }, [searchFilters, onSearch]);

  const handleReset = useCallback(() => {
    setSearchFilters({
      customer_raw_id: "",
      customer_name: "",
      route_name: "",
      route_app_id: "",
      customer_division: "",
      customer_channel: "",
      customer_subchannel: "",
      customer_status: "",
    });
    onReset();
  }, [onReset]);
  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        style={{ marginBottom: "1rem" }}
      >
        <Grid item>
          <h6
            style={{
              margin: 0,
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "42px",
            }}
          >
            Search by
          </h6>
        </Grid>
        <Grid item xs={12} sm="auto">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              width: "100%",
            }}
          >
            <button
              type="button"
              onClick={handleSearch}
              className="btn py-3 px-5 d-flex align-items-center justify-content-center"
              style={{
                background: "rgba(19, 67, 149, 1)",
                borderRadius: "30px",
                fontFamily: "Poppins",
                fontSize: "12px",
                lineHeight: "25px",
                fontWeight: "600",
                color: "#FFFFFF",
                letterSpacing: "-0.16px",
                height: "40px",
                width: "66.67%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                maxWidth: "140px",
              }}
            >
              Search
            </button>
            <button
              type="button"
              onClick={handleReset}
              className="btn py-3 px-5 d-flex align-items-center"
              style={{
                background: "transparent",
                border: "1px solid rgba(19, 67, 149, 1)",
                borderRadius: "50px",
                fontFamily: "Poppins",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: "500",
                color: "rgba(19, 67, 149, 1)",
                letterSpacing: "-0.16px",
                height: "40px",
                width: "33.33%", // Takes 1/3 of the width
                textAlign: "center", // Center text alignment
                display: "flex", // Flex to center the content
                justifyContent: "center", // Horizontally center the text
                alignItems: "center", // Vertically center the text
                maxWidth: "90px",
              }}
            >
              Reset
            </button>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        sx={{
          width: "100%",
          marginBottom: "1rem",
          flexWrap: { xs: "wrap" },
          gap: "1rem",
        }}
      >
        {Object.keys(searchFilters)
          .filter((key) => filterVisibility[key])
          .map((key) => (
            <Grid item xs={12} sm={2.4} key={key}>
              <Autocomplete
                fullWidth
                disableClearable
                freeSolo
                value={searchFilters[key]}
                isOptionEqualToValue={(option, value) =>
                  option === value || value === "" || !option
                }
                onInputChange={(event, newValue, reason) =>
                  handleAutocompleteChange(event, newValue, reason, key)
                }
                placeholder={`${fieldLabels[key]}`}
                options={options[key] || []}
                getOptionLabel={(option) => option || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name={key}
                    placeholder={`${fieldLabels[key]}`}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          {key === "customer_name" && (
                            <StoreMallDirectoryIcon />
                          )}
                          {key === "customer_raw_id" && (
                            <StoreMallDirectoryIcon />
                          )}
                          {key === "route_name" && <RouteIcon />}
                          {key === "route_app_id" && <RouteIcon />}

                          {key === "customer_division" && (
                            <StoreMallDirectoryIcon />
                          )}
                          {key === "customer_channel" && (
                            <StoreMallDirectoryIcon />
                          )}
                          {key === "customer_subchannel" && (
                            <StoreMallDirectoryIcon />
                          )}

                          {key === "customer_status" && <ToggleOnIcon />}
                        </InputAdornment>
                      ),
                      style: {
                        height: "40px",
                        padding: "5px 16px",
                        maxWidth: "250px",
                        boxShadow: "0px 3px 6px #a8a8a833",
                        borderRadius: "50px",
                      },
                    }}
                    className="custom-autocomplete-input"
                  />
                )}
              />
            </Grid>
          ))}
        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "flex-end" },
            marginLeft: { sm: "auto" }, // Align the button to the right on larger screens
          }}
          xs={12}
          sm="auto"
        >
          <button
            style={{
              background: "transparent",
              border: "1px solid rgba(19, 67, 149, 1)",
              borderRadius: "50px",
              fontFamily: "Poppins",
              fontSize: "12px",
              lineHeight: "16px",
              fontWeight: "500",
              color: "rgba(19, 67, 149, 1)",
              letterSpacing: "-0.16px",
              height: "40px",
              padding: "6px 16px",
              opacity: "1",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={handleAddClick}
          >
            <AddIcon sx={{ marginRight: "2px", fontSize: "22px" }} />
            <span style={{ marginTop: "0.1rem" }}> Add</span>
          </button>
        </Grid>
        <Menu anchorEl={anchorEl} open={open} onClose={handleCloseMenu}>
          {[
            "customer_raw_id",
            "customer_name",
            "route_name",
            "route_app_id",
            "customer_division",
            "customer_channel",
            "customer_subchannel",
            "customer_status",
          ].map((filter) => (
            <MenuItem
              sx={{
                padding: "4px 12px",
                marginBottom: "4px",
              }}
              key={filter}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterVisibility[filter]}
                    onChange={() => handleFilterToggle(filter)}
                    sx={{
                      color: "rgba(23, 71, 153, 1)", // Set the default unchecked color
                      "&.Mui-checked": {
                        color: "rgba(23, 71, 153, 1)", // Set the checked color to blue
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: "24px", // Adjust the size of the checkbox icon (similar to image)
                      },
                    }}
                  />
                }
                label={fieldLabels[filter]}
              />
            </MenuItem>
          ))}
        </Menu>
      </Grid>
    </>
  );
};

export default CustomerSearchFilters;
