// TaskCreationModal.js
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import EditTasks from "../../pages/EditTask";

const TaskEditModal = ({ route_id, user_id, fetchDetails }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button
        className="btn btn-outline-success font-weight-bold"
        type="button"
        onClick={handleShow}
      >
        Edit Visit
      </button>

      <Modal show={show} onHide={handleClose} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Updating Visit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* You can pass profileId or any other required props to Tasks component */}
          <EditTasks
            route_id={route_id}
            user_id={user_id}
            closeModal={handleClose}
            fetchDetails={fetchDetails}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TaskEditModal;
