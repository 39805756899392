// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-hip9hq-MuiPaper-root-MuiAppBar-root {
  color: black !important;
  box-shadow: none !important;
  background-color: transparent !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/styles/navbar.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,2BAA2B;EAC3B,wCAAwC;AAC1C","sourcesContent":[".css-hip9hq-MuiPaper-root-MuiAppBar-root {\n  color: black !important;\n  box-shadow: none !important;\n  background-color: transparent !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
