import {
  Box,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@mui/material/CircularProgress";
import {
  fetchVisitDetails,
  updateVisitDetails,
} from "../../services/assignRoutes/apiCalls";

const initialValues = {
  route_comment: "",
  dateField: "",

  route_monthly_repeated: "",
  visitDurationMonths: "",
  route_start_month: "",
  route_end_month: "",
  route_start_year: "",
  route_end_year: "",
};
const checkoutSchema = yup.object().shape({
  route_comment: yup.string().required("required"),

  dateField: yup.date().required("Date is required"),
});
const EditTasks = ({ route_id, user_id, closeModal, fetchDetails }) => {
  const [initialTaskValues, setInitialTaskValues] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(true);

  const isNonMobile = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    async function loadData() {
      try {
        const { initialTaskValues, error: detailsError } =
          await fetchVisitDetails(route_id, user_id);
        if (!detailsError) {
          console.log(initialTaskValues);
          setInitialTaskValues(initialTaskValues);
        } else {
          console.log(detailsError);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }

      setIsLoading(false);
    }

    loadData();
  }, []);

  // to make the api call here to save the updates on the visit details
  const handleFormSubmit = async (values) => {
    const [startYear, startMonth] = values.dateField.split("-").map(Number);
    if (values.route_monthly_repeated) {
      values = {
        ...values,
        visitDurationMonths: "",
        route_end_month: "",
        route_end_year: "",
      };
    } else {
      values = {
        ...values,
      };
      if (!values.route_monthly_repeated && values.visitDurationMonths) {
        const durationMonths = parseInt(values.visitDurationMonths, 10);
        if (durationMonths >= 1 && durationMonths <= 12) {
          const startDate = new Date(values.dateField);
          startDate.setMonth(startDate.getMonth() + durationMonths);

          values = {
            ...values,
            route_end_month: startDate.getMonth() + 1,
            route_end_year: startDate.getFullYear(),
          };
        }
      }
    }
    values = {
      ...values,
      route_start_year: startYear,
      route_start_month: startMonth,
    };
    const { dateField, visitDurationMonths, ...rest } = values;
    const FinalData = {
      ...rest,
      route_start_year: startYear,
      route_start_month: startMonth,
    };

    try {
      const { success, data, error } = await updateVisitDetails(
        route_id,
        user_id,
        FinalData
      );
      if (success) {
        toast.success(data.message);
        fetchDetails();
      } else {
        toast.error(`Error while updating: ${error}`, { theme: "dark" });
      }
    } catch (error) {
      toast.error("Error while updating", { theme: "dark" });
    } finally {
      closeModal();
    }
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Box sx={{ margin: "20px auto", width: "90%" }}>
      <Header title="Edit Visit" subtitle="Edit Visit" />

      <Formik
        onSubmit={(values, formikHelpers) =>
          handleFormSubmit(values, formikHelpers)
        }
        initialValues={initialTaskValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Visit Comment"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.route_comment}
                name="route_comment"
                error={!!touched.route_comment && !!errors.route_comment}
                helperText={touched.route_comment && errors.route_comment}
                sx={{ gridColumn: "span 4" }}
              />
              {/* <Autocomplete
                multiple
                options={filteredRoutes}
                getOptionLabel={(option) => option["route_name"]}
                isOptionEqualToValue={(option, value) =>
                  option.route_id === value.route_id
                }
                sx={{ gridColumn: "span 4" }}
                value={selectedRoutes}
                onChange={(event, newValue) => {
                  setSelectedRoutes(newValue);
                  const routes = newValue.map((user) => user["route_id"]);
                  setFieldValue("routes_id", routes);
                }}
                onInputChange={(event, newInputValue) => {
                  handleRouteSearch(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="filled"
                    label="Customer Name"
                    name="customer"
                    error={!!touched.routes_id && !!errors.routes_id}
                    helperText={touched.routes_id && errors.routes_id}
                    sx={{ gridColumn: "span 4" }}
                  />
                )}
              /> */}
              <TextField
                label="Choose a date"
                type="month"
                name="dateField"
                value={values.dateField}
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{
                  shrink: true,
                }}
                error={touched.dateField && Boolean(errors.dateField)}
                helperText={touched.dateField && errors.dateField}
                sx={{ gridColumn: "span 4" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.route_monthly_repeated}
                    onChange={(event) =>
                      setFieldValue(
                        "route_monthly_repeated",
                        event.target.checked
                      )
                    }
                    name="route_monthly_repeated"
                  />
                }
                label="Mark as default monthly visit"
                sx={{ gridColumn: "span 4" }}
              />
            </Box>
            {!values.route_monthly_repeated && (
              <FormControl fullWidth sx={{ gridColumn: "span 4" }}>
                <InputLabel id="visit-duration-select-label">
                  Visit Duration
                </InputLabel>
                <Select
                  labelId="visit-duration-select-label"
                  id="visitDurationMonths"
                  name="visitDurationMonths"
                  value={values.visitDurationMonths || ""}
                  label="Visit Duration"
                  onChange={handleChange}
                >
                  {[...Array(12).keys()].map((number) => (
                    <MenuItem key={number + 1} value={number + 1}>
                      {number + 1} Month{number + 1 > 1 ? "s" : ""}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}{" "}
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Save
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default EditTasks;
