import { Box, CircularProgress, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { toast } from "react-toastify";
import ProductCards from "./ProductCards";
import Header from "../../components/Header";

const ProductImages = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // to fetch  data from the firestore
  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const UsersCollection = collection(db, "products");
        const Usersnapshot = await getDocs(UsersCollection);
        const UsersData = Usersnapshot.docs.map((doc, index) => ({
          id: doc.id,
          ...doc.data(),
          rowNumber: index + 1,
        }));
        setIsLoading(false);
        setUsers(UsersData);
      } catch (err) {
        console.error("Error fetching Users:", err);
        toast.error("Error fetching Users , please try again", {
          theme: "dark",
        });
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTasks();
  }, []);

  //    here the field reperesent the key returned from the data and the headerName the title appears for the user

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Box>
      <Grid container spacing={3} sx={{ margin: "20px auto", width: "90%" }}>
        <Grid item xs={12}>
          <Header title="Products" subtitle="Products Images" />
        </Grid>
        <Grid item xs={12}>
          <ProductCards users={users} />;
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductImages;
