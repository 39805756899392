import { Box, CircularProgress, Drawer, Grid, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { fetchSurveysData } from "../../services/surveys/apicalls";
import MapModal from "../../components/mapModal";
import SurveyQuestionDrawer from "../../components/Drawers/surveyQuestionsDrawer";
import SurveyCustomFilters from "../../components/surveysFilters";
import dayjs from "dayjs";

const Surveys = () => {
  const [surveys, setSurveys] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isProfileDraweOpen, setIsProfileDrawerOpen] = useState(false);
  const [profileId, setProfileId] = useState(null);
  const [surveyprofile, setSurveyProfile] = useState(null);
  const [filteredSurveys, setFilteredSurveys] = useState([]);

  ///// config to show the map modal
  const [showModal, setShowModal] = useState(false);
  const [mapCoords, setMapCoords] = useState([0, 0]);
  const handleShowMap = (coords) => {
    const parsedCoords = coords.split(",").map(Number);
    setMapCoords(parsedCoords);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  /////

  const toggleProfileDrawer = () => {
    setIsProfileDrawerOpen(!isProfileDraweOpen);
  };

  /////////////////////////searchConfigurations
  const handleSearch = (filters) => {
    const filtered = surveys.filter((user) => {
      const surveyDate = user.visit_start_time_stamp
        ? dayjs(user.visit_start_time_stamp).startOf("day").valueOf()
        : null;
      const startOfDayStartDate = filters.startDate
        ? dayjs(filters.startDate).startOf("day").valueOf()
        : null;
      const startOfDayEndDate = filters.endDate
        ? dayjs(filters.endDate).startOf("day").valueOf()
        : null;
      return (
        (!filters.user_name ||
          (user.user_name &&
            user.user_name
              .toLowerCase()
              .includes(filters.user_name.toLowerCase()))) &&
        (!filters.user_position ||
          (user.user_position &&
            user.user_position
              .toLowerCase()
              .includes(filters.user_position.toLowerCase()))) &&
        (!filters.survey_ques_ref ||
          (user.survey_ques_ref &&
            user.survey_ques_ref
              .toLowerCase()
              .includes(filters.survey_ques_ref.toLowerCase()))) &&
        (!filters.customer_name ||
          (user.customer_name &&
            user.customer_name
              .toLowerCase()
              .includes(filters.customer_name.toLowerCase()))) &&
        (!filters.route_app_id ||
          (user.route_app_id &&
            user.route_app_id
              .toLowerCase()
              .includes(filters.route_app_id.toLowerCase()))) &&
        (!startOfDayStartDate ||
          (surveyDate && surveyDate >= startOfDayStartDate)) &&
        (!startOfDayEndDate || (surveyDate && surveyDate <= startOfDayEndDate))
      );
    });

    setFilteredSurveys(filtered);
  };
  const handleReset = () => {
    setFilteredSurveys(surveys);
  };
  useEffect(() => {
    fetchSurveysData(setIsLoading, setSurveys, setFilteredSurveys);
  }, []);
  /// Columns for the table showing users blow
  const columns = [
    {
      field: "rowNumber",
      headerName: "#",
      width: 80,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "uniqueId",
      headerName: "Survey Name",
      width: 150,
      renderCell: (params) => (
        <Box
          onClick={() => {
            setProfileId(params.row.survey_id);
            setSurveyProfile(params.row);
            toggleProfileDrawer();
          }}
          sx={{
            color: "rgba(58, 67, 68, 1)",
            fontWeight: "600",
            fontSize: "12px",
            lineHeight: "20px",
            letterSpacing: "-0.14px",
            fontFamily: "Poppins",
            padding: "1rem",
            cursor: "pointer",
          }}
        >
          {params.value}
        </Box>
      ),
    },
    {
      field: "user_name",
      headerName: "Submitter",
      width: 150,
    },
    {
      field: "user_position",
      headerName: "Role ",
      width: 200,
    },
    {
      field: "customer_name",
      headerName: "Store Name",
      width: 200,
    },
    {
      field: "route_app_id",
      headerName: "Route Id ",
      width: 200,
    },
    {
      field: "survey_ques_ref",
      headerName: "Survey Type",
      width: 200,
    },

    {
      field: "visit_start_time_stamp",
      headerName: "Start Time",
      width: 200,
      renderCell: (params) => {
        if (!params.value) {
          return null;
        }

        const [date, time] = params.value.split("T");
        return (
          <div className="MuiDataGrid-cellContent">
            {time}&nbsp;&nbsp;&nbsp;{date}
          </div>
        );
      },
    },
    {
      field: "visit_end_time_stamp",
      headerName: "Submit Time",
      width: 200,
      renderCell: (params) => {
        if (!params.value) {
          return null;
        }

        const [date, time] = params.value.split("T");
        return (
          <div className="MuiDataGrid-cellContent">
            {time}&nbsp;&nbsp;&nbsp;{date}
          </div>
        );
      },
    },

    {
      field: "distance_in_meters",
      headerName: "Approximate distance",
      width: 200,
      valueFormatter: (params) => {
        const value = Number(params.value);
        const value_in_Km = value / 1000;
        return `${value_in_Km.toFixed(4)} km`;
      },
    },
    {
      field: "visit_location",
      headerName: "Location",
      width: 170,
      renderCell: (params) => (
        <button
          className="btn btn-link"
          onClick={() => handleShowMap(params.value)}
        >
          <LocationOnIcon /> Show on map
        </button>
      ),
    },
  ];

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="container " style={{ height: "95%" }}>
      <Grid className="h-full" item xs={12}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: 40,
            marginBottom: "1rem",
            marginTop: "2rem",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "24px",
              lineHeight: "36px",
              fontWeight: "500",
              paddingLeft: "0.5rem",
              borderLeft: "5px solid  #134394 ",
              height: "100%",
              display: "flex",
              alignItems: "center",
              borderRadius: "2px 0px 0px 2px",
            }}
          >
            Survey Management
          </Typography>

          {/* Profile Drawer */}
          <Drawer
            anchor="right"
            open={isProfileDraweOpen}
            onClose={toggleProfileDrawer}
            sx={{
              "& .MuiDrawer-paper": { width: "50%", boxSizing: "border-box" },
            }}
          >
            <SurveyQuestionDrawer
              surveyId={profileId}
              onClose={toggleProfileDrawer}
              survey={surveyprofile}
            />
          </Drawer>
        </Box>
        <SurveyCustomFilters
          onSearch={handleSearch}
          onReset={handleReset}
          data={surveys}
        />

        <Box
          height="90%"
          sx={{
            "& .MuiDataGrid-row": {
              backgroundColor: "white",
            },
            "& .MuiDataGrid-root": {
              color: "#424242",
              borderRadius: "10px",
            },
            "& .MuiDataGrid-cell": {
              color: "#424242",
              fontSize: "14px",
              borderRight: "1px solid #e0e0e0",
              justifyContent: "center !important",
            },
            "& .MuiDataGrid-cellContent": {
              fontFamily: "Poppins",
              color: "rgba(58, 67, 68, 1)",
              fontWeight: "500",
              fontSize: "12px",
              lineHeight: "20px",
              letterSpacing: "-0.14px",
              padding: "1rem",
              cursor: "pointer",
            },
            "& .MuiDataGrid-columnHeaderTitleContainer": {
              padding: "1rem",
              justifyContent: "center",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontFamily: "Poppins",
              fontSize: "12px",
              fontWeight: "600",
              lineHeight: "18px",
              letterSpacing: "-0.01em",
              textAlign: "left",
              color: "rgba(97, 129, 184, 1)", // Ensure text color is visible against the background
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "rgba(238, 241, 247, 1)", // Background color for headers
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: "#fafafa",
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "1px solid #e0e0e0",
              backgroundColor: "#f5f5f5",
            },
            "& .MuiCheckbox-root": {
              color: `#757575 !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `#424242 !important`,
            },
            "& .MuiDataGrid-footerContainer ": {
              background: "white",
            },
            "& .MuiDataGrid-columnHeader": {
              height: "50px",
              padding: "10px 16px", // Padding for headers
              gap: "10px",
              border: "0px solid",
              borderRight: "1px solid #e0e0e0",
            },
          }}
        >
          <DataGrid
            rows={filteredSurveys}
            columns={columns}
            rowHeight={70}
            disableSelectionOnClick
            pageSizeOptions={[8, 16, 32, 64, 100]}
          />
        </Box>
      </Grid>
      <MapModal show={showModal} onHide={handleCloseModal} coords={mapCoords} />
    </div>
  );
};

export default Surveys;
