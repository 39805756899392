// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiList-root.MuiList-padding.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 0px !important;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  padding-left: 8px !important;
  padding-right: 12px !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root + .MuiDivider-root {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/styles/menus.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;AACA;EACE,4BAA4B;EAC5B,8BAA8B;AAChC;;AAEA;EACE,0BAA0B;EAC1B,6BAA6B;AAC/B","sourcesContent":[".MuiList-root.MuiList-padding.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list {\n  padding: 0px !important;\n}\n.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {\n  padding-left: 8px !important;\n  padding-right: 12px !important;\n}\n\n.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root + .MuiDivider-root {\n  margin-top: 6px !important;\n  margin-bottom: 6px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
