import React from "react";
import { TextField, InputAdornment } from "@mui/material";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import { toast } from "react-toastify";

const BrandForm = ({ getFieldProps, touched, errors, setFieldValue }) => {
  const brandNameProps = getFieldProps("brand_name");

  return (
    <div className="flex mb-2 flex-col">
      <div className="flex mb-2">
        <div className="flex w-100 flex-col mr-4">
          <label
            style={{
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "16px",
            }}
            htmlFor="brand_name"
            className="mb-2 input-label"
          >
            Brand Name *
          </label>
          <TextField
            id="outlined-basic"
            variant="outlined"
            placeholder="Enter Brand Full"
            type="text"
            autoComplete="Brand Name"
            required
            {...brandNameProps}
            value={brandNameProps.value || ""}
            onChange={(e) => setFieldValue("brand_name", e.target.value)}
            error={touched.brand_name && Boolean(errors.brand_name)}
            InputProps={{
              startAdornment: (
                <InputAdornment sx={{ display: "none" }} position="start">
                  <LocationCityIcon style={{ color: "#030391eb" }} />
                </InputAdornment>
              ),
            }}
            helperText={touched.brand_name && errors.brand_name}
            className="mt-1 mb-4 rounded-lg"
          />
        </div>
      </div>

      <div className="flex mb-2">
        <div className="flex w-100 flex-col">
          <label
            style={{
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "16px",
            }}
            htmlFor="brand_image"
            className="mb-2 input-label"
          >
            Brand Logo *
          </label>
          <input
            id="brand_image"
            name="brand_image"
            type="file"
            accept="image/*"
            style={{
              paddingBottom: "2.5rem",
              paddingTop: "0.7rem",
              fontFamily: "Poppins",
            }}
            onChange={(event) => {
              const file = event.target.files[0];
              const maxSize = 1024 * 1024;
              if (file && file.size > maxSize) {
                toast.error(
                  "Logo is too large. Please upload a logo smaller than 1MB."
                );
                event.target.value = null;
              } else {
                setFieldValue("brand_image", file);
              }
            }}
            className="form-control"
          />
          {touched.brand_image && errors.brand_image && (
            <div className="text-danger">{errors.brand_image}</div>
          )}
          <div
            style={{
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "12px",
            }}
            className="my-2"
          >
            Max logo size: 1MB
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandForm;
