import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";

import "../../components/styles/adminprofile.css";
import { profileDataApiCall } from "../../services/users/usersApis";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { Input, Ripple, initTWE } from "tw-elements";

import { useSalesRepContext } from "../../components/context/salesfetch";
import EditUserForm from "../../components/forms/editUser";
import EditPasswordForm from "../../components/forms/editUserPassword";
import { mapUserRole } from "../../utils/helperFunctions";
initTWE({ Input, Ripple });

const AdminProfile = () => {
  const [userData, setUserData] = useState({});
  const { updateFlag } = useSalesRepContext();
  const [selectedTab, setSelectedTab] = useState("Personal Details");

  const renderContent = () => {
    switch (selectedTab) {
      case "Personal Details":
        return <EditUserForm userData={userData} user_id={user_id} />;
      case "Password":
        return <EditPasswordForm user_id={user_id} />;
      default:
        return null;
    }
  };
  const [dataLoading, setDataLoading] = useState(true);
  const { user_id } = jwtDecode(Cookies.get("Price_App_Token"));

  useEffect(() => {
    const fetchData = async () => {
      const data = await profileDataApiCall(user_id, setDataLoading);
      setUserData(data); // Store the data in state
    };

    fetchData();
  }, [user_id, updateFlag]);

  //////////////////////////// Editing Passwords ----------------

  //////////////////////////////////////////
  if (dataLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <div className="container">
      <Grid xs={12} item>
        <Box
          sx={{
            display: "none",
            alignItems: "center",
            justifyContent: "space-between",
            height: 45,
            marginBottom: "1rem",
            marginTop: "0.5rem",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "24px",
              lineHeight: "36px",
              fontWeight: "500",
              paddingLeft: "0.5rem",
              borderLeft: "5px solid  #134394 ",
              height: "100%",
              display: "flex",
              alignItems: "center",
              borderRadius: "2px 0px 0px 2px",
            }}
          >
            Profile
          </Typography>
        </Box>
        <div
          style={{ padding: "0.5rem", height: "70vh", position: "relative" }}
        >
          {" "}
          <div className="container profileContainer">
            {" "}
            <Avatar
              alt="Profile Picture"
              src=""
              sx={{ width: 80, height: 80 }}
              className="avatar"
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "13.5rem" /* Adjust as needed for spacing */,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  fontSize: "18px",
                  lineHeight: "24px",
                  letterSpacing: "-0.18px",
                  paddingLeft: "0.5rem",
                  color: " #000000",
                  textTransform: "capitalize",
                  opacity: 1,
                }}
                className="mb-1"
              >
                {userData.user_name}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "22px",
                  letterSpacing: "-0.16px",
                  paddingLeft: "0.5rem",

                  color: " #868686",
                  textTransform: "capitalize",
                  opacity: 1,
                }}
                color="textSecondary"
              >
                {mapUserRole(userData.user_position)}
              </Typography>
            </Box>
          </div>
          <Grid
            container
            mt={15}
            sx={{
              height: "100%",
            }}
            spacing={2}
          >
            <Grid item xs={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  padding: "1rem",
                  backgroundColor: "transparent",
                  height: "100%",
                }}
              >
                <Button
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    lineHeight: "24px",
                    fontWeight: "500",
                    letterSpacing: "-0.18px",
                    color: "#000000",
                    justifyContent: "start",
                    backgroundColor:
                      selectedTab === "Personal Details"
                        ? "#1343940f"
                        : "transparent",
                    padding: "1rem 2rem",
                    textTransform: "capitalize",
                  }}
                  className="mb-3"
                  fullWidth
                  onClick={() => setSelectedTab("Personal Details")}
                >
                  Personal Details
                </Button>
                <Button
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    lineHeight: "24px",
                    fontWeight: "500",
                    letterSpacing: "-0.18px",
                    color: "#000000",
                    justifyContent: "start",
                    backgroundColor:
                      selectedTab === "Password" ? "#1343940f" : "transparent",
                    padding: "1rem 2rem",
                    textTransform: "capitalize",
                  }}
                  fullWidth
                  onClick={() => setSelectedTab("Password")}
                >
                  Password
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              sx={{
                height: "100%",
              }}
              xs={8}
            >
              <Box
                sx={{
                  backgroundColor: "#ffffff",
                  height: "100%",
                }}
              >
                {renderContent()}
              </Box>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </div>
  );
};

export default AdminProfile;
