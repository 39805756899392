import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";

import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { fetchCountryRoutes } from "../../services/allroutes/apicalls";
import CreateRouteForm from "../../components/Drawers/addRouteDrawer";
import { useRoutesContext } from "../../components/context/routesfetch";
import RoutesMenu from "../../components/Menus/routesMenu";
import RouteProfileDrawer from "../../components/Drawers/RouteProfileDrawer";
import AddButton from "../../components/buttons/addButton";
import RouteSearchFilters from "../../components/routeFilters";
const Routes = () => {
  const [routes, setRoutes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { updateRoutesFlag } = useRoutesContext();
  const [routeprofile, setProfile] = useState(null);
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showRemovedRoutes, setShowRemovedRoutes] = useState(false);

  //////////////////Action menus
  const handleMenuClick = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const toggleRemovedUsers = () => setShowRemovedRoutes(!showRemovedRoutes);
  const open = Boolean(anchorEl);

  ///////////////// Adding Route Configuration
  const [isAddRouteDrawerOpen, setAddRouteDrawerOpen] = useState(false);

  const toggleAddDrawer = () => {
    setAddRouteDrawerOpen(!isAddRouteDrawerOpen);
  };
  ////////////////////////////////Showing Route Profile
  const [isProfileDraweOpen, setIsProfileDrawerOpen] = useState(false);

  const toggleProfileDrawer = () => {
    setIsProfileDrawerOpen(!isProfileDraweOpen);
  };
  ///////////////////////// Search Configuration
  const handleSearch = (filters) => {
    const filtered = routes.filter((user) => {
      return (
        (!filters.route_name ||
          (user.route_name &&
            user.route_name
              .toLowerCase()
              .includes(filters.route_name.toLowerCase()))) &&
        (!filters.route_app_id ||
          (user.route_app_id &&
            user.route_app_id
              .toLowerCase()
              .includes(filters.route_app_id.toLowerCase()))) &&
        (!filters.route_status ||
          (user.route_status &&
            user.route_status
              .toLowerCase()
              .includes(filters.route_status.toLowerCase())))
      );
    });
    setFilteredRoutes(filtered);
  };
  const handleReset = () => {
    setFilteredRoutes(routes);
  };
  ///////////////////////////

  useEffect(() => {
    fetchCountryRoutes(
      setIsLoading,
      setRoutes,
      setFilteredRoutes,
      showRemovedRoutes ? "true" : "false"
    );
  }, [updateRoutesFlag, showRemovedRoutes]);
  /// Columns for the table showing users blow
  const columns = [
    {
      field: "rowNumber",
      headerName: "#",
      width: 80,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "route_app_id",
      headerName: "Route Id",
      width: 250,
      renderCell: (params) => (
        <Box
          onClick={() => {
            setProfile(params.row);
            toggleProfileDrawer();
          }}
          sx={{
            color: "rgba(58, 67, 68, 1)",
            fontWeight: "600",
            fontSize: "12px",
            lineHeight: "20px",
            letterSpacing: "-0.14px",
            fontFamily: "Poppins",
            padding: "1rem",
            cursor: "pointer",
          }}
        >
          {params.value}
        </Box>
      ),
    },
    {
      field: "route_name",
      headerName: "Route Name",
      width: 250,
    },
    {
      field: "store_count",
      headerName: "Stores",
      minWidth: 150,
      // renderCell: (params) => {
      //   return (
      //     <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
      //       {params.value.map((store, index) => (
      //         <Chip
      //           key={index}
      //           label={store}
      //           sx={{
      //             fontFamily: "Poppins",
      //             fontWeight: "600",
      //             fontSize: "12px",
      //             lineHeight: "17px",
      //             letterSpacing: "-0.12px",
      //             textTransform: "capitalize",
      //             backgroundColor: index === 0 ? "#13439433" : "#03A9F433",
      //             mb: 0.5,
      //             border: "none",
      //             padding: "0.5rem 0.5rem",
      //           }}
      //         />
      //       ))}
      //       {params.value.length >= 2 && (
      //         <Typography
      //           className="text-muted"
      //           sx={{
      //             cursor: "pointer",
      //             padding: "0.5rem",
      //             fontSize: "14px",
      //           }}
      //           onClick={() => {
      //             console.log(params.row);
      //             setProfile(params.row);
      //             toggleProfileDrawer();
      //           }}
      //         >
      //           Show More
      //         </Typography>
      //       )}
      //     </Box>
      //   );
      // },
    },
    {
      field: "route_status",
      headerName: "Status",
      sortable: false,

      width: 150,
      renderCell: (params) => (
        <>
          {params.value === "Active" ? (
            <Box
              className="px-3 py-2"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "600",
                fontSize: "10px",
                lineHeight: "14px",
                fontFamily: "Poppins",
                letterSpacing: "0.1px",
                color: "#037847",
                textTransform: "capitalize",
                background: "#ECFDF3 0% 0% no-repeat padding-box",
                borderRadius: "11px",
                minWidth: "80px",
              }}
            >
              <FiberManualRecordIcon
                sx={{ fontSize: "inherit", marginRight: "5px" }}
              />
              Active
            </Box>
          ) : (
            <Box
              className="px-3 py-2"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "600",
                fontSize: "10px",
                lineHeight: "14px",
                fontFamily: "Poppins",
                letterSpacing: "0.1px",
                color: "#D12E34",
                textTransform: "capitalize",
                background: "#FED8D9 0% 0% no-repeat padding-box",
                borderRadius: "11px",
              }}
            >
              <FiberManualRecordIcon
                sx={{ fontSize: "inherit", marginRight: "5px" }}
              />
              Inactive
            </Box>
          )}
        </>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      renderCell: (params) => <RoutesMenu params={params} />,
    },
  ];

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="container " style={{ height: "95%" }}>
      <Grid item xs={12} className="h-full">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: 40,
            marginBottom: "1rem",
            marginTop: "2rem",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "24px",
              lineHeight: "36px",
              fontWeight: "500",
              paddingLeft: "0.5rem",
              borderLeft: "5px solid  #134394 ",
              height: "100%",
              display: "flex",
              alignItems: "center",
              borderRadius: "2px 0px 0px 2px",
            }}
          >
            Route Management
          </Typography>
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <AddButton
              sx={{
                width: "152px", // Set the width to 152px
                height: "40px", // Set the height to 40px
              }}
              onclick={toggleAddDrawer}
              text={"Add a new route"}
            />
            <Button
              className="btn  d-flex align-items-center"
              style={{
                textTransform: "capitalize",
                padding: "7px 16px",
                borderRadius: "30px",
                border: "1px solid rgba(23, 71, 153, 1)",
                fontFamily: "Poppins",
                fontSize: "12px",
                lineHeight: "25px",
                fontWeight: "600",
                color: "rgba(23, 71, 153, 1)", // Text color
                letterSpacing: "-0.18px",
                opacity: "1", // Ensure opacity is 1 so the button is visible
                display: "inline-flex", // Responsive display with flexbox
                alignItems: "center", // Center content vertically
                justifyContent: "center", // Center content horizontally
              }}
              id="actions-button"
              aria-controls={open ? "actions-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleMenuClick}
              endIcon={<ArrowDropDownIcon />}
            >
              Actions
            </Button>
            <Menu
              sx={{
                padding: "0px !important",
              }}
              id="actions-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              MenuListProps={{
                "aria-labelledby": "actions-button",
              }}
            >
              <MenuItem
                className="rounded mt-1"
                onClick={() => {
                  toggleRemovedUsers();
                  handleMenuClose();
                }}
              >
                <RestoreOutlinedIcon
                  sx={{
                    fontSize: "15px",
                    marginRight: "5px",
                    color: "rgba(57, 71, 83, 1)",
                  }}
                />
                <span
                  style={{
                    color: "rgba(57, 71, 83, 1)",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    lineHeight: "18px",
                  }}
                >
                  {showRemovedRoutes
                    ? "Hide Removed Routes"
                    : "Show Removed Routes"}
                </span>
              </MenuItem>
            </Menu>

            {/* Add User Drawer */}
            <Drawer
              anchor="right"
              open={isAddRouteDrawerOpen}
              onClose={toggleAddDrawer}
              sx={{
                "& .MuiDrawer-paper": { width: "50%", boxSizing: "border-box" },
              }}
            >
              <CreateRouteForm onClose={toggleAddDrawer} />
            </Drawer>
            {/* Profile Drawer */}
            <Drawer
              anchor="right"
              open={isProfileDraweOpen}
              onClose={toggleProfileDrawer}
              sx={{
                "& .MuiDrawer-paper": { width: "50%", boxSizing: "border-box" },
              }}
            >
              <RouteProfileDrawer
                profile={routeprofile}
                onClose={toggleProfileDrawer}
              />
            </Drawer>
          </Box>
        </Box>
        <RouteSearchFilters
          onSearch={handleSearch}
          onReset={handleReset}
          data={routes}
        />
        <Box
          height="90%"
          sx={{
            "& .MuiDataGrid-row": {
              backgroundColor: "white",
            },
            "& .MuiDataGrid-root": {
              color: "#424242",
              borderRadius: "10px",
            },
            "& .MuiDataGrid-cell": {
              color: "#424242",
              fontSize: "14px",
              borderRight: "1px solid #e0e0e0",
              justifyContent: "center !important",
            },
            "& .MuiDataGrid-cellContent": {
              fontFamily: "Poppins",
              color: "rgba(58, 67, 68, 1)",
              fontWeight: "500",
              fontSize: "12px",
              lineHeight: "20px",
              letterSpacing: "-0.14px",
              padding: "1rem",
              cursor: "pointer",
            },
            "& .MuiDataGrid-columnHeaderTitleContainer": {
              padding: "1rem",
              justifyContent: "center",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontFamily: "Poppins",
              fontSize: "12px",
              fontWeight: "600",
              lineHeight: "18px",
              letterSpacing: "-0.01em",
              textAlign: "left",
              color: "rgba(97, 129, 184, 1)", // Ensure text color is visible against the background
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "rgba(238, 241, 247, 1)", // Background color for headers
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: "#fafafa",
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "1px solid #e0e0e0",
              backgroundColor: "#f5f5f5",
            },
            "& .MuiCheckbox-root": {
              color: `#757575 !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `#424242 !important`,
            },
            "& .MuiDataGrid-footerContainer ": {
              background: "white",
            },
            "& .MuiDataGrid-columnHeader": {
              height: "50px",
              padding: "10px 16px", // Padding for headers
              gap: "10px",
              border: "0px solid",
              borderRight: "1px solid #e0e0e0",
            },
          }}
        >
          <DataGrid
            rows={filteredRoutes}
            columns={columns}
            rowHeight={70}
            disableSelectionOnClick
            pageSizeOptions={[8, 16, 32, 64, 100]}
          />
        </Box>
      </Grid>
    </div>
  );
};

export default Routes;
