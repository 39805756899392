import React, { useCallback, useMemo, useState } from "react";
import { TextField, Grid, InputAdornment, Autocomplete } from "@mui/material";
import { Person } from "@mui/icons-material";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import { mapUserRole } from "../../utils/helperFunctions";
import "../styles/galleryfilters.css";

const UserSearchFilters = ({ onSearch, onReset, data }) => {
  const [searchFilters, setSearchFilters] = useState({
    user_name: "",
    user_username: "",
    user_city: "",
    user_position: "",
    user_status: "",
  });

  const handleAutocompleteChange = useCallback(
    (event, newValue, reason, field) => {
      if (reason === "input") {
        setSearchFilters((prevFilters) => ({
          ...prevFilters,
          [field]: event.target.value,
        }));
      } else if (reason === "reset" && newValue) {
        setSearchFilters((prevFilters) => ({
          ...prevFilters,
          [field]: newValue,
        }));
      }
    },
    []
  );

  const fieldLabels = {
    user_name: "Full Name",
    user_username: "User Name",
    user_city: "City",
    user_position: "Role",
    user_status: "Status",
  };

  const options = useMemo(() => {
    const fieldOptions = {
      user_name: new Set(),
      user_username: new Set(),
      user_city: new Set(),
      user_position: new Set(),
      user_status: ["Active", "Inactive"],
    };

    data.forEach((item) => {
      if (item.user_name) fieldOptions.user_name.add(item.user_name);
      if (item.user_username)
        fieldOptions.user_username.add(item.user_username);
      if (item.user_city) fieldOptions.user_city.add(item.user_city);
      if (item.user_position) {
        const mappedRole = mapUserRole(item.user_position);
        fieldOptions.user_position.add(mappedRole);
      }
    });

    return {
      user_name: Array.from(fieldOptions.user_name),
      user_username: Array.from(fieldOptions.user_username),
      user_city: Array.from(fieldOptions.user_city),
      user_position: Array.from(fieldOptions.user_position),
      user_status: fieldOptions.user_status,
    };
  }, [data]); // Dependency on data
  const handleSearch = useCallback(() => {
    onSearch(searchFilters);
  }, [searchFilters, onSearch]);

  const handleReset = useCallback(() => {
    setSearchFilters({
      user_name: "",
      user_username: "",
      user_city: "",
      user_position: "",
      user_status: "",
    });
    onReset();
  }, [onReset]);
  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        style={{ marginBottom: "1rem" }}
      >
        <Grid item>
          <h6
            style={{
              margin: 0,
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "42px",
            }}
          >
            Search by
          </h6>
        </Grid>
        <Grid item xs={12} sm="auto">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              width: "100%",
            }}
          >
            <button
              type="button"
              onClick={handleSearch}
              className="btn py-3 px-5 d-flex align-items-center justify-content-center"
              style={{
                background: "rgba(19, 67, 149, 1)",
                borderRadius: "30px",
                fontFamily: "Poppins",
                fontSize: "12px",
                lineHeight: "25px",
                fontWeight: "600",
                color: "#FFFFFF",
                letterSpacing: "-0.16px",
                height: "40px",
                width: "66.67%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                maxWidth: "140px",
              }}
            >
              Search
            </button>
            <button
              type="button"
              onClick={handleReset}
              className="btn py-3 px-5 d-flex align-items-center"
              style={{
                background: "transparent",
                border: "1px solid rgba(19, 67, 149, 1)",
                borderRadius: "50px",
                fontFamily: "Poppins",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: "500",
                color: "rgba(19, 67, 149, 1)",
                letterSpacing: "-0.16px",
                height: "40px",
                width: "33.33%", // Takes 1/3 of the width
                textAlign: "center", // Center text alignment
                display: "flex", // Flex to center the content
                justifyContent: "center", // Horizontally center the text
                alignItems: "center", // Vertically center the text
                maxWidth: "90px",
              }}
            >
              Reset
            </button>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        sx={{
          width: "100%",
          marginBottom: "1rem",
          flexWrap: { xs: "wrap", md: "nowrap", gap: "0.5rem" },
        }}
      >
        {Object.keys(searchFilters).map((key) => (
          <Grid item xs={12} sm={3} key={key}>
            <Autocomplete
              id="FullName-autocomplete"
              fullWidth
              disableClearable
              freeSolo
              value={searchFilters[key]}
              isOptionEqualToValue={(option, value) =>
                option === value || value === "" || !option
              }
              onInputChange={(event, newValue, reason) =>
                handleAutocompleteChange(event, newValue, reason, key)
              }
              placeholder={`Search by ${fieldLabels[key]}`}
              options={options[key] || []}
              getOptionLabel={(option) => option || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={key}
                  placeholder={`Search by ${fieldLabels[key]}`}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        {key === "user_name" && <Person />}
                        {key === "user_username" && <Person />}
                        {key === "user_city" && <LocationCityIcon />}
                        {key === "user_position" && <VpnKeyIcon />}
                        {key === "user_status" && <ToggleOnIcon />}
                      </InputAdornment>
                    ),
                    style: {
                      height: "40px",
                      padding: "5px 16px",
                      maxWidth: "250px",
                      boxShadow: "0px 3px 6px #a8a8a833",
                      borderRadius: "50px",
                    },
                  }}
                  className="custom-autocomplete-input"
                />
              )}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default UserSearchFilters;
