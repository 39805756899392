import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { fetchBrandProducts } from "../../../services/allbrands/apicalls";
export default function BrandProfileDrawer({ profile, onClose }) {
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [productNames, setProductNames] = useState([]);

  const [visibleStores, setVisibleStores] = useState(5);

  const handleShowMore = () => {
    setVisibleStores((prev) => prev + 5);
  };
  useEffect(() => {
    const loadProducts = async () => {
      try {
        await fetchBrandProducts(
          setIsLoading,
          setProducts,
          profile.brand_name,
          setProductNames
        );
      } catch (error) {
        toast.error("Failed to fetch products");
      } finally {
        setIsLoading(false);
      }
    };

    loadProducts();
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <Box sx={{ width: "100%" }} autoComplete="off">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          boxShadow: "0px 3px 6px #A8A8A81F",
        }}
        autoComplete="off"
        className="p-4 mb-3"
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "30px",
            letterSpacing: "-0.22px",
            color: " #000000",
          }}
        >
          Brand Details
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          padding: 4,
          backgroundColor: "#fff",
          margin: 2,
        }}
      >
        <Grid container spacing={2}>
          {/* Left column */}
          <Grid
            item
            xs={6}
            sx={{ borderRight: 1, borderColor: "divider", paddingRight: 2 }}
          >
            <Grid container>
              {[{ label: "Brand Name", value: profile.brand_name }].map(
                (item, index) => (
                  <Grid
                    item
                    xs={12}
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: 1,
                      borderColor: "divider",
                      py: 1,
                      height: "65px",
                    }}
                  >
                    <Typography
                      style={{
                        font: "normal normal 500 18px/22px Poppins",
                        letterSpacing: " -0.14px",
                        color: "#000000",
                        textTransform: " capitalize",
                      }}
                    >
                      {item.label}
                    </Typography>
                    <Typography
                      style={{
                        font: "normal normal 400 16px/20px Poppins",
                        letterSpacing: " -0.14px",
                        color:
                          item.label === "Route Id" ? "#03A9F4" : "#000000",
                        textTransform: " capitalize",
                      }}
                    >
                      {item.value}
                    </Typography>
                  </Grid>
                )
              )}
            </Grid>
          </Grid>

          {/* Right column */}
          <Grid item xs={6}>
            <Grid container>
              {[{ label: "Brand Logo", value: profile.brand_image_url }].map(
                (item, index) => (
                  <Grid
                    item
                    xs={12}
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: 1,
                      borderColor: "divider",
                      py: 1,

                      height: "65px",
                    }}
                  >
                    <Typography
                      style={{
                        font: "normal normal 500 18px/22px Poppins",
                        letterSpacing: " -0.14px",
                        color: "#000000",
                        textTransform: " capitalize",
                      }}
                    >
                      {item.label}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={item.value}
                        alt="Brand Logo"
                        loading="lazy"
                        className="rounded"
                        style={{
                          width: "50%",
                          height: "50%",
                          maxWidth: "100px",
                        }}
                      />
                    </Box>
                  </Grid>
                )
              )}
            </Grid>
          </Grid>

          {/* Routes - Full width */}
          <Grid
            item
            xs={12}
            sx={{ pt: 2, borderBottom: 1, borderColor: "divider" }}
          >
            <Box
              sx={{
                display: "flex",
                mb: 1,
                mt: 2,
                justifyContent: "space-between",
                alignItems: "start",
              }}
            >
              <Typography
                style={{
                  font: "normal normal 500 18px/22px Poppins",
                  letterSpacing: "-0.14px",
                  color: "#000000",
                  textTransform: "capitalize",
                  marginRight: "1rem",
                  minWidth: "100px",
                }}
              >
                Products
              </Typography>
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-end",
                  gap: 0.5,
                }}
              >
                {productNames.slice(0, visibleStores).map((route, index) => (
                  <Chip
                    key={index}
                    label={route}
                    variant="outlined"
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: "500",
                      fontSize: "12px",
                      lineHeight: "17px",
                      letterSpacing: "-0.12px",
                      textTransform: "capitalize",
                      backgroundColor:
                        index % 4 === 0 || index % 4 === 3
                          ? "#13439433"
                          : "#03A9F433",
                      mb: 0.5,
                      border: "none",
                      padding: "0.5rem 0.5rem",
                    }}
                  />
                ))}
                {productNames.length > visibleStores && (
                  <Typography
                    sx={{
                      cursor: "pointer",
                      color: "#03A9F4",
                      padding: "0.5rem",
                      alignSelf: "center",
                      textDecoration: "underline",
                      fontFamily: "Poppins",
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "19px",
                      textTransform: "capitalize",
                      lineSpacing: "-0.14px",
                    }}
                    onClick={handleShowMore}
                  >
                    See More
                  </Typography>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

//
