// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/profileBackground.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profileContainer {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  height: 80px;
  position: relative;
  flex-direction: column;
}

.avatar {
  position: absolute !important;
  top: 100%;
  transform: translateY(-50%);
}
`, "",{"version":3,"sources":["webpack://./src/components/styles/adminprofile.css"],"names":[],"mappings":"AAAA;EACE,yDAAwD;EACxD,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,6BAA6B;EAC7B,SAAS;EACT,2BAA2B;AAC7B","sourcesContent":[".profileContainer {\n  background-image: url(\"../images/profileBackground.png\");\n  background-size: cover;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 10px;\n  height: 80px;\n  position: relative;\n  flex-direction: column;\n}\n\n.avatar {\n  position: absolute !important;\n  top: 100%;\n  transform: translateY(-50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
