import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import CheckYourEmailGif from "../images/email_gif.gif";
import "../styles/emailModal.css";
import EllipseImage from "../images/ellipse.png";

function OtpSentModal({ show, onHide, onResend, onLogin, email }) {
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        onHide();
      }, 5000);
    }
  }, [show, onHide]);
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      dialogClassName="custom-modal"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Modal.Body
        style={{
          position: "relative",
          width: "600px",
          height: "450px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="text-center"
      >
        <img
          src={EllipseImage}
          alt="Ellipse Shape"
          style={{
            position: "absolute",
            top: "0px",
            width: "500px",
            height: "190px",
            opacity: "1",
            left: "50%", // Start positioning at the center
            transform: "translateX(-50%)", // Adjust positioning to center the element
          }}
        />
        <div className="w-full d-flex justify-content-center align-items-center">
          {" "}
          <img
            src={CheckYourEmailGif}
            alt="Envelope"
            style={{
              maxWidth: "200px",
              marginBottom: "20px",
              maxHeight: "200px",
              zIndex: 10000,
            }}
          />
        </div>

        <h3
          style={{
            fontFamily: "Poppins",
            fontWeight: "800",
            fontSize: "24px",
            lineHeight: "34px",
            textAlign: "center",
            color: "rgba(72, 90, 105, 1)",
            marginTop: "1rem",
          }}
        >
          Check Your Inbox!
        </h3>
        <p
          style={{
            fontFamily: "Poppins",
            fontSize: "18px",
            lineHeight: "26px",
            fontWeight: "400",
            textAlign: "center",
            marginTop: "1rem",

            marginBottom: "1.5rem",
            color: "rgba(125, 148, 166, 1)",
          }}
        >
          We’ve sent an OTP code to your email. Enter the code to <br /> verify
          your identity.
        </p>
        <p
          style={{
            fontFamily: "Poppins",
            fontWeight: "400",

            fontSize: "16px",
            lineHeight: "25px",
            color: "rgba(147, 166, 182, 1)",
          }}
        >
          Didn’t get the email?{" "}
          <span
            onClick={onResend}
            style={{
              fontFamily: "Poppins",
              fontWeight: "600",

              fontSize: "16px",
              lineHeight: "25px",
              color: " rgba(19, 67, 149, 1)",
              cursor: "pointer",
              textDecoration: "none",
            }}
          >
            Click here to resend
          </span>{" "}
          or{" "}
          <span
            onClick={onLogin}
            style={{
              fontFamily: "Poppins",
              fontWeight: "600",

              fontSize: "16px",
              lineHeight: "25px",
              color: " rgba(19, 67, 149, 1)",
              cursor: "pointer",
              textDecoration: "none",
            }}
          >
            Go back to Login
          </span>
        </p>
      </Modal.Body>
    </Modal>
  );
}

export default OtpSentModal;
