import { useState, useEffect } from "react";
import axios from "axios";

const categoryMapping = {
  Assigned_Routes: "routes_users_link_qa",
  Visits: "visits_qa",
};

export function useDetails(userId, activeTab) {
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  async function fetchDetails() {
    setLoading(true);
    try {
      const table_name = categoryMapping[activeTab];
      const response = await axios.get(
        `http://127.0.0.1:5000/api/getdata/${table_name}/${userId}`
      );
      const fetchedDetails = [];
      response.data.data.forEach((doc, index) => {
        fetchedDetails.push({
          id: index,
          ...doc,
        });
      });
      setDetails(fetchedDetails);
    } catch (error) {
      console.error("Error fetching Data:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchDetails();
  }, [userId, activeTab]);

  return [details, loading, fetchDetails];
}
