import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import "../styles/emailModal.css";
import EllipseImage from "../images/ellipse.png";
function SuccessResetPasswordModal({ onLogin, show, onHide }) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      dialogClassName="custom-modal-success"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      backdrop="static" // Prevent closing by clicking outside
      keyboard={false} // Prevent closing with the Escape key
    >
      <Modal.Body
        style={{
          position: "relative", // Allow positioning of the ellipse within the modal
          width: "600px",
          height: "450px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="text-center"
      >
        <img
          src={EllipseImage}
          alt="Ellipse Shape"
          style={{
            position: "absolute",
            top: "0px",
            width: "550px",
            height: "80px",
            opacity: "1",
            left: "50%", // Start positioning at the center
            transform: "translateX(-50%)", // Adjust positioning to center the element
          }}
        />
        <h3
          style={{
            fontFamily: "Poppins",
            fontWeight: "800",
            fontSize: "24px",
            lineHeight: "34px",
            textAlign: "center",
            color: "rgba(72, 90, 105, 1)",
            marginTop: "4rem",
          }}
        >
          Success! Your Password is Updated
        </h3>
        <p
          style={{
            fontFamily: "Poppins",
            fontSize: "16px",
            lineHeight: "26px",
            fontWeight: "400",
            textAlign: "center",
            marginTop: "1rem",

            marginBottom: "1.5rem",
            color: "rgba(103, 129, 150, 1)",
          }}
        >
          You’ve successfully reset your password. Log in with your new
          credentials
        </p>
        <button
          onClick={onLogin}
          style={{
            width: "133px",
            height: "44px",
            padding: "7px 16px",
            gap: "6px",
            borderRadius: "30px",
            opacity: 1, // Set opacity to 1 to make the button visible
            backgroundColor: "rgba(19, 67, 149, 1)",
            fontFamily: "Poppins",
            fontSize: "12px",
            fontWeight: "600",
            lineHeight: "25px",
            textAlign: "center",
            color: "rgba(255, 255, 255, 1)",
            border: "none",
            cursor: "pointer",
          }}
        >
          Login Now
        </button>
      </Modal.Body>
    </Modal>
  );
}

export default SuccessResetPasswordModal;
