import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Col, Container, Nav, Row } from "react-bootstrap";
import ProductCards from "../ProductImages/ProductCards";
import { CircularProgress } from "@mui/material";
import "../../components/styles/profile.css";
import TaskEditModal from "../../components/taskEditModal";
import TaskDeleteModal from "../../components/taskDeleteModal";
import { useDetails } from "../../components/customizeHooks/fetchProductsHook";
import TaskCreationModal from "../../components/taskCreationModal";

const UserProfile = () => {
  const { userid, username } = useParams();

  const [activeTab, setActiveTab] = useState("Assigned_Routes");
  const [categories, setCategories] = useState({});
  const [columnMapping, setColumnMapping] = useState({});
  const [showModal, setShowModal] = useState(false);

  function toProperCase(value) {
    if (
      [
        "NaN",
        "NAN",
        "Nan",
        "Null",
        "",
        "--",
        "- -",
        " -  - ",
        "N/A",
        -1,
        "-1",
        null,
      ].includes(value)
    ) {
      return "N/A";
    }

    return value;
  }

  useEffect(() => {
    setCategories({
      Assigned_Routes: [],

      Visits: [],
    });

    setColumnMapping({
      Visits: {
        visit_start_time_stamp: "Start Date ",
        visit_end_time_stamp: "End Date",
        visit_status: "Status",
      },

      Assigned_Routes: {
        route_comment: "Route Comment",
        route_status: "Task Status",
        route_monthly_repeated: "Monthly Repeated",
      },
    });
  }, []);
  const [details, loading, fetchDetails] = useDetails(userid, activeTab);

  function formatDate(dateObject) {
    const { seconds } = dateObject;
    const date = new Date(seconds * 1000);
    return date.toLocaleString();
  }

  const handleTabClick = (category) => {
    setActiveTab(category);
  };
  function renderDetails() {
    const customColumns = columnMapping[activeTab];

    return (
      <Container>
        <Row>
          {details.map((detail, index) => (
            <Col sm={4} key={index} className="mb-3">
              <Card>
                {" "}
                <Card.Body>
                  {Object.entries(customColumns).map(([key, displayName]) => {
                    if (!detail.hasOwnProperty(key)) {
                      return null;
                    }
                    let formattedValue = detail[key];
                    if (
                      (key === "visit_start_time_stamp" ||
                        key === "visit_end_time_stamp  ") &&
                      formattedValue &&
                      formattedValue.seconds
                    ) {
                      formattedValue = formatDate(formattedValue);
                    }

                    return (
                      <div key={key} className="mt-3">
                        <Card.Title>{displayName}:</Card.Title>
                        <Card.Text
                          title={
                            typeof formattedValue === "boolean"
                              ? formattedValue.toString()
                              : formattedValue
                          }
                        >
                          {typeof formattedValue === "boolean"
                            ? formattedValue
                              ? "Yes"
                              : "No"
                            : toProperCase(formattedValue)}
                        </Card.Text>
                      </div>
                    );
                  })}
                  {activeTab === "Assigned_Routes" && (
                    <div className="mt-3 d-flex justify-content-between">
                      <TaskEditModal
                        route_id={detail.route_id}
                        user_id={detail.user_id}
                        fetchDetails={fetchDetails}
                      />
                      <TaskDeleteModal
                        route_id={detail.route_id}
                        user_id={detail.user_id}
                        fetchDetails={fetchDetails}
                      />
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid style={{ minHeight: "100vh" }} className="mt-5">
      <h5 className="username-title">{username}</h5>{" "}
      <Row>
        <Col md={4} className=" border p-3  d-sm-block">
          <Nav className="flex-column custom-tabs mb-3">
            {Object.keys(categories).map((category) => (
              <Nav.Item key={`tab-${category}`}>
                <Nav.Link
                  className={`border mb-3 text-center h3 bold text-capitalize ${
                    activeTab === category ? "active" : ""
                  }`}
                  aria-selected={activeTab === category}
                  onClick={() => handleTabClick(category)}
                  disabled={activeTab === category}
                >
                  {category}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Col>
        <Col md={8} className="border">
          {loading ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100px",
                }}
              >
                <CircularProgress sx={{ color: "black" }} />
              </div>
            </>
          ) : details.length === 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
              }}
            >
              <p>
                No Data for <strong>{username}</strong> in this Category
              </p>
            </div>
          ) : activeTab === "Products" && details.length > 0 ? (
            <ProductCards users={details} />
          ) : (
            activeTab !== "Products" && <Row>{renderDetails()}</Row>
          )}
        </Col>
        <Col md={12} className="text-center mt-5 ">
          <TaskCreationModal
            show={showModal}
            onHide={() => setShowModal(false)}
            profileId={userid}
            fetchDetails={fetchDetails}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default UserProfile;
