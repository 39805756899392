import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { toast } from "react-toastify";
import PasswordCreationForm from "../resetPasswordConfirmation";

function OtpVerification({ onLogin, onResendCode, email }) {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [timer, setTimer] = useState(600); // 10 minutes in seconds
  const [isOtpVerified, setIsOtpVerified] = useState(false);

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => clearInterval(countdown); // Cleanup timer on component unmount
  }, []);

  const handleChange = (element, index) => {
    const value = element.value.replace(/[^0-9]/g, ""); // Only allow digits
    if (value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value && index < 5) {
        // Move to the next input
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handleVerify = () => {
    const enteredOtp = otp.join("");
    const storedOtp = Cookies.get("Price_App_OTP"); // Retrieve OTP from cookies

    if (enteredOtp === storedOtp) {
      toast.success("OTP verified successfully");
      setIsOtpVerified(true);
    } else {
      toast.error("The OTP you entered is incorrect. Please try again.");
    }
  };

  const handleResendCode = () => {
    onResendCode();
    setTimer(600); // Reset timer to 10 minutes
  };

  const formatTime = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div className="container" style={{ width: "80%", marginLeft: "auto" }}>
      {isOtpVerified ? (
        <PasswordCreationForm onLogin={onLogin} email={email} />
      ) : (
        <>
          {" "}
          <h3
            style={{
              fontFamily: "Poppins",
              fontWeight: "700",
              fontSize: "24px",
              lineHeight: "34px",
              color: "rgba(87, 110, 128, 1)",
              textAlign: "left",
              marginBottom: "1rem",
            }}
          >
            Verify Your Identity
          </h3>
          <p
            style={{
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "34px",
              color: "rgba(79, 94, 119, 1)",
              textAlign: "left",
            }}
          >
            Please enter the 6-digit OTP code that we have sent to your
            registered email This code is valid for a 10 minutes only.
          </p>
          <div
            className="otp-input-container my-3"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            {otp.map((digit, index) => (
              <input
                key={index}
                id={`otp-input-${index}`}
                type="text"
                className="otp-input"
                value={digit}
                maxLength="1"
                onChange={(e) => handleChange(e.target, index)}
                onFocus={(e) => e.target.select()}
                style={{
                  width: "48px",
                  height: "48px",
                  padding: "10px",
                  borderRadius: "4px ",
                  border: "1px solid rgba(19, 67, 149, 1)",
                  boxShadow: "0px 0px 4px 0px rgba(19, 67, 149, 1)",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "34px",
                  textAlign: "center",
                  color: "rgba(29, 28, 35, 1)",
                  opacity: 1,
                }}
              />
            ))}
          </div>
          <p className="instructions">
            <span
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "25px",
                textAlign: "left",
                color: "rgba(79, 94, 119, 1)",
              }}
            >
              <HelpOutlineOutlinedIcon sx={{ fontSize: "18px" }} /> If you did
              not receive the OTP, please check your spam/junk folder or <br />{" "}
              resend code after 10 minutes
            </span>
            <br />
            <span
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "25px",
                textAlign: "left",
                color: "rgba(79, 94, 119, 1)",
              }}
            >
              <HelpOutlineOutlinedIcon sx={{ fontSize: "18px" }} /> Ensure your
              email address is correctly entered for a smooth verification{" "}
              <br /> process.
            </span>
          </p>
          <button
            className={` mt-3 loginButton inline-block leading-normal w-full transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg ${
              otp.join("").length === 6 ? "noErrorsButton" : "errorsButton"
            }`}
            type="submit"
            onClick={handleVerify}
            disabled={otp.join("").length !== 6} // Disable button unless OTP is 6 digits
          >
            <h5 className="text-md button-txt"> Verify</h5>
          </button>
          <button
            className={`mt-2 loginButton inline-block leading-normal w-full transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg ${
              timer === 0 ? "noErrorsButton" : "resendInactiveButton"
            }`}
            onClick={handleResendCode}
            disabled={timer !== 0} // Enable button only when timer reaches 0
          >
            <h5 className="text-md button-txt"> Resend Code</h5>
          </button>
          <p
            className="text-center mt-2"
            style={{
              fontFamily: "Poppins, sans-serif",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "22px",
              color: "rgba(41, 52, 60, 1)",
            }}
          >
            Time remaining <strong>{formatTime()}</strong>
          </p>
        </>
      )}
    </div>
  );
}

export default OtpVerification;
