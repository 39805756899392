import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import smjLogo from "../../components/images/SMJ-logo_FAW@2x.png";

import MailIcon from "@mui/icons-material/Mail";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import "../../components/styles/loginpage.css";
import { useUser } from "../../components/context/userAuth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  login,
  requestOtpForMultiAuthFactor,
} from "../../services/users/usersApis";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import LockIcon from "@mui/icons-material/Lock";
import ForgotPasswordForm from "../../components/ForgetPasswordOtp";
import OtpSentModal from "../../components/emailmodal";
import LoginOtpVerfication from "../../components/otpCheckLogin";

const initialValues = {
  email: "",
  user_password: "",
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  user_password: Yup.string().required("Password is required"),
});

function LoginPage() {
  const { userlogin } = useUser();

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [showOtpVerification, setShowOtpVerification] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const sliderContents = [
    {
      header: "Seamless Collaboration",
      text: "Effortlessly work together with your team in real-time.",
    },
    {
      header: "Enhanced Productivity",
      text: "Streamline your workflows to increase efficiency.",
    },
    {
      header: "Real-Time Analytics",
      text: "Get instant insights into your sales metrics and performance.",
    },
  ];
  const history = useHistory();
  const [loginloading, setloginLoading] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSlide((current) => (current + 1) % sliderContents.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);
  /////////// to check if there is a user already logged in to redirect him directly to the default page
  useEffect(() => {
    const token = Cookies.get("Price_App_Token");
    if (token) {
      history.push(`/`);
    }
  }, [history]);

  const renderLoginForm = () => (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, formikHelpers) =>
        handleFormSubmit(values, formikHelpers)
      }
    >
      {({ getFieldProps, errors, touched, values }) => (
        <Form style={{ width: "100%", maxWidth: "75%" }}>
          <div className="flex flex-col">
            <TextField
              id="outlined-basic"
              variant="outlined"
              label="Email"
              type="email"
              autoComplete="Email"
              {...getFieldProps("email")}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailIcon style={{ color: "rgba(19, 67, 149, 1)" }} />
                  </InputAdornment>
                ),
              }}
              className="mt-1 mb-4 rounded-lg"
            />
          </div>

          <div className="flex flex-col">
            <TextField
              variant="outlined"
              type={passwordVisible ? "text" : "password"}
              autoComplete="Password"
              label="Password"
              {...getFieldProps("user_password")}
              error={touched.user_password && Boolean(errors.user_password)}
              helperText={touched.user_password && errors.user_password}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon style={{ color: "rgba(19, 67, 149, 1)" }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePasswordVisibility}
                      edge="end"
                    >
                      {passwordVisible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              className="mb-4"
            />
          </div>
          <div className="text-right mb-4">
            <p
              onClick={() => setIsForgotPassword(true)}
              style={{
                fontFamily: "Poppins",
                fontSize: "18px",
                fontWeight: "600",
                lineHeight: "25px",
                textAlign: "right",
                color: "rgba(23, 71, 153, 1)",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              Forgot Password?
            </p>
          </div>
          <div className="text-center lg:text-left">
            <button
              type="submit"
              className={`loginButton inline-block  leading-normal w-full transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg ${
                Object.keys(errors).length === 0 &&
                Object.values(values).every((val) => val !== "") // Check if all values are not empty
                  ? "noErrorsButton"
                  : "errorsButton"
              }`}
            >
              <h5 className="text-md button-txt">
                {loginloading ? "Loading..." : "Login"}
              </h5>
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );

  ///// Submitting the login form to authenticate the user
  const handleHideModal = () => {
    setShowModal(false);
    setShowOtpVerification(true);
  };
  const handleResendOtp = async () => {
    await requestOtpForMultiAuthFactor(email);
  };
  const handleFormSubmit = async (values, { resetForm }) => {
    setloginLoading(true);
    try {
      const response = await login(values);
      if (response.otpRequired) {
        setEmail(values.email);
        setShowModal(true);
      } else if (response.token) {
        userlogin(response.token);

        const { user_name } = jwtDecode(response.token);
        toast.success(`Welcome Mr. ${user_name}`);
        history.push("/");
      } else {
        toast.error(
          response.response.data.message ||
            "Incorrect username or password. Please try again, if you require support please contact your administrator."
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setloginLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          m: "1%",
          mt: "1%",
          mb: 0,
          height: "calc(100% - 1%)",
          overflowX: "hidden",
        }}
      >
        <Grid container spacing={2} sx={{ margin: "0px", height: "100%" }}>
          <Grid
            item
            xs={12}
            md={6}
            className="backgroundDiv"
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              color: "#fff",
            }}
          >
            {/* <Box className="overlay"></Box> */}
            <div style={{ textAlign: "center" }}>
              <p className="header">
                Welcome To SM Jaleel <br /> Admin Dashboard
              </p>
              {/* <p className="header2 mt-2">Your dashboard to manage sales rep.</p> */}
            </div>
            <div className="mt-5 slidercontainer">
              {sliderContents[activeSlide] && (
                <div className="transition-opacity duration-700 ease-in-out text-center">
                  <p
                    className="sliderHeader"
                    style={{ minWidth: "200px", textAlign: "center" }}
                  >
                    {sliderContents[activeSlide].header}
                  </p>
                  <p
                    className="sliderText mt-2"
                    style={{
                      maxWidth: "300px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      textAlign: "center",
                    }}
                  >
                    {sliderContents[activeSlide].text}
                  </p>
                </div>
              )}

              {/* Bullet points */}
              <div className="flex justify-center mt-2">
                {sliderContents.map((_, index) => (
                  <div
                    key={index}
                    className={`${
                      index === activeSlide ? "activeBullet" : "bullet"
                    } ms-1 transition-opacity duration-700 ease-in-out`}
                  ></div>
                ))}
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <Box
              sx={{
                width: "75%",
                mb: 3,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <img
                src={smjLogo}
                alt="Logo"
                style={{
                  height: "75%",
                }}
                className="logo"
              />
            </Box>
            {isForgotPassword ? (
              <ForgotPasswordForm
                onBackToLogin={() => setIsForgotPassword(false)}
              />
            ) : showOtpVerification ? (
              <LoginOtpVerfication
                onResendCode={handleResendOtp}
                email={email}
              />
            ) : (
              renderLoginForm()
            )}
          </Grid>
        </Grid>
      </Box>
      <OtpSentModal
        show={showModal}
        onHide={handleHideModal}
        onResend={handleResendOtp}
        email={email}
      />
    </>
  );
}

export default LoginPage;
