import {
  Box,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import * as yup from "yup";
import { Input, Ripple, initTWE } from "tw-elements";
import PersonIcon from "@mui/icons-material/Person";
import CloseIcon from "@mui/icons-material/Close";

import { Form, Formik } from "formik";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import BadgeIcon from "@mui/icons-material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LockIcon from "@mui/icons-material/Lock";
import IconButton from "@mui/material/IconButton";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";

import { useState } from "react";
import { toast } from "react-toastify";
import { useSalesRepContext } from "../../context/salesfetch";
import { AddUser } from "../../../services/users/usersApis";
import { access_type, cities, roles } from "../../../data/data";
import CancelButton from "../../buttons/cancelButton";
import SubmitButton from "../../buttons/submitFormButton";

initTWE({ Input, Ripple });
const initialValues = {
  user_email: "",
  user_name: "",
  user_username: "",
  user_password: "",
  check_password: "",
  user_city: "",
  user_position: "",
  user_access_type: "",
  user_phone_no: "",
  user_access_dashboard: "",
  user_access_mobile_app: "",
  user_country: "",
};
const checkoutSchema = yup.object().shape({
  user_email: yup
    .string()
    .email("Please enter a valid email address") // Customized error message for invalid email
    .required("Email is required") // Error message for the required condition
    .max(100, "Email must be at most 100 characters long"),
  user_name: yup
    .string()
    .max(40, "Full Name must be at most 40 characters")
    .required("Full Name is required"),
  user_username: yup
    .string()
    .max(40, "User Name must be at most 40 characters")
    .required("User Name is required"),
  user_password: yup
    .string()
    .min(6, "Password must be at least 6 characters long")
    .required("Password is required"),
  check_password: yup
    .string()
    .required("Please confirm your password")
    .oneOf([yup.ref("user_password"), null], "Passwords must match"),
  user_city: yup.string().required("Please select a city"),
  user_position: yup.string().required("Position is required"),
  user_access_type: yup.string().required("Position is required"),
  user_phone_no: yup
    .string()
    .max(20, "User Phone Number must be at most 20 digits")
    .required("Phone Number is required"),
});

export default function CreateAccountForm({ onClose }) {
  const { toggleUpdateFlag } = useSalesRepContext();

  const [addinguserloading, setAddingUserLoading] = useState(false);
  const [checkpasswordVisible, setCheckPasswordVisible] = useState(false);
  const toggleCheckPasswordVisibility = () => {
    setCheckPasswordVisible(!checkpasswordVisible);
  };
  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleFormSubmit = async (values, { resetForm }) => {
    setAddingUserLoading(true);
    switch (values["user_access_type"]) {
      case "Dashboard":
        values["user_access_dashboard"] = true;
        values["user_access_mobile_app"] = false;
        break;
      case "Mobile Application":
        values["user_access_dashboard"] = false;
        values["user_access_mobile_app"] = true;
        break;
      default:
        values["user_access_dashboard"] = true;
        values["user_access_mobile_app"] = true;
    }
    values["user_country"] = jwtDecode(
      Cookies.get("Price_App_Token")
    ).user_country;
    try {
      const { message } = await AddUser(values);
      if (message === "User added successfully") {
        toast.success(message);
        toggleUpdateFlag();
        onClose();
      } else {
        toast.error(message);
      }
    } catch (e) {
      toast.error("Error adding user please try again");
    } finally {
      setAddingUserLoading(false);
    }
  };

  return (
    <Box sx={{ width: "100%" }} autoComplete="off">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          boxShadow: "0px 3px 6px #A8A8A81F",
        }}
        autoComplete="off"
        className="p-4 mb-5"
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "24px",
            letterSpacing: "-0.22px",
            color: " rgba(75, 75, 75, 1)",
          }}
        >
          Add a new User
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Formik
        initialValues={initialValues}
        validationSchema={checkoutSchema}
        onSubmit={(values, formikHelpers) =>
          handleFormSubmit(values, formikHelpers)
        }
      >
        {({ getFieldProps, touched, errors }) => (
          <Form className="px-4 ">
            <div className="flex mb-2">
              <div className="flex  w-50 flex-col mr-4">
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                  htmlFor="Full Name"
                  className="mb-2 input-label"
                >
                  Full Name *
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Enter Employee Full Name"
                  type="text"
                  autoComplete="Full Name"
                  required
                  {...getFieldProps("user_name")}
                  error={touched.user_name && Boolean(errors.user_name)}
                  helperText={touched.user_name && errors.user_name}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon style={{ color: "rgba(19, 67, 149, 1)" }} />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1 mb-4 rounded-lg"
                />
              </div>
              <div className="flex w-50 flex-col">
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                  htmlFor="username"
                  className="mb-2 input-label"
                >
                  Username *
                </label>
                <TextField
                  id="outlined-basic1"
                  variant="outlined"
                  placeholder="Enter Employee Id"
                  type="text"
                  autoComplete="User Name"
                  required
                  {...getFieldProps("user_username")}
                  error={touched.user_username && Boolean(errors.user_username)}
                  helperText={touched.user_username && errors.user_username}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon style={{ color: "rgba(19, 67, 149, 1)" }} />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1 mb-4 rounded-lg"
                />
              </div>
            </div>
            <div className="flex mb-2 flex-col">
              <label
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
                htmlFor="City"
                className="mb-2 input-label"
              >
                City *
              </label>
              <TextField
                id="outlined-basic2"
                variant="outlined"
                select
                autoComplete="City"
                required
                {...getFieldProps("user_city")}
                error={touched.user_city && Boolean(errors.user_city)}
                helperText={touched.user_city && errors.user_city}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationCityIcon
                        style={{ color: "rgba(19, 67, 149, 1)" }}
                      />
                    </InputAdornment>
                  ),
                }}
                className="mt-1 mb-4 rounded-lg"
              >
                {cities.sort().map((country, index) => (
                  <MenuItem key={index} value={country["value"]}>
                    {country["name"]}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="flex mb-2 ">
              <div className="flex w-50 flex-col mr-4">
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                  htmlFor="role"
                  className="mb-2 input-label"
                >
                  Role *
                </label>
                <TextField
                  id="outlined-basic3"
                  variant="outlined"
                  autoComplete="Role"
                  select
                  required
                  {...getFieldProps("user_position")}
                  error={touched.user_position && Boolean(errors.user_position)}
                  helperText={touched.user_position && errors.user_position}
                  className="mt-1 mb-4 rounded-lg"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <BadgeIcon style={{ color: "rgba(19, 67, 149, 1)" }} />
                      </InputAdornment>
                    ),
                  }}
                >
                  {roles.sort().map((country, index) => (
                    <MenuItem key={index} value={country}>
                      {country}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="flex  w-50 flex-col">
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                  htmlFor="access"
                  className="mb-2 input-label"
                >
                  Access Type *
                </label>
                <TextField
                  id="outlined-basic4"
                  variant="outlined"
                  select
                  autoComplete="Access Type"
                  required
                  {...getFieldProps("user_access_type")}
                  error={
                    touched.user_access_type && Boolean(errors.user_access_type)
                  }
                  helperText={
                    touched.user_access_type && errors.user_access_type
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <VpnKeyIcon style={{ color: "rgba(19, 67, 149, 1)" }} />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1 mb-4 rounded-lg"
                >
                  {access_type.map((country, index) => (
                    <MenuItem key={index} value={country}>
                      {country}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
            <div className="flex mb-2 ">
              <div className="flex w-50 flex-col mr-4">
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                  htmlFor="Email"
                  className="mb-2 input-label"
                >
                  Email *
                </label>
                <TextField
                  id="outlined-basic5"
                  variant="outlined"
                  type="email"
                  autoComplete="Email"
                  placeholder="example@example.com"
                  {...getFieldProps("user_email")}
                  error={touched.user_email && Boolean(errors.user_email)}
                  helperText={touched.user_email && errors.user_email}
                  className="mt-1 mb-4 rounded-lg"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailIcon style={{ color: "rgba(19, 67, 149, 1)" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="flex  w-50 flex-col">
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                  htmlFor="Phone Number"
                  className="mb-2 input-label"
                >
                  Phone Number *
                </label>
                <TextField
                  id="outlined-basic6"
                  variant="outlined"
                  type="text"
                  placeholder="Enter a phone number"
                  autoComplete="Phone Number"
                  required
                  {...getFieldProps("user_phone_no")}
                  error={touched.user_phone_no && Boolean(errors.user_phone_no)}
                  helperText={touched.user_phone_no && errors.user_phone_no}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ContactPhoneIcon
                          style={{ color: "rgba(19, 67, 149, 1)" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1 mb-4 rounded-lg"
                />
              </div>
            </div>
            <div className="flex mb-2 ">
              <div className="flex w-50 flex-col mr-4">
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                  htmlFor="Password"
                  className="mb-2 input-label"
                >
                  Password *
                </label>
                <TextField
                  id="outlined-basic7"
                  variant="outlined"
                  type={passwordVisible ? "text" : "password"}
                  autoComplete="Password"
                  required
                  placeholder="User Password"
                  {...getFieldProps("user_password")}
                  error={touched.user_password && Boolean(errors.user_password)}
                  helperText={touched.user_password && errors.user_password}
                  className="mt-1 mb-4 rounded-lg"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon style={{ color: "rgba(19, 67, 149, 1)" }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={togglePasswordVisibility}
                          edge="end"
                        >
                          {passwordVisible ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="flex  w-50 flex-col">
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                  htmlFor="Check Password"
                  className="mb-2 input-label"
                >
                  Check Password *
                </label>
                <TextField
                  id="outlined-basic8"
                  variant="outlined"
                  placeholder="User Password"
                  type={checkpasswordVisible ? "text" : "password"}
                  autoComplete="Check Password"
                  required
                  {...getFieldProps("check_password")}
                  error={
                    touched.check_password && Boolean(errors.check_password)
                  }
                  helperText={touched.check_password && errors.check_password}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon style={{ color: "rgba(19, 67, 149, 1)" }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle Check password visibility"
                          onClick={toggleCheckPasswordVisibility}
                          edge="end"
                        >
                          {checkpasswordVisible ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1 mb-4 rounded-lg"
                />
              </div>
            </div>

            <div className="text-end my-3">
              {" "}
              <CancelButton onClose={onClose} />
              <SubmitButton
                disabled={addinguserloading}
                loading={addinguserloading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
