// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal .modal-content {
  width: 600px !important;
  height: 500px !important;
}

.custom-modal-success .modal-content {
  width: 800px !important;
  height: 350px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/styles/emailModal.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,wBAAwB;AAC1B;;AAEA;EACE,uBAAuB;EACvB,wBAAwB;AAC1B","sourcesContent":[".custom-modal .modal-content {\n  width: 600px !important;\n  height: 500px !important;\n}\n\n.custom-modal-success .modal-content {\n  width: 800px !important;\n  height: 350px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
