import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Drawer, Grid, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SearchBar from "../../components/galleryFilters";
import { fetchVisitsData } from "../../services/gallery/apicalls";
import { DataGrid } from "@mui/x-data-grid";
import MapModal from "../../components/mapModal";
import dayjs from "dayjs";
import VisitProfileDrawer from "../../components/Drawers/visitProfileDrawer";

function GalleryVisits() {
  const [visits, setVisits] = useState([]);
  const [visitProfile, setVisitProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  ///// for server side pagination
  // const [paginationModel, setPaginationModel] = React.useState({
  //   page: 0,
  //   pageSize: 20,
  // });
  // const [totalVisits, setTotalVisits] = useState(0);

  const [data, setSearchData] = useState({
    user_country: "",
    user_name: "",
    customer_name: "",
    visit_date: null,
    sku_brand: "",
    sku_full_name: "",
  });
  const [isProfileDraweOpen, setIsProfileDrawerOpen] = useState(false);
  const [profileId, setProfileId] = useState(null);

  ///// config to show the map modal
  const [showModal, setShowModal] = useState(false);
  const [mapCoords, setMapCoords] = useState([0, 0]);
  const handleShowMap = (coords) => {
    const parsedCoords = coords.split(",").map(Number);
    setMapCoords(parsedCoords);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  /////

  const toggleProfileDrawer = () => {
    setIsProfileDrawerOpen(!isProfileDraweOpen);
  };

  // Handling Openning Users Profile

  useEffect(() => {
    if (data.user_country) {
      fetchVisitsData(
        setIsLoading,
        setVisits,
        data
        // paginationModel.page,
        // paginationModel.pageSize,
        // setTotalVisits
      );
    }
  }, [data.user_country]);

  const columns = [
    {
      field: "rowNumber",
      headerName: "#",
      width: 80,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "uniqueId",
      headerName: "Visit Id",
      width: 150,
      renderCell: (params) => (
        <Box
          onClick={() => {
            setProfileId(params.row.visit_id);
            setVisitProfile(params.row);
            toggleProfileDrawer();
          }}
          sx={{
            color: "rgba(58, 67, 68, 1)",
            fontWeight: "600",
            fontSize: "12px",
            lineHeight: "20px",
            letterSpacing: "-0.14px",
            fontFamily: "Poppins",
            padding: "1rem",
            cursor: "pointer",
          }}
        >
          {params.value}
        </Box>
      ),
    },
    {
      field: "user_name",
      headerName: "Submitter",
      width: 150,
    },

    {
      field: "customer_name",
      headerName: "Store Name",
      width: 300,
    },

    {
      field: "visit_date",
      headerName: "Visit Date",
      width: 200,
      renderCell: (params) => (
        <span>{dayjs(params.value).format("MM/DD/YYYY")}</span>
      ),
    },
    {
      field: "visit_location",
      headerName: "Location",
      width: 170,
      renderCell: (params) => (
        <button
          className="btn btn-link"
          style={{
            fontFamily: "Poppins",
            color: "rgba(19, 67, 149, 1)",
            fontSize: "12px",
            fontWeight: "600",
            lineHeight: "18px",
          }}
          onClick={() => handleShowMap(params.value)}
        >
          <LocationOnIcon /> Show on map
        </button>
      ),
    },
  ];

  const handleSearch = (searchData) => {
    setSearchData((prevData) => ({ ...prevData, ...searchData }));
    fetchVisitsData(
      setIsLoading,
      setVisits,
      { ...data, ...searchData }
      // paginationModel.page,
      // paginationModel.pageSize,
      // setTotalVisits
    );
  };

  return (
    <div className="container " style={{ height: "95%" }}>
      <Grid className="h-full" item xs={12}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: 45,
            marginBottom: "1rem",
            marginTop: "2rem",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "24px",
              lineHeight: "36px",
              fontWeight: "500",
              paddingLeft: "0.5rem",
              borderLeft: "5px solid  #134394 ",
              height: "100%",
              display: "flex",
              alignItems: "center",
              borderRadius: "2px 0px 0px 2px",
            }}
          >
            Gallery Management
          </Typography>
          <Drawer
            anchor="right"
            open={isProfileDraweOpen}
            onClose={toggleProfileDrawer}
            sx={{
              "& .MuiDrawer-paper": { width: "50%", boxSizing: "border-box" },
            }}
          >
            <VisitProfileDrawer
              visitId={profileId}
              onClose={toggleProfileDrawer}
              visit={visitProfile}
            />
          </Drawer>
        </Box>
        <SearchBar onSearch={handleSearch} initialData={data} />
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Box
            height="80%"
            sx={{
              "& .MuiDataGrid-row": {
                backgroundColor: "white",
              },
              "& .MuiDataGrid-root": {
                color: "#424242",
                borderRadius: "10px",
              },
              "& .MuiDataGrid-cell": {
                color: "#424242",
                fontSize: "14px",
                borderRight: "1px solid #e0e0e0",
                justifyContent: "center !important",
              },
              "& .MuiDataGrid-cellContent": {
                fontFamily: "Poppins",
                color: "rgba(58, 67, 68, 1)",
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: "20px",
                letterSpacing: "-0.14px",
                padding: "1rem",
                cursor: "pointer",
              },
              "& .MuiDataGrid-columnHeaderTitleContainer": {
                padding: "1rem",
                justifyContent: "center",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontFamily: "Poppins",
                fontSize: "12px",
                fontWeight: "600",
                lineHeight: "18px",
                letterSpacing: "-0.01em",
                textAlign: "left",
                color: "rgba(97, 129, 184, 1)", // Ensure text color is visible against the background
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "rgba(238, 241, 247, 1)", // Background color for headers
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: "#fafafa",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "1px solid #e0e0e0",
                backgroundColor: "#f5f5f5",
              },
              "& .MuiCheckbox-root": {
                color: `#757575 !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `#424242 !important`,
              },
              "& .MuiDataGrid-footerContainer ": {
                background: "white",
              },
              "& .MuiDataGrid-columnHeader": {
                height: "50px",
                padding: "10px 16px", // Padding for headers
                gap: "10px",
                border: "0px solid",
                borderRight: "1px solid #e0e0e0",
              },
            }}
          >
            {/* <DataGrid
              rows={visits}
              columns={columns}
              rowHeight={70}
              rowCount={totalVisits}
              disableSelectionOnClick
              pageSizeOptions={[20, 40, 60, 80, 100]}
              paginationMode="server"
              page={paginationModel.page}
              pageSize={paginationModel.pageSize}
              onPaginationModelChange={(model) => setPaginationModel(model)}
              loading={isLoading} // Show a loading indicator while fetching data
              initialState={{
                pagination: { paginationModel: { pageSize: 20 } },
              }}
            /> */}
            <DataGrid
              rows={visits}
              columns={columns}
              rowHeight={70}
              disableSelectionOnClick
              pageSizeOptions={[20, 40, 60, 80, 100]}
              initialState={{
                pagination: { paginationModel: { pageSize: 20 } },
              }}
            />
          </Box>
        )}
      </Grid>
      <MapModal show={showModal} onHide={handleCloseModal} coords={mapCoords} />
    </div>
  );
}

export default GalleryVisits;
