// src/firebase.js

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyAMbPAJRnb7yLk6Oh0qOYsuAuU1yTtZDi4",
  authDomain: "priceappsurvey.firebaseapp.com",
  projectId: "priceappsurvey",
  storageBucket: "priceappsurvey.appspot.com",
  messagingSenderId: "1004644290234",
  appId: "1:1004644290234:web:8d5751e1eece07a26bfc83",
  measurementId: "G-1P9XBQMKQV",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore();
const functions = getFunctions(app);

export { db, functions };
