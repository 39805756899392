import {
  Box,
  CircularProgress,
  Typography,
  List,
  ListItem,
  ListItemText,
  Grid,
  IconButton,
  ListItemIcon,
  Divider,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { surveyQuestionsData } from "../../../services/surveys/apicalls";
import { styled } from "@mui/system";
import ImageViewer from "react-simple-image-viewer";
import DownloadSurveyButton from "../../buttons/downloadButton";
import CancelButton from "../../buttons/cancelButton";
import { toast } from "react-toastify";

export default function SurveyQuestionDrawer({ surveyId, onClose, survey }) {
  const [questionsData, setQuestionsData] = useState(null); // State to store user data
  const [dataLoading, setDataLoading] = useState(true);
  const [currentImage, setCurrentImage] = useState(null); // For image viewing
  let lastSubtitle = "";

  useEffect(() => {
    const fetchData = async () => {
      const result = await surveyQuestionsData(surveyId, setDataLoading);
      if (result.questions) {
        setQuestionsData(result.questions); // Store the data in state
      } else if (result.message) {
        toast.error(result.message); // Display error message
      }
    };

    fetchData();
  }, [surveyId]);
  // Function to handle image click
  const handleImageClick = (url) => {
    setCurrentImage(url);
  };

  // Function to handle image viewer close
  const closeImageViewer = () => {
    setCurrentImage(null);
  };
  if (dataLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <Box sx={{ width: "100%" }} autoComplete="off">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          boxShadow: "0px 3px 6px #A8A8A81F",
        }}
        autoComplete="off"
        className="p-4 mb-3"
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "30px",
            letterSpacing: "-0.22px",
            color: " #000000",
          }}
        >
          Survey Details
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <List sx={{ padding: 2 }}>
        {questionsData !== null &&
          questionsData.map((question, index) => {
            const isSubtitle =
              question.survey_ques_subtitle &&
              question.survey_ques_subtitle.toLowerCase() !== "nan";
            const showLongDivider =
              isSubtitle && question.survey_ques_subtitle !== lastSubtitle;

            if (showLongDivider) {
              lastSubtitle = question.survey_ques_subtitle;
            }

            return (
              <React.Fragment key={index}>
                {showLongDivider && (
                  <>
                    <Divider sx={{ marginY: 4, background: "Black" }} />
                    <Typography
                      variant="h6"
                      color="primary"
                      sx={{ marginBottom: 1 }}
                    >
                      {question.survey_ques_subtitle}
                    </Typography>
                  </>
                )}
                <ListItem divider={false} sx={{ paddingY: 2 }}>
                  <Box sx={{ width: "100%" }}>
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "500", fontFamily: "Poppins" }}
                    >
                      {index + 1}. {question.survey_ques_text}
                    </Typography>
                    {question.survey_ques_type === 7 ? (
                      <Grid container spacing={1} sx={{ marginTop: 1 }}>
                        {question.survey_answer.split(",").length === 1 &&
                        question.survey_answer.trim() === "" ? (
                          <Grid item xs={12} style={{ textAlign: "center" }}>
                            <p
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                color: "gray",
                                paddingLeft: "1rem",
                                textAlign: "left",
                              }}
                            >
                              No Image Provided
                            </p>
                          </Grid>
                        ) : (
                          question.survey_answer
                            .split(",")
                            .map((url, imgIndex) => {
                              const trimmedUrl = url.trim();
                              return trimmedUrl ? (
                                <Grid item xs={12} sm={6} md={4} key={imgIndex}>
                                  <img
                                    src={trimmedUrl}
                                    alt={`Question ${index + 1}`}
                                    style={{
                                      width: "100%",
                                      cursor: "pointer",
                                      maxHeight: "150px",
                                      objectFit: "cover",
                                    }}
                                    loading="lazy"
                                    onClick={() => handleImageClick(trimmedUrl)}
                                  />
                                </Grid>
                              ) : null; // Return null if URL is empty after trim, effectively skipping it
                            })
                        )}
                      </Grid>
                    ) : [5, 8, 10, 11].includes(question.survey_ques_type) ? (
                      <List component="ul" sx={{ paddingLeft: 3 }}>
                        {question.survey_answer
                          .split(",")
                          .map((choice, choiceIndex) => (
                            <ListItem
                              key={choiceIndex}
                              sx={{
                                fontFamily: "Poppins",
                                fontWeight: "400",
                                paddingY: 0.5,
                              }}
                            >
                              <ListItemIcon>
                                <FiberManualRecordIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText primary={choice.trim()} />
                            </ListItem>
                          ))}
                      </List>
                    ) : (
                      <Typography
                        variant="body2"
                        sx={{
                          marginTop: 1,
                          paddingLeft: 3,
                          fontFamily: "Poppins",
                          fontWeight: "400",
                        }}
                      >
                        {question.survey_answer}
                      </Typography>
                    )}
                  </Box>
                </ListItem>

                <Divider
                  sx={{
                    marginY: 1,
                    width: "50%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              </React.Fragment>
            );
          })}
      </List>
      <div className="text-end ">
        {" "}
        <CancelButton onClose={onClose} />
        <DownloadSurveyButton questions={questionsData} survey={survey} />
      </div>
      {/* Image Viewer */}
      {currentImage && (
        <ImageViewer
          src={[currentImage]}
          currentIndex={0}
          onClose={closeImageViewer}
          closeOnClickOutside={true}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain", // Ensures the image scales to fit within the viewer
          }}
        />
      )}{" "}
    </Box>
  );
}
