import React, { useState, useEffect } from "react";
import { Formik, Form, FieldArray } from "formik";
import {
  TextField,
  MenuItem,
  CircularProgress,
  InputAdornment,
  IconButton,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import "../styles/loginpage.css";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import LocationCityIcon from "@mui/icons-material/LocationCity";
import * as yup from "yup";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

import {
  EditProductApi,
  fetchBrandProducts,
  fetchSkuDetails,
} from "../../services/allbrands/apicalls";
import { useBrandsContext } from "../context/brandsfetch";
import { skus_sizes, status } from "../../data/data";
import CancelButton from "../buttons/cancelButton";
import SubmitButton from "../buttons/submitFormButton";

const caseSchema = yup.object().shape({
  units: yup.number().required("Units are required"),
  price: yup.number().required("Price is required"),
  status: yup.string().oneOf(["old", "new", "deleted"]).required(),
  price_id: yup.string(),
  id: yup.string(),
});

const productSchema = yup.object().shape({
  sku_full_name: yup
    .string()
    .max(40, "Product Name must be at most 40 characters")
    .required("Product Full Name is required"),
  sku_brand: yup.string().required("Product Brand is required"),
  sku_category: yup
    .string()
    .max(40, "Product Category  must be at most 40 characters")
    .required("Product Ctategory is required"),
  sku_flavor: yup
    .string()
    .max(20, "Product Flavor  must be at most 40 characters")
    .required("Product Flavor is required"),
  sku_distributor: yup.string().required("Product Distributor is required"),
  sku_size: yup.string().required("Product Size is required"),
  sku_status: yup.string().required("Product Status is required"),
  dtt_cases: yup.array().of(caseSchema),
  key_a_cases: yup.array().of(caseSchema),
  key_b_cases: yup.array().of(caseSchema),
  chains_cases: yup.array().of(caseSchema),
});
const renderCaseConfigurations = (fieldArrayProps, prefix, errors, touched) => {
  const filteredValues = fieldArrayProps.form.values[prefix].filter(
    (caseConfig) => caseConfig.status !== "deleted"
  );

  return (
    <>
      {filteredValues.map((caseConfig, index) => (
        <div key={caseConfig.id || caseConfig.price_id} className="flex mb-2">
          <div className="flex w-50 flex-col mr-4">
            <label
              style={{
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "16px",
              }}
              htmlFor={`units_${prefix}_${index}`}
              className="mb-2 input-label"
            >
              Units *
            </label>
            <TextField
              id={`units_${prefix}_${index}`}
              variant="outlined"
              placeholder="Enter number of units"
              type="number"
              value={caseConfig.units}
              onChange={(e) => {
                const updatedArray = [...fieldArrayProps.form.values[prefix]];
                const actualIndex = updatedArray.findIndex(
                  (config) => config.id === caseConfig.id
                );
                updatedArray[actualIndex] = {
                  ...caseConfig,
                  units: e.target.value,
                };
                fieldArrayProps.form.setFieldValue(prefix, updatedArray);
              }}
              error={
                touched[prefix] &&
                touched[prefix][index]?.units &&
                Boolean(errors[prefix]?.[index]?.units)
              }
              helperText={
                touched[prefix] &&
                touched[prefix][index]?.units &&
                errors[prefix]?.[index]?.units
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment sx={{ display: "none" }} position="start">
                    <LocationCityIcon style={{ color: "#030391eb" }} />
                  </InputAdornment>
                ),
              }}
              className="mt-1 mb-4 rounded-lg"
            />
          </div>
          <div className="flex w-50 flex-col">
            <label
              style={{
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "16px",
              }}
              htmlFor={`price_${prefix}_${index}`}
              className="mb-2 input-label"
            >
              Retail Selling Price (Case) *
            </label>
            <TextField
              id={`price_${prefix}_${index}`}
              variant="outlined"
              placeholder="Enter price"
              type="number"
              value={caseConfig.price}
              onChange={(e) => {
                const updatedArray = [...fieldArrayProps.form.values[prefix]];
                const actualIndex = updatedArray.findIndex(
                  (config) => config.id === caseConfig.id
                );
                updatedArray[actualIndex] = {
                  ...caseConfig,
                  price: e.target.value,
                };
                fieldArrayProps.form.setFieldValue(prefix, updatedArray);
              }}
              error={
                touched[prefix] &&
                touched[prefix][index]?.price &&
                Boolean(errors[prefix]?.[index]?.price)
              }
              helperText={
                touched[prefix] &&
                touched[prefix][index]?.price &&
                errors[prefix]?.[index]?.price
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment sx={{ display: "none" }} position="start">
                    <LocationCityIcon style={{ color: "#030391eb" }} />
                  </InputAdornment>
                ),
              }}
              className="mt-1 mb-4 rounded-lg"
            />
          </div>
          <IconButton
            onClick={() => {
              const updatedArray = [...fieldArrayProps.form.values[prefix]];
              const actualIndex = updatedArray.findIndex(
                (config) => config.id === caseConfig.id
              );
              updatedArray[actualIndex] = { ...caseConfig, status: "deleted" };
              fieldArrayProps.form.setFieldValue(prefix, updatedArray);
            }}
          >
            <RemoveIcon />
          </IconButton>
        </div>
      ))}
      <div className="flex mb-2">
        <IconButton
          onClick={() =>
            fieldArrayProps.push({
              id: uuidv4(),
              price_id: "",
              units: "",
              price: "",
              status: "new",
            })
          }
        >
          <AddIcon />
        </IconButton>
      </div>
    </>
  );
};

export default function EditProduct({ user, onClose, brands, distributors }) {
  const { toggleBrandsUpdateFlag } = useBrandsContext();

  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [editProductLoading, setEditProductLoading] = useState(false);
  const [productNames, setProductNames] = useState([]);
  const [detailsLoading, setDetailsLoading] = useState(false);

  useEffect(() => {
    const loadProducts = async () => {
      try {
        await fetchBrandProducts(
          setIsLoading,
          setProducts,
          user.brand_name,
          setProductNames
        );
      } catch (error) {
        toast.error("Failed to fetch products");
      } finally {
        setIsLoading(false);
      }
    };

    loadProducts();
  }, []);

  const handleProductChange = async (event, setFieldValue) => {
    const selectedProductId = event.target.value;
    setDetailsLoading(true);
    try {
      const response = await fetchSkuDetails(selectedProductId);
      const product = response.data;

      setSelectedProduct(product);
      setFieldValue("sku_full_name", product.sku_full_name);
      setFieldValue("sku_brand", product.sku_brand);
      setFieldValue("sku_category", product.sku_category);
      setFieldValue("sku_flavor", product.sku_flavor);
      setFieldValue("sku_image_url", product.sku_image_url);
      setFieldValue("sku_size", product.sku_size || "");
      setFieldValue("sku_distributor", product.sku_distributor || "");
      setFieldValue("sku_status", product.sku_status);
      const prefixMap = {
        dtt: "dtt_cases",
        chains: "chains_cases",
        key_a: "key_a_cases",
        key_b: "key_b_cases",
      };

      ["dtt", "chains", "key_a", "key_b"].forEach((prefix) => {
        const priceDetails = product.prices.find(
          (p) => p.store_type.toLowerCase().replace(" ", "_") === prefix
        );
        if (priceDetails) {
          setFieldValue(`${prefix}_price`, priceDetails.price_before_vat);
          setFieldValue(
            `${prefix}_selling_price`,
            priceDetails.rec_sell_price_per_unit
          );
          setFieldValue(`${prefix}_vat`, priceDetails.price_vat !== 0);
          setFieldValue(
            prefixMap[prefix],
            priceDetails.cases.map((caseConfig) => ({
              id: uuidv4(), // Generate a unique ID for each case
              price_id: caseConfig.price_id,
              units: caseConfig.units,
              price: caseConfig.price,
              status: "old",
            }))
          );
        } else {
          setFieldValue(prefixMap[prefix], []);
        }
      });
    } catch (error) {
      toast.error("Failed to fetch product details");
    } finally {
      setDetailsLoading(false);
    }
  };

  const handleFormSubmit = async (values, { resetForm }) => {
    setEditProductLoading(true);
    const formData = new FormData();
    const country = jwtDecode(Cookies.get("Price_App_Token")).user_country;
    formData.append("country", country);
    formData.append("sku_brand", values.sku_brand);
    formData.append("sku_flavor", values.sku_flavor);
    formData.append("sku_category", values.sku_category);
    formData.append("sku_full_name", values.sku_full_name);
    formData.append("sku_distributor", values.sku_distributor);
    formData.append("sku_size", values.sku_size);
    formData.append("sku_status", values.sku_status);
    if (values.sku_image) {
      formData.append("sku_image", values.sku_image);
    }
    ["dtt", "chains", "key_a", "key_b"].forEach((prefix) => {
      formData.append(`${prefix}_price`, values[`${prefix}_price`]);
      formData.append(
        `${prefix}_selling_price`,
        values[`${prefix}_selling_price`]
      );
      formData.append(`${prefix}_vat`, values[`${prefix}_vat`]);
      if (values[`${prefix}_cases`].length > 0) {
        formData.append(
          `${prefix}_cases`,
          JSON.stringify(
            values[`${prefix}_cases`].map((caseConfig) => ({
              price_id: caseConfig.price_id,
              units: caseConfig.units,
              price: caseConfig.price,
              status: caseConfig.status,
            }))
          )
        );
      } else {
        formData.append(`${prefix}_cases`, JSON.stringify([]));
      }
    });
    // Append other form values as needed

    try {
      const { message } = await EditProductApi(
        formData,
        selectedProduct.sku_id
      );
      if (message === "Product updated successfully") {
        toast.success(message);
        toggleBrandsUpdateFlag();
        onClose();
      } else {
        toast.error(message);
      }
    } catch (e) {
      toast.error("Error updating product, please try again");
    } finally {
      setEditProductLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const initialValues = {
    sku_full_name: "",
    sku_brand: "",
    sku_flavor: "",
    sku_category: "",
    sku_distributor: "",
    sku_size: "",
    sku_status: "",
    dtt_price: "",
    dtt_selling_price: "",
    dtt_vat: false,
    chains_price: "",
    chains_selling_price: "",
    chains_vat: false,
    key_a_price: "",
    key_a_selling_price: "",
    key_a_vat: false,
    key_b_price: "",
    key_b_selling_price: "",
    key_b_vat: false,
    dtt_cases: [],
    key_a_cases: [],
    key_b_cases: [],
    chains_cases: [],
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={productSchema}
      enableReinitialize
      onSubmit={handleFormSubmit}
    >
      {({ getFieldProps, setFieldValue, touched, errors, values }) => (
        <Form className="px-4">
          <div className="flex mb-2">
            <div className="flex w-100 flex-col">
              <label
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
                htmlFor="product_select"
                className="mb-2 input-label"
              >
                Select Product
              </label>

              <TextField
                select
                variant="outlined"
                placeholder="Select Product"
                value={selectedProduct.sku_id || ""}
                InputProps={{
                  startAdornment: (
                    <InputAdornment sx={{ display: "none" }} position="start">
                      <LocationCityIcon style={{ color: "#030391eb" }} />
                    </InputAdornment>
                  ),
                }}
                onChange={(event) => handleProductChange(event, setFieldValue)}
                className="mt-1 mb-4 rounded-lg"
              >
                {" "}
                {products.map((product) => (
                  <MenuItem key={product.sku_id} value={product.sku_id}>
                    {product.sku_full_name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>

          {detailsLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            selectedProduct.sku_id && (
              <div className="flex mb-2 flex-col">
                <div className="flex mb-2">
                  <div className="flex w-50 flex-col mr-4">
                    <label
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        fontSize: "16px",
                      }}
                      htmlFor="sku_full_name"
                      className="mb-2 input-label"
                    >
                      Product Name *
                    </label>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      placeholder="Enter Product Name"
                      type="text"
                      autoComplete="Product Name"
                      required
                      {...getFieldProps("sku_full_name")}
                      error={
                        touched.sku_full_name && Boolean(errors.sku_full_name)
                      }
                      helperText={touched.sku_full_name && errors.sku_full_name}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            sx={{ display: "none" }}
                            position="start"
                          >
                            <LocationCityIcon style={{ color: "#030391eb" }} />
                          </InputAdornment>
                        ),
                      }}
                      className="mt-1 mb-4 rounded-lg"
                    />
                  </div>
                  <div className="flex w-50 flex-col">
                    <label
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        fontSize: "16px",
                      }}
                      htmlFor="sku_brand"
                      className="mb-2 input-label"
                    >
                      Product Brand *
                    </label>
                    <TextField
                      select
                      id="outlined-basic"
                      variant="outlined"
                      placeholder="Select Product Brand"
                      required
                      {...getFieldProps("sku_brand")}
                      error={touched.sku_brand && Boolean(errors.sku_brand)}
                      helperText={touched.sku_brand && errors.sku_brand}
                      className="mt-1 mb-4 rounded-lg"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            sx={{ display: "none" }}
                            position="start"
                          >
                            <LocationCityIcon style={{ color: "#030391eb" }} />
                          </InputAdornment>
                        ),
                      }}
                    >
                      {brands.length > 0 &&
                        brands.map((brand, index) => (
                          <MenuItem key={index} value={brand.brand_name}>
                            {brand.brand_name}
                          </MenuItem>
                        ))}
                    </TextField>
                  </div>
                </div>
                <div className="flex mb-2">
                  <div className="flex w-50 flex-col mr-4">
                    <label
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        fontSize: "16px",
                      }}
                      htmlFor="sku_category"
                      className="mb-2 input-label"
                    >
                      Product Category *
                    </label>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      placeholder="Enter Product Category"
                      type="text"
                      autoComplete="Product Category"
                      required
                      {...getFieldProps("sku_category")}
                      error={
                        touched.sku_category && Boolean(errors.sku_category)
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            sx={{ display: "none" }}
                            position="start"
                          >
                            <LocationCityIcon style={{ color: "#030391eb" }} />
                          </InputAdornment>
                        ),
                      }}
                      helperText={touched.sku_category && errors.sku_category}
                      className="mt-1 mb-4 rounded-lg"
                    />
                  </div>
                  <div className="flex w-50 flex-col">
                    <label
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        fontSize: "16px",
                      }}
                      htmlFor="sku_flavor"
                      className="mb-2 input-label"
                    >
                      Product Flavor *
                    </label>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      placeholder="Enter Product Flavor"
                      type="text"
                      autoComplete="Product Flavor"
                      required
                      {...getFieldProps("sku_flavor")}
                      error={touched.sku_flavor && Boolean(errors.sku_flavor)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            sx={{ display: "none" }}
                            position="start"
                          >
                            <LocationCityIcon style={{ color: "#030391eb" }} />
                          </InputAdornment>
                        ),
                      }}
                      helperText={touched.sku_flavor && errors.sku_flavor}
                      className="mt-1 mb-4 rounded-lg"
                    />
                  </div>
                </div>
                <div className="flex mb-2">
                  <div className="flex w-50 flex-col mr-4">
                    <label
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        fontSize: "16px",
                      }}
                      htmlFor="sku_distributor"
                      className="mb-2 input-label"
                    >
                      Product Distributor *
                    </label>
                    <TextField
                      select
                      id="outlined-basic"
                      variant="outlined"
                      placeholder="Select Product Distributor"
                      required
                      {...getFieldProps("sku_distributor")}
                      error={
                        touched.sku_distributor &&
                        Boolean(errors.sku_distributor)
                      }
                      helperText={
                        touched.sku_distributor && errors.sku_distributor
                      }
                      className="mt-1 mb-4 rounded-lg"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            sx={{ display: "none" }}
                            position="start"
                          >
                            <LocationCityIcon style={{ color: "#030391eb" }} />
                          </InputAdornment>
                        ),
                      }}
                    >
                      {distributors.length > 0 &&
                        distributors.map((distributor, index) => (
                          <MenuItem key={index} value={distributor}>
                            {distributor}
                          </MenuItem>
                        ))}
                    </TextField>
                  </div>
                  <div className="flex w-50 flex-col">
                    <label
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        fontSize: "16px",
                      }}
                      htmlFor="sku_size"
                      className="mb-2 input-label"
                    >
                      Product Size *
                    </label>
                    <TextField
                      select
                      id="outlined-basic"
                      variant="outlined"
                      placeholder="Select Product Size"
                      required
                      {...getFieldProps("sku_size")}
                      error={touched.sku_size && Boolean(errors.sku_size)}
                      helperText={touched.sku_size && errors.sku_size}
                      className="mt-1 mb-4 rounded-lg"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            sx={{ display: "none" }}
                            position="start"
                          >
                            <LocationCityIcon style={{ color: "#030391eb" }} />
                          </InputAdornment>
                        ),
                      }}
                    >
                      {skus_sizes.length > 0 &&
                        skus_sizes.map((size, index) => (
                          <MenuItem key={index} value={size}>
                            {size}
                          </MenuItem>
                        ))}
                    </TextField>
                  </div>
                </div>
                <div className="flex mb-2">
                  <div className="flex w-50 flex-col mr-4">
                    <label
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        fontSize: "16px",
                      }}
                      htmlFor="sku_status"
                      className="mb-2 input-label"
                    >
                      Product Status *
                    </label>
                    <TextField
                      select
                      id="outlined-basic"
                      variant="outlined"
                      placeholder="Select Product Status"
                      required
                      {...getFieldProps("sku_status")}
                      error={touched.sku_status && Boolean(errors.sku_status)}
                      helperText={touched.sku_status && errors.sku_status}
                      className="mt-1 mb-4 rounded-lg"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            sx={{ display: "none" }}
                            position="start"
                          >
                            <LocationCityIcon style={{ color: "#030391eb" }} />
                          </InputAdornment>
                        ),
                      }}
                    >
                      {status.length > 0 &&
                        status.map((status, index) => (
                          <MenuItem key={index} value={status}>
                            {status}
                          </MenuItem>
                        ))}
                    </TextField>
                  </div>
                </div>
                {["key_a", "key_b", "chains", "dtt"].map((prefix) => (
                  <div key={prefix} className="mb-3">
                    <Divider className="mb-2" />
                    <div className="flex">
                      <div className="flex w-50 flex-col mr-4">
                        <label
                          style={{
                            fontFamily: "Poppins",
                            fontWeight: "400",
                            fontSize: "16px",
                          }}
                          htmlFor={`${prefix}_price`}
                          className="mb-2 input-label"
                        >
                          {prefix.toUpperCase()} SMJ Selling Price
                        </label>
                        <TextField
                          id={`${prefix}_price`}
                          variant="outlined"
                          placeholder={`Enter ${prefix.toUpperCase()} Price`}
                          type="number"
                          autoComplete={`${prefix} Price`}
                          {...getFieldProps(`${prefix}_price`)}
                          error={
                            touched[`${prefix}_price`] &&
                            Boolean(errors[`${prefix}_price`])
                          }
                          helperText={
                            touched[`${prefix}_price`] &&
                            errors[`${prefix}_price`]
                          }
                          className="mt-1 mb-4 rounded-lg"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                sx={{ display: "none" }}
                                position="start"
                              >
                                <LocationCityIcon
                                  style={{ color: "#030391eb" }}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      <div className="flex w-50 flex-col">
                        <label
                          style={{
                            fontFamily: "Poppins",
                            fontWeight: "400",
                            fontSize: "16px",
                          }}
                          htmlFor={`${prefix}_selling_price`}
                          className="mb-2 input-label"
                        >
                          {prefix.toUpperCase()} Retail Selling Price (Unit)
                        </label>
                        <TextField
                          id={`${prefix}_selling_price`}
                          variant="outlined"
                          placeholder={`Enter ${prefix.toUpperCase()} Selling Price`}
                          type="number"
                          autoComplete={`${prefix} Selling Price`}
                          {...getFieldProps(`${prefix}_selling_price`)}
                          error={
                            touched[`${prefix}_selling_price`] &&
                            Boolean(errors[`${prefix}_selling_price`])
                          }
                          helperText={
                            touched[`${prefix}_selling_price`] &&
                            errors[`${prefix}_selling_price`]
                          }
                          className="mt-1 mb-4 rounded-lg"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                sx={{ display: "none" }}
                                position="start"
                              >
                                <LocationCityIcon
                                  style={{ color: "#030391eb" }}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex w-100 flex-row align-items-center">
                      <label
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "400",
                          fontSize: "16px",
                        }}
                        htmlFor={`${prefix}_vat`}
                        className="input-label"
                      >
                        {prefix.toUpperCase()} VAT
                      </label>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={`${prefix}_vat`}
                            name={`${prefix}_vat`}
                            checked={values[`${prefix}_vat`]}
                            inputProps={{ title: "VAT is 12.5%" }}
                            onChange={(event) =>
                              setFieldValue(
                                `${prefix}_vat`,
                                event.target.checked
                              )
                            }
                          />
                        }
                        label={values[`${prefix}_vat`] ? "VAT is 12.5%" : ""}
                        style={{ fontSize: "0.875rem" }}
                        className="mx-1 mb-2 rounded-lg text-muted"
                      />
                    </div>
                    <div className="flex flex-col mt-3">
                      <h3
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "400",
                          fontSize: "16px",
                        }}
                        className="mb-2 input-label"
                      >
                        {prefix.toUpperCase()} Case Configurations
                      </h3>
                      <FieldArray
                        name={`${prefix}_cases`}
                        render={(fieldArrayProps) =>
                          renderCaseConfigurations(
                            fieldArrayProps,
                            `${prefix}_cases`,
                            errors,
                            touched
                          )
                        }
                      />
                    </div>
                  </div>
                ))}

                <div className="flex mb-2">
                  <div className="flex w-100 flex-col">
                    <label
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        fontSize: "16px",
                      }}
                      htmlFor="brand_image"
                      className="mb-2 input-label"
                    >
                      Upload New Product Logo
                    </label>
                    <input
                      id="sku_image"
                      name="sku_image"
                      type="file"
                      accept="image/*"
                      style={{
                        paddingBottom: "2.5rem",
                        paddingTop: "0.7rem",
                        fontFamily: "Poppins",
                      }}
                      onChange={(event) => {
                        const file = event.target.files[0];
                        const maxSize = 1024 * 1024;
                        if (file && file.size > maxSize) {
                          toast.error(
                            "Logo is too large. Please upload a logo smaller than 1MB."
                          );
                          event.target.value = null;
                        } else {
                          setFieldValue("sku_image", event.target.files[0]);
                        }
                      }}
                      className="form-control"
                    />
                    {touched.sku_image && errors.sku_image && (
                      <div className="text-danger">{errors.sku_image}</div>
                    )}
                    <div
                      className="my-2"
                      style={{ fontSize: "0.8rem", color: "#666" }}
                    >
                      Max logo size: 1MB
                    </div>
                  </div>
                </div>

                {selectedProduct.sku_image_url &&
                selectedProduct.sku_image_url !== "nan" ? (
                  <div className="flex mb-2 flex-col">
                    <div className="flex w-100 flex-col">
                      <label className="mb-2 input-label">
                        Current Product Logo
                      </label>
                      <img
                        src={selectedProduct.sku_image_url}
                        alt="Current Product Logo"
                        loading="lazy"
                        style={{ maxWidth: "200px", maxHeight: "200px" }}
                      />
                    </div>
                  </div>
                ) : (
                  <>No logo for this product</>
                )}
                <div className="text-end my-3">
                  <CancelButton onClose={onClose} />
                  <SubmitButton
                    disabled={editProductLoading || !selectedProduct}
                    loading={editProductLoading}
                  />
                </div>
              </div>
            )
          )}
        </Form>
      )}
    </Formik>
  );
}
