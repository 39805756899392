// TaskCreationModal.js
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Tasks from "../../pages/addTask";

const TaskCreationModal = ({ profileId, fetchDetails }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <button
        className="btn btn-outline-success font-weight-bold"
        type="button"
        onClick={handleShow}
      >
        Assign Visit
      </button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Assign Routes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tasks
            profileId={profileId}
            closeModal={handleClose}
            fetchDetails={fetchDetails}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TaskCreationModal;
