import axios from "axios";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { dev_url, prod_url, local_url } from "../../apiurls";
import { toast } from "react-toastify";

// ///////////////////////////// Function to get all routes details in this country
export const fetchBrands = async (
  setIsLoading,
  setBrands,
  setDistributors,
  setFilteredBrands,
  brandsDeleted
) => {
  setIsLoading(true);
  const { user_country } = jwtDecode(Cookies.get("Price_App_Token"));
  try {
    const response = await axios.get(
      // `${prod_url}/brands/${user_country}`,
      `${prod_url}/brands/${user_country}?brands_deleted=${brandsDeleted}`,

      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
      }
    );

    if (response.data && Array.isArray(response.data.data)) {
      let BrandsData = response.data.data.map((brand, index) => ({
        id: brand.brand_id,
        ...brand,
        rowNumber: index + 1,
      }));
      const DyanmicfilteredDistributors = response.data.distributors.filter(
        (distributor) => distributor.toLowerCase() !== "nan"
      );
      setBrands(BrandsData);
      setFilteredBrands(BrandsData);
    } else {
      setBrands([]);
      setFilteredBrands([]);
    }
    const staticDistributors = ["SMJ", "Competitor"];
    setDistributors(staticDistributors);
  } catch (e) {
    if (!e.response) {
      setBrands([]);
      setFilteredBrands([]);
      // Network error or server is down
      return {
        message: "Network error . Please check your connection and try again.",
      };
    } else if (e.response.status >= 500) {
      // Server error
      setBrands([]);
      setFilteredBrands([]);
      return {
        message: "A server error occurred. Please try again later.",
      };
    } else {
      setBrands([]);
      setFilteredBrands([]);
      // Other errors
      return {
        message: e.response.data.message || "An error occurred",
      };
    }
  } finally {
    setIsLoading(false);
  }
};
//////////////////////// function to add A new Brand
export const AddBrand = async (formData) => {
  try {
    const response = await axios.post(
      // `${prod_url}/add_brand`,
      `${prod_url}/add_brand`,

      formData,

      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    const { message } = response.data;
    return { message };
  } catch (e) {
    if (!e.response) {
      // Network error or server is down
      return {
        message: "Network error . Please check your connection and try again.",
      };
    } else if (e.response.status >= 500) {
      // Server error
      return {
        message: "A server error occurred. Please try again later.",
      };
    } else {
      // Other errors
      return {
        message: e.response.data.message || "An error occurred",
      };
    }
  }
};

////////////////////////////////////////////////// function to add Sku
export const AddSku = async (formData) => {
  try {
    const response = await axios.post(
      // `${prod_url}/add_sku`,
      `${prod_url}/add_sku`,
      // `${local_url}/add_sku`,

      formData,

      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    const { message } = response.data;
    return { message };
  } catch (e) {
    if (!e.response) {
      // Network error or server is down
      return {
        message: "Network error . Please check your connection and try again.",
      };
    } else if (e.response.status >= 500) {
      // Server error
      return {
        message: "A server error occurred. Please try again later.",
      };
    } else {
      // Other errors
      return {
        message: e.response.data.message || "An error occurred",
      };
    }
  }
};

/////////////////// function to edit brand
export const EditBrand = async (formData, id) => {
  try {
    const response = await axios.post(
      // `${prod_url}/edit_brand/${id}`,
      `${prod_url}/edit_brand/${id}`,

      formData,

      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    const { message } = response.data;
    return { message };
  } catch (e) {
    if (!e.response) {
      // Network error or server is down
      return {
        message: "Network error . Please check your connection and try again.",
      };
    } else if (e.response.status >= 500) {
      // Server error
      return {
        message: "A server error occurred. Please try again later.",
      };
    } else {
      // Other errors
      return {
        message: e.response.data.message || "An error occurred",
      };
    }
  }
};

///////////////////////// fetch the products of the brand
export const fetchBrandProducts = async (
  setIsLoading,
  setProducts,
  brand_name,
  setProductNames
) => {
  setIsLoading(true);
  const { user_country } = jwtDecode(Cookies.get("Price_App_Token"));
  try {
    const response = await axios.get(
      // `${prod_url}/brand_products/${brand_name}/${user_country}`,
      `${prod_url}/brand_products/${brand_name}/${user_country}`,
      // `${local_url}/brand_products/${brand_name}/${user_country}`,

      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
        maxContentLength: Infinity, // Increase the response size limit
        maxBodyLength: Infinity,
      }
    );

    let ProductsData = [];
    ProductsData = response.data.data.map((sku, index) => ({
      id: sku.sku_id,
      ...sku,
      rowNumber: index + 1,
    }));
    setProducts(ProductsData);
    const namesArray = ProductsData.reduce((acc, product) => {
      if (product.sku_full_name !== "nan") {
        acc.push(product.sku_full_name);
      }
      return acc;
    }, []);
    setProductNames(namesArray);
  } catch (e) {
    if (!e.response) {
      // Network error or server is down
      return {
        message: "Network error . Please check your connection and try again.",
      };
    } else if (e.response.status >= 500) {
      // Server error
      return {
        message: "A server error occurred. Please try again later.",
      };
    } else {
      // Other errors
      return {
        message: e.response.data.message || "An error occurred",
      };
    }
  } finally {
    setIsLoading(false);
  }
};

////// edit Product
export const EditProductApi = async (formData, id) => {
  try {
    const response = await axios.post(
      // `${prod_url}/edit_sku/${id}`,
      // `${local_url}/edit_sku/${id}`,
      `${prod_url}/edit_sku/${id}`,

      formData,

      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    const { message } = response.data;
    return { message };
  } catch (e) {
    if (!e.response) {
      // Network error or server is down
      return {
        message: "Network error . Please check your connection and try again.",
      };
    } else if (e.response.status >= 500) {
      // Server error
      return {
        message: "A server error occurred. Please try again later.",
      };
    } else {
      // Other errors
      return {
        message: e.response.data.message || "An error occurred",
      };
    }
  }
};

/////////////////////// changing status for store
export const changeBrandStatus = async (brand_id, data) => {
  try {
    const response = await axios.put(
      // `${prod_url}/brandStatus/${brand_id}`,
      `${prod_url}/brandStatus/${brand_id}`,

      data,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
      }
    );
    const { message } = response.data;
    return { message };
  } catch (e) {
    if (!e.response) {
      // Network error or server is down
      return {
        message: "Network error . Please check your connection and try again.",
      };
    } else if (e.response.status >= 500) {
      // Server error
      return {
        message: "A server error occurred. Please try again later.",
      };
    } else {
      // Other errors
      return {
        message: e.response.data.message || "An error occurred",
      };
    }
  }
};

///// fetch single sku details
export const fetchSkuDetails = async (sku_id) => {
  const token = Cookies.get("Price_App_Token");
  try {
    const response = await axios.get(`${prod_url}/sku_details/${sku_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response.data);
    return response.data;
  } catch (e) {
    if (!e.response) {
      // Network error or server is down
      return {
        message: "Network error . Please check your connection and try again.",
      };
    } else if (e.response.status >= 500) {
      // Server error
      return {
        message: "A server error occurred. Please try again later.",
      };
    } else {
      // Other errors
      return {
        message: e.response.data.message || "An error occurred",
      };
    }
  }
};

//////////// download the skus_excel sheet

export const downloadSkusExcel = async () => {
  try {
    const { user_country } = jwtDecode(Cookies.get("Price_App_Token"));
    const response = await axios.get(
      // `${prod_url}/surveyExcel`,
      `${prod_url}/skuExcelSheet/${user_country}`,
      // `${local_url}/surveyExcel`,

      {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
      }
    );

    // Create a URL for the Excel blob
    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "sku_details.xlsx");
    document.body.appendChild(link);
    link.click();
    link.remove();

    // Show success message
    toast.success("File downloaded successfully");
  } catch (e) {
    if (!e.response) {
      toast.error("Network error. Please check your connection and try again.");
      return {
        message: "Network error. Please check your connection and try again.",
      };
    } else if (e.response.status >= 500) {
      toast.error("A server error occurred. Please try again later.");
      return {
        message: "A server error occurred. Please try again later.",
      };
    } else {
      toast.error(e.response.data.message || "An error occurred");
      return {
        message: e.response.data.message || "An error occurred",
      };
    }
  }
};

///////// Uploading Excel Sheet to Update Prices
export const uploadExcelFile = async (file) => {
  const formData = new FormData();
  formData.append("file", file);

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${Cookies.get("Price_App_Token")}`, // replace with your token retrieval method
    },
  };

  try {
    const response = await axios.post(
      `${prod_url}/bulkeditprices`,
      formData,
      config
    );
    return response.data;
  } catch (e) {
    if (!e.response) {
      throw new Error(
        "Network error. Please check your connection and try again."
      );
    } else if (e.response.status >= 500) {
      throw new Error("A server error occurred. Please try again later.");
    } else {
      throw new Error(e.response.data.message || "An error occurred");
    }
  }
};

//////////////// Restoring Brand
export const RestoreBrand = async (brand_id) => {
  const data = {};
  try {
    const response = await axios.put(
      `${prod_url}/restorebrand/${brand_id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
      }
    );
    const { message } = response.data;
    return { message };
  } catch (e) {
    return {
      message: e.response ? e.response.data.message : "An error occurred",
    };
  }
};
///////////// **********************Deleting Brand******************************************/////////////////////////
export const deleteBrands = async (brand_id) => {
  try {
    const response = await axios.delete(
      `${prod_url}/edit_brand/${brand_id}`,

      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
      }
    );
    const { message } = response.data;
    return { message };
  } catch (e) {
    return {
      message: e.response ? e.response.data.message : "An error occurred",
    };
  }
};
