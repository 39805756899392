import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField, IconButton, InputAdornment, Box } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { resetForgottenPassword } from "../../services/users/usersApis";
import { toast } from "react-toastify";
import SuccessResetPasswordModal from "../successResetPasswordModal";

const PasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Password must be at least 6 characters long")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const PasswordCreationForm = ({ onLogin, email }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      {" "}
      <Formik
        initialValues={{
          password: "",
          confirmPassword: "",
        }}
        validationSchema={PasswordSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          try {
            // Use the email from the OTP component
            const response = await resetForgottenPassword(
              email,
              values.password
            );

            if (response.success) {
              setShowModal(true);
            } else {
              toast.error(response.message);
              // Handle failure, e.g., show error message
            }
          } catch (error) {
            toast.error(error.message);
            // Handle error, e.g., show error message
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <p
              style={{
                fontFamily: "Poppins",
                fontWeight: "700",
                fontSize: "24px",
                lineHeight: "34px",
                color: "rgba(87, 110, 128, 1)",
                textAlign: "left",
                marginBottom: "1rem",
              }}
            >
              Create a New Password
            </p>
            <p
              style={{
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "17px",
                lineHeight: "34px",
                color: "rgba(125, 148, 166, 1)",
                textAlign: "left",
                marginBottom: "1.5rem",
              }}
            >
              Enter and confirm your new password below.
            </p>
            <Box mb={2}>
              <Field
                as={TextField}
                name="password"
                type={showPassword ? "text" : "password"}
                label="New password"
                variant="outlined"
                fullWidth
                error={touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: {
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "15px",
                    color:
                      touched.password && errors.password
                        ? "red"
                        : "rgba(19, 67, 149, 1)",
                  },
                }}
                FormHelperTextProps={{
                  style: {
                    color: "red",
                    fontFamily: "Poppins",
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor:
                        touched.password && errors.password
                          ? "red"
                          : "rgba(19, 67, 149, 1)",
                    },
                    "&:hover fieldset": {
                      borderColor:
                        touched.password && errors.password
                          ? "red"
                          : "rgba(19, 67, 149, 1)",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor:
                        touched.password && errors.password
                          ? "red"
                          : "rgba(19, 67, 149, 1) !important",
                    },
                  },
                }}
              />
            </Box>
            <Box mb={2}>
              <Field
                as={TextField}
                name="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                label="Confirm Password"
                variant="outlined"
                fullWidth
                error={touched.confirmPassword && !!errors.confirmPassword}
                helperText={touched.confirmPassword && errors.confirmPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: {
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "15px",
                    color:
                      touched.confirmPassword && errors.confirmPassword
                        ? "red"
                        : "rgba(19, 67, 149, 1)",
                  },
                }}
                FormHelperTextProps={{
                  style: {
                    color: "red",
                    fontFamily: "Poppins",
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor:
                        touched.confirmPassword && errors.confirmPassword
                          ? "red"
                          : "rgba(19, 67, 149, 1)",
                    },
                    "&:hover fieldset": {
                      borderColor:
                        touched.confirmPassword && errors.confirmPassword
                          ? "red"
                          : "rgba(19, 67, 149, 1)",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor:
                        touched.confirmPassword && errors.confirmPassword
                          ? "red"
                          : "rgba(19, 67, 149, 1) !important",
                    },
                  },
                }}
              />
            </Box>
            <p className="px-1">
              <span
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "25px",
                  textAlign: "left",
                  color: "rgba(79, 94, 119, 1)",
                }}
              >
                <InfoOutlinedIcon sx={{ fontSize: "18px" }} /> Password should
                include a minimum of 6 characters
              </span>
              <br />
              <span
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "25px",
                  textAlign: "left",
                  color: "rgba(79, 94, 119, 1)",
                }}
              >
                <InfoOutlinedIcon sx={{ fontSize: "18px" }} /> Password should
                include at least 1 digit.
              </span>
              <br />

              <span
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "25px",
                  textAlign: "left",
                  color: "rgba(79, 94, 119, 1)",
                }}
              >
                <InfoOutlinedIcon sx={{ fontSize: "18px" }} /> Password should
                include at least 1 special character (e.g., !, @, #, $).
              </span>
            </p>
            <button
              className={`mt-3 loginButton inline-block leading-normal w-full transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg ${
                Object.keys(errors).length === 0
                  ? "noErrorsButton"
                  : "errorsButton"
              }`}
              type="submit"
              disabled={Object.keys(errors).length > 0 || isSubmitting}
            >
              <h5 className="text-md button-txt">
                {" "}
                {isSubmitting ? <>Updating...</> : <>Update Password</>}
              </h5>
            </button>
          </Form>
        )}
      </Formik>
      <SuccessResetPasswordModal
        onLogin={onLogin}
        show={showModal}
        onHide={handleCloseModal}
      />
    </>
  );
};

export default PasswordCreationForm;
