import { TextField, InputAdornment, Button } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

import * as yup from "yup";
import { Input, Ripple, initTWE } from "tw-elements";
import "../styles/loginpage.css";
import { Form, Formik } from "formik";

import { useState } from "react";
import { toast } from "react-toastify";
import { useBrandsContext } from "../context/brandsfetch";
import { EditBrand } from "../../services/allbrands/apicalls";
import CancelButton from "../buttons/cancelButton";
import SubmitButton from "../buttons/submitFormButton";

initTWE({ Input, Ripple });
const checkoutSchema = yup.object().shape({
  brand_name: yup
    .string()
    .max(40, "Brand Name must be at most 40 characters")
    .required("Brand Name is required"),
});

export default function EditBrandForm({ user, onClose }) {
  const initialValues = {
    brand_name: user.brand_name || "",
  };
  const { toggleBrandsUpdateFlag } = useBrandsContext();
  const [editBrandLoading, setEditBrandLoading] = useState(false);

  const handleFormSubmit = async (values, { resetForm }) => {
    setEditBrandLoading(true);
    const formData = new FormData();

    formData.append("brand_name", values.brand_name);
    if (values.brand_image) {
      formData.append("brand_image", values.brand_image);
    }
    try {
      const { message } = await EditBrand(formData, user.brand_id);
      if (message === "Brand updated successfully") {
        toast.success(message);
        toggleBrandsUpdateFlag();
        onClose();
      } else {
        toast.error(message);
      }
    } catch (e) {
      toast.error("Error adding product, please try again");
    } finally {
      setEditBrandLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={checkoutSchema}
      onSubmit={(values, formikHelpers) =>
        handleFormSubmit(values, formikHelpers)
      }
    >
      {({ getFieldProps, setFieldValue, touched, errors, values }) => (
        <Form className="px-4 ">
          <div className="flex mb-2">
            <div className="flex  w-50 flex-col mr-4">
              <label
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
                htmlFor="Brand Name"
                className="mb-2 input-label"
              >
                Brand Name *
              </label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Enter Brand Name "
                type="text"
                autoComplete="Brand Name"
                required
                {...getFieldProps("brand_name")}
                error={touched.brand_name && Boolean(errors.brand_name)}
                helperText={touched.brand_name && errors.brand_name}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      sx={{
                        display: "none",
                      }}
                      position="start"
                    >
                      <PersonIcon style={{ color: "#030391eb" }} />
                    </InputAdornment>
                  ),
                }}
                className="mt-1 mb-4 rounded-lg"
              />
            </div>
          </div>
          <div className="flex mb-2">
            <div className="flex w-100 flex-col">
              <label
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
                htmlFor="brand_image"
                className="mb-2 input-label"
              >
                Upload New Brand Logo
              </label>
              <input
                id="brand_image"
                name="brand_image"
                type="file"
                accept="image/*"
                style={{
                  paddingBottom: "2.5rem",
                  paddingTop: "0.7rem",
                  fontFamily: "Poppins",
                }}
                onChange={(event) => {
                  const file = event.target.files[0];
                  const maxSize = 1024 * 1024;
                  if (file && file.size > maxSize) {
                    toast.error(
                      "Logo is too large. Please upload a logo smaller than 1MB."
                    );
                    event.target.value = null;
                  } else {
                    setFieldValue("brand_image", event.target.files[0]);
                  }
                }}
                className="form-control"
              />
              {touched.brand_image && errors.brand_image && (
                <div className="text-danger">{errors.brand_image}</div>
              )}
              <div
                className="my-2"
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "12px",
                }}
              >
                Max logo size: 1MB
              </div>
            </div>
          </div>

          {user.brand_image_url && (
            <div className="flex mb-2 flex-col">
              <div className="flex w-100 flex-col">
                <label className="mb-2 input-label">Current Brand Logo</label>
                <img
                  src={user.brand_image_url}
                  alt="Current Brand Logo"
                  loading="lazy"
                  style={{ maxWidth: "200px", maxHeight: "200px" }}
                />
              </div>
            </div>
          )}

          <div className="text-end ">
            {" "}
            <CancelButton onClose={onClose} />
            <SubmitButton
              disabled={editBrandLoading}
              loading={editBrandLoading}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}
