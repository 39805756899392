// DownloadSurveyButton.js
import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  downloadSurveyCsv,
  downloadSurveyPdf,
} from "../../../services/surveys/apicalls";

const DownloadSurveyButton = ({ questions, survey }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleDownload = async (format) => {
    setOpen(false);
    if (format === "pdf") {
      await downloadSurveyPdf(questions, setLoading, survey);
    } else {
      await downloadSurveyCsv(questions, setLoading, survey);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {" "}
      <Button
        onClick={handleClickOpen}
        sx={{
          background: "#03A9F4 ",
          borderRadius: "10px",
          fontFamily: "Poppins",
          fontSize: "12px",
          lineHeight: "25px",
          fontWeight: "600",
          color: "#FFFFFF",
          letterSpacing: "-0.18px",
          "&:hover": {
            backgroundColor: "#67C7F7",
          },

          textTransform: "capitalize",
        }}
        className="px-5 py-2 "
        disabled={loading}
      >
        {loading ? "Downloading..." : "Download Survey"}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Choose File Format</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please choose the file format you want to download the survey in.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleDownload("pdf")}
            color="primary"
            disabled={loading}
          >
            PDF
          </Button>
          <Button
            onClick={() => handleDownload("csv")}
            color="primary"
            disabled={loading}
          >
            CSV
          </Button>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DownloadSurveyButton;
