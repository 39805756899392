import {
  Box,
  TextField,
  Typography,
  InputAdornment,
  Button,
} from "@mui/material";
import * as yup from "yup";
import { Input, Ripple, initTWE } from "tw-elements";
import CloseIcon from "@mui/icons-material/Close";
import "../../styles/loginpage.css";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import { Form, Formik } from "formik";
import IconButton from "@mui/material/IconButton";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";

import { useState } from "react";
import { toast } from "react-toastify";
import { AddRoute } from "../../../services/allroutes/apicalls";
import { useRoutesContext } from "../../context/routesfetch";
import CancelButton from "../../buttons/cancelButton";
import SubmitButton from "../../buttons/submitFormButton";

initTWE({ Input, Ripple });
const initialValues = {
  route_app_id: "",
  route_name: "",
};
const checkoutSchema = yup.object().shape({
  route_app_id: yup
    .string()
    .max(40, "Route Id must be at most 40 characters")
    .required("Route Id is required"),
  route_name: yup
    .string()
    .max(40, "Route Name must be at most 40 characters")
    .required("Route Name is required"),
});

export default function CreateRouteForm({ onClose }) {
  const { toggleRouteUpdateFlag } = useRoutesContext();

  const [addingRouteLoading, setAddingRouteLoading] = useState(false);

  const handleFormSubmit = async (values, { resetForm }) => {
    setAddingRouteLoading(true);

    values["route_country"] = jwtDecode(
      Cookies.get("Price_App_Token")
    ).user_country;
    try {
      const { message } = await AddRoute(values);
      if (message === "Route added successfully") {
        toast.success(message);
        toggleRouteUpdateFlag();
        onClose();
      } else {
        toast.error(message);
      }
    } catch (e) {
      toast.error("Error adding user please try again");
    } finally {
      setAddingRouteLoading(false);
    }
  };

  return (
    <Box sx={{ width: "100%" }} autoComplete="off">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          boxShadow: "0px 3px 6px #A8A8A81F",
        }}
        autoComplete="off"
        className="p-4 mb-5"
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "24px",
            letterSpacing: "-0.22px",
            color: " rgba(75, 75, 75, 1)",
          }}
        >
          Create New Route
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Formik
        initialValues={initialValues}
        validationSchema={checkoutSchema}
        onSubmit={(values, formikHelpers) =>
          handleFormSubmit(values, formikHelpers)
        }
      >
        {({ getFieldProps, touched, errors }) => (
          <Form className="px-4 ">
            <div className="flex mb-2">
              <div className="flex  w-50 flex-col mr-4">
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                  htmlFor="Route Id"
                  className="mb-2 input-label"
                >
                  Route Id *
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Enter Route Id"
                  type="text"
                  autoComplete="Route Id"
                  required
                  {...getFieldProps("route_app_id")}
                  error={touched.route_app_id && Boolean(errors.route_app_id)}
                  helperText={touched.route_app_id && errors.route_app_id}
                  className="mt-1 mb-4 rounded-lg"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        sx={{
                          display: "none",
                        }}
                        position="start"
                      >
                        <LocationCityIcon
                          style={{ color: "rgba(19, 67, 149, 1)" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="flex w-50 flex-col">
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                  htmlFor="Route Name"
                  className="mb-2 input-label"
                >
                  Route Name *
                </label>
                <TextField
                  id="outlined-basic1"
                  variant="outlined"
                  placeholder="Enter Route Name"
                  type="text"
                  autoComplete="Route Name"
                  required
                  {...getFieldProps("route_name")}
                  error={touched.route_name && Boolean(errors.route_name)}
                  helperText={touched.route_name && errors.route_name}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        sx={{
                          display: "none",
                        }}
                        position="start"
                      >
                        <LocationCityIcon
                          style={{ color: "rgba(19, 67, 149, 1)" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1 mb-4 rounded-lg"
                />
              </div>
            </div>

            <div className="text-end my-3">
              {" "}
              <CancelButton onClose={onClose} />
              <SubmitButton
                disabled={addingRouteLoading}
                loading={addingRouteLoading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
