import React, { createContext, useState, useContext } from "react";

const RoutesContext = createContext();

export const useRoutesContext = () => useContext(RoutesContext);

export const RoutesProvider = ({ children }) => {
  const [updateRoutesFlag, setUpdateRoutesFlag] = useState(false);

  const toggleRouteUpdateFlag = () => {
    setUpdateRoutesFlag(!updateRoutesFlag);
  };

  return (
    <RoutesContext.Provider value={{ updateRoutesFlag, toggleRouteUpdateFlag }}>
      {children}
    </RoutesContext.Provider>
  );
};
