// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.username-title {
  text-align: center;
  font-weight: bold;
  font-size: 2rem; /* Adjust the size as needed */
  margin-bottom: 30px; /* Adjust the margin as needed */
}
`, "",{"version":3,"sources":["webpack://./src/components/styles/profile.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,eAAe,EAAE,8BAA8B;EAC/C,mBAAmB,EAAE,gCAAgC;AACvD","sourcesContent":[".username-title {\n  text-align: center;\n  font-weight: bold;\n  font-size: 2rem; /* Adjust the size as needed */\n  margin-bottom: 30px; /* Adjust the margin as needed */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
