import React, { createContext, useContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import { mapUserRole } from "../../utils/helperFunctions";

// Create a context
const UserContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useUser().
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = Cookies.get("Price_App_Token");
    if (token) {
      const { user_name, user_position } = jwtDecode(token);
      const mappedUserPosition = mapUserRole(user_position); // Map the user_position

      setUser({ user_name, user_position: mappedUserPosition });
    }
  }, []);

  const userlogin = (token) => {
    const { user_name, user_position } = jwtDecode(token);
    Cookies.set("Price_App_Token", token);
    const mappedUserPosition = mapUserRole(user_position); // Map the user_position

    setUser({ user_name, user_position: mappedUserPosition }); // Update user context here
  };
  const logout = () => {
    Cookies.remove("Price_App_Token");
    setUser(null); // clear user data
  };
  const updateUser = (newUserData) => {
    setUser(newUserData);
  };

  return (
    <UserContext.Provider value={{ user, logout, userlogin, updateUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
