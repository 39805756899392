import axios from "axios";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
/////////**********************************Assign Visit Page Calls********************************************* */

export const addVisit = async (FinalData) => {
  try {
    const response = await axios.post(
      "https://dev-price-admin-app.corporatica.com/api/assignroute",
      FinalData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { success: true, data: response.data };
    } else {
      return { success: false, error: "Unexpected response status." };
    }
  } catch (error) {
    console.log(error);
    return { success: false, error };
  }
};
/////////////********************************************************************************** */
//////////////////// function to fetch Routes
export const fetchRoutes = async (user_id) => {
  try {
    const user_country = jwtDecode(Cookies.get("Price_App_Token")).user_country;
    const routes = await axios.get(
      // `https://price-admin-panel.corporatica.com/api/availableroutes/${user_country}/${user_id}`
      `https://dev-price-admin-app.corporatica.com/api/availableroutes/${user_country}/${user_id}`
    );
    const Routes_data = routes.data.data.map((doc) => ({
      route_id: doc.route_id,
      route_name: doc.route_name,
    }));
    return { data: Routes_data, error: null };
  } catch (err) {
    console.log(err);
    return { data: [], error: err };
  }
};

////////// function to get the visit details
export const fetchVisitDetails = async (route_id, user_id) => {
  try {
    const response = await axios.get(
      `https://dev-price-admin-app.corporatica.com/api/routesmodify/${route_id}/${user_id}`
    );
    const fetchedData = response.data.data;
    let visitDurationMonths;
    if (!fetchedData.route_monthly_repeated) {
      const route_start_month = parseInt(fetchedData.route_start_month, 10);
      const route_end_month = parseInt(fetchedData.route_end_month, 10);
      const route_start_year = parseInt(fetchedData.route_start_year, 10);
      const route_end_year = parseInt(fetchedData.route_end_year, 10);

      const totalStartMonths = route_start_year * 12 + route_start_month;
      const totalEndMonths = route_end_year * 12 + route_end_month;

      visitDurationMonths = totalEndMonths - totalStartMonths;
    }
    const formattedMonth = fetchedData.route_start_month
      .toString()
      .padStart(2, "0");
    const dateField = `${fetchedData.route_start_year}-${formattedMonth}`;
    const initialTaskValues = {
      route_comment: fetchedData["route_comment"],
      dateField: dateField,
      route_monthly_repeated: fetchedData.route_monthly_repeated,
      visitDurationMonths: visitDurationMonths,
    };

    return { initialTaskValues, error: null };
  } catch (e) {
    console.log(
      "############################ Error###########################"
    );
    console.log(e);
    return { error: e };
  }
};

///// function to submit edit to visit
export const updateVisitDetails = async (route_id, user_id, values) => {
  try {
    const response = await axios.put(
      `https://dev-price-admin-app.corporatica.com/api/routesmodify/${route_id}/${user_id}`,
      values
    );
    if (response.status === 200) {
      return { success: true, data: response.data };
    } else {
      return { success: false, error: "Unexpected response status." };
    }
  } catch (error) {
    console.log(error);
    return { success: false, error };
  }
};
