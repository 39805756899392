import React from "react";
import Sidebar from "./sidebar";
import NavBar from "./navbar";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const NavigationLayout = ({ children }) => {
  const location = useLocation();
  const isProfilePage = location.pathname === "/profile";
  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="flex flex-col w-full overflow-hidden ">
        <NavBar />
        <div
          style={{
            padding: "0rem 1.5rem",
          }}
          className={`content h-full overflow-y-auto`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default NavigationLayout;
